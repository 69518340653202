import { useOperationShallowWorkMachineForm } from '@modules/encoding/modules/technicalItinerary/components/OperationShallowWorkMachineForm/useOperationShallowWorkMachineForm';
import OperationShallowWorkMachineFormSkeleton from '@modules/encoding/modules/technicalItinerary/components/OperationShallowWorkMachineForm/OperationShallowWorkMachineFormSkeleton';
import { NumberInput } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { useOperationShallowWorkMachineFormStyle } from '@modules/encoding/modules/technicalItinerary/components/OperationShallowWorkMachineForm/useOperationShallowWorkMachineForm.style';
import { FormProvider } from 'react-hook-form';
import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { ReuseOperationSelect } from '../../module/operationDuplication/reuse/components/ReuseOperationSelect/ReuseOperationSelect';
import { useOperationRouteParams } from '../../hooks/useOperationRouteParams';
import { useReuseOperation } from '../../module/operationDuplication/reuse/hooks/useReuseOperation';
import { SHALLOW_WORK_FORM_INPUT_NAMES } from '../../schema/shallowWorkFormSchema';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';
import { FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';
import { WarningBox } from '@shared/components/WarningBox/WarningBox';

const OperationShallowWorkMachineForm = () => {
    const { t } = useTranslation();
    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();

    const {
        selectedMachinery,
        methods,
        machineryOptions,
        onSubmit,
        isFormLoading,
        fieldCropHasCoverCropDestruction,
        getFormValues,
        refetchFormData,
        changeMachineryRelatedInputs,
    } = useOperationShallowWorkMachineForm();

    const { handleReuseOperation, isReuseOperationLoading } = useReuseOperation({
        onOperationReused: refetchFormData,
        getFormValues,
    });

    const { classes, cx } = useOperationShallowWorkMachineFormStyle();

    return (
        <>
            {isFormLoading ? <OperationShallowWorkMachineFormSkeleton /> : null}
            <FormProvider {...methods}>
                <form onSubmit={onSubmit} className={cx(classes.form, isFormLoading && classes.hiddenForm)}>
                    {fieldCropHasCoverCropDestruction && (
                        <WarningBox displayWarningIcon={true}>
                            {t('encoding-technical-itinerary.warning.cover-crop-double-passage')}
                        </WarningBox>
                    )}
                    <ReuseOperationSelect
                        fieldCropId={fieldCropId}
                        farmSeasonId={seasonId}
                        operationId={operationId}
                        onReuseOperationTriggered={handleReuseOperation}
                        disabled={isReuseOperationLoading}
                        actionLoading={isReuseOperationLoading}
                    />

                    <FormRow>
                        <FormQuestion
                            question={t('encoding-technical-itinerary.shallow-work-machine.machine')}
                            tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.machine-tooltip-title"
                            tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.machine-tooltip-text"
                        />
                        <FormAutocomplete
                            name={SHALLOW_WORK_FORM_INPUT_NAMES.MACHINERY_ID}
                            placeholder={t('constants.select')}
                            options={machineryOptions}
                            disabled={isReuseOperationLoading}
                            multiple={false}
                            onChange={(value) => {
                                changeMachineryRelatedInputs(value.value);
                                onSubmit();
                            }}
                        />
                    </FormRow>

                    <FormRow>
                        <FormQuestion
                            question={t('encoding-technical-itinerary.shallow-work-machine.passage')}
                            tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.passage-tooltip-title"
                            tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.passage-tooltip-text"
                        />
                        <NumberInput
                            min={0}
                            incrementationStep={1}
                            value={methods.watch(SHALLOW_WORK_FORM_INPUT_NAMES.PASSAGE) ?? null}
                            onChange={(value) => {
                                methods.setValue(SHALLOW_WORK_FORM_INPUT_NAMES.PASSAGE, value);
                                onSubmit();
                            }}
                            data-testid="passage-input"
                            style={{ width: '250px' }}
                            disabled={isReuseOperationLoading}
                        />
                    </FormRow>

                    {selectedMachinery && selectedMachinery.depth === null && (
                        <FormRow>
                            <FormQuestion
                                question={t('encoding-technical-itinerary.shallow-work-machine.depth')}
                                tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.depth-tooltip-title"
                                tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.depth-tooltip-text"
                            />
                            <NumberInput
                                min={0}
                                value={methods.watch(SHALLOW_WORK_FORM_INPUT_NAMES.DEPTH) ?? null}
                                onChange={(value) => {
                                    methods.setValue(SHALLOW_WORK_FORM_INPUT_NAMES.DEPTH, value);
                                    onSubmit();
                                }}
                                data-testid="depth-input"
                                suffix={t('constants.unit.cm')}
                                style={{ width: '250px' }}
                                disabled={isReuseOperationLoading}
                            />
                        </FormRow>
                    )}

                    {selectedMachinery?.slug === STATIC_MACHINERY_SLUGS.STRIP_TILL && (
                        <FormRow>
                            <FormQuestion
                                question={t('encoding-technical-itinerary.shallow-work-machine.width')}
                                tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.width-tooltip-title"
                                tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.width-tooltip-text"
                            />
                            <NumberInput
                                min={0}
                                value={methods.watch(SHALLOW_WORK_FORM_INPUT_NAMES.SOIL_WORK_WIDTH) ?? null}
                                onChange={(value) => {
                                    methods.setValue(SHALLOW_WORK_FORM_INPUT_NAMES.SOIL_WORK_WIDTH, value);
                                    onSubmit();
                                }}
                                suffix={t('constants.unit.cm')}
                                data-testid="width-input"
                                style={{ width: '250px' }}
                                disabled={isReuseOperationLoading}
                            />
                        </FormRow>
                    )}
                    {selectedMachinery?.slug === STATIC_MACHINERY_SLUGS.STRIP_TILL && (
                        <FormRow>
                            <FormQuestion
                                question={t('encoding-technical-itinerary.shallow-work-machine.interrow')}
                                tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.interrow-tooltip-title"
                                tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.interrow-tooltip-text"
                            />
                            <NumberInput
                                min={0}
                                value={methods.watch(SHALLOW_WORK_FORM_INPUT_NAMES.INTERROW) ?? null}
                                onChange={(value) => {
                                    methods.setValue(SHALLOW_WORK_FORM_INPUT_NAMES.INTERROW, value);
                                    onSubmit();
                                }}
                                data-testid="interrow-input"
                                suffix={t('constants.unit.cm')}
                                style={{ width: '250px' }}
                                disabled={isReuseOperationLoading}
                            />
                        </FormRow>
                    )}
                </form>
            </FormProvider>
        </>
    );
};

export default OperationShallowWorkMachineForm;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { FieldT, GeometryDataT } from '@shared/entities';
import { PolygonGeometryT } from '@shared/entities/field/field.types';
import { useEffect, useCallback, useMemo } from 'react';
import {
    calculateArea,
    isDrawingInsideBoundaries,
    isDrawingOnOtherPolygons,
} from '@modules/encoding/modules/rotation/helpers/polygonHelper';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMap } from '@shared/map/useMap';
import { FeatureCollection, Geometry, GeometryCollection, Properties } from '@turf/helpers';
import { useToggle } from '@shared/hooks/useToggle';
import { MapDrawingEventContext } from '@modules/encoding/modules/rotation/context/mapDrawingEventContextProvider';
import { MapLayerCallbackFeatureDataT } from '@shared/map/MapLayers';
import { MAPMODE, MapModeT } from '@modules/encoding/modules/rotation/components/PacImportMap/PacImportMap.logic';

interface ExtendedFeatureTarget {
    layer?: {
        type?: string;
    };
}

const CustomDirectSelect = { ...MapboxDraw.modes.direct_select };

CustomDirectSelect.onMouseDown = function (state, e) {
    // Avoid moving the whole polygon
    const targetFeature = e.featureTarget as ExtendedFeatureTarget;
    const targetFeatureType = targetFeature?.layer?.type;

    if (targetFeatureType === 'circle') {
        state.disableDrag = false;
    } else {
        state.disableDrag = true;
    }
    if (MapboxDraw.modes.direct_select.onMouseDown) {
        MapboxDraw.modes.direct_select.onMouseDown.call(this, state, e);
    }
};

CustomDirectSelect.onDrag = function (state, e) {
    if (state.disableDrag) {
        // Do nothing to prevent dragging the polygon
        return;
    }
    if (MapboxDraw.modes.direct_select.onDrag) {
        MapboxDraw.modes.direct_select.onDrag.call(this, state, e);
    }
    // Set a flag indicating dragging is in progress
    state.dragging = true;
};

CustomDirectSelect.onMouseUp = function (state, e) {
    state.disableDrag = false;
    if (MapboxDraw.modes.direct_select.onMouseUp) {
        MapboxDraw.modes.direct_select.onMouseUp.call(this, state, e);
    }
    // Check if a drag was happening
    if (state.dragging) state.dragging = false;
};

export const useEditFieldLogic = ({
    mapMode,
    setMapMode,
    currentFields,
    farmBoundaries,
    currentInvalidFields,
    isYoyFarmSeason,
}: {
    mapMode: MapModeT;
    setMapMode: Dispatch<SetStateAction<MapModeT>>;
    currentFields: FieldT[];
    farmBoundaries: FeatureCollection<Geometry | GeometryCollection, Properties> | null | undefined;
    currentInvalidFields: GeometryDataT[];
    isYoyFarmSeason: boolean;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const map = useMap();
    const mapDrawingEventContextProvider = useContext(MapDrawingEventContext);

    if (!mapDrawingEventContextProvider) {
        throw new Error('MapDrawingEventContextProvider is not available');
    }
    const { setOnClick } = mapDrawingEventContextProvider;
    const [fields, setFields] = useState<FieldT[]>(currentFields);
    const [invalidFields, setInvalidFields] = useState<GeometryDataT[]>(currentInvalidFields);
    const [drawControl, setDrawControl] = useState<null | MapboxDraw>(null);
    const [editedFeatureId, setEditedFeatureId] = useState<null | string>(null);
    const [editedField, setEditedField] = useState<FieldT | null>(null);
    const [editedInvalidField, setEditedInvalidField] = useState<GeometryDataT | null>(null);
    const [updatedField, setUpdatedField] = useState<FieldT | GeometryDataT | null>(null);
    const [isSaveDisabled, setIsSavedDisabled] = useState(true);

    const drawOptions = useMemo(() => {
        if (!map) return null;

        return new MapboxDraw({
            displayControlsDefault: false,
            modes: {
                ...MapboxDraw.modes,
                direct_select: CustomDirectSelect,
            },
        });
    }, [map]);

    const handleDrawUpdate = useCallback(
        (featureData: MapLayerCallbackFeatureDataT<any> | null, layerId: string) => {
            if (!featureData || !drawOptions) return;

            const featureId = featureData.featureId;

            const selectedField = currentFields.find((field) => field.id === featureId);

            // edit not available for permanent fields & agroforestry YoY
            if (selectedField?.is_permanent) {
                return;
            } else if (selectedField?.has_agroforestry && isYoyFarmSeason) {
                return;
            }
            setFields((prevFields) => prevFields.filter((field) => field.id !== featureId));
            if (selectedField) {
                // Ensure feature is added before selecting it
                let newFeatureId = '';
                const existingFeature = drawOptions.get(layerId);

                if (existingFeature) {
                    newFeatureId = layerId;
                } else {
                    newFeatureId = drawOptions.add(selectedField.polygon)?.[0];
                }

                if (!newFeatureId) {
                    console.error('❌ Error adding feature to Draw');
                    return;
                }

                drawOptions.changeMode('direct_select', { featureId: newFeatureId });
                setEditedFeatureId(newFeatureId);
                setEditedField(selectedField);
            }
            // Process for invalidFields
            if (invalidFields) {
                const selectedField = invalidFields.find((field) => field.id === featureId);
                if (selectedField && !editedInvalidField && !editedField) {
                    setEditedInvalidField?.(selectedField);
                    setInvalidFields?.(invalidFields.filter((field) => field !== selectedField));

                    const featureId = drawOptions.add(selectedField.polygon);
                    setEditedFeatureId(featureId[0]);
                    drawOptions.changeMode('direct_select', { featureId: featureId[0] });
                    setInvalidFields?.(invalidFields.filter((field) => field !== selectedField));
                }
            }
        },
        [
            drawOptions,
            currentFields,
            invalidFields,
            setEditedFeatureId,
            setInvalidFields,
            editedField,
            editedInvalidField,
            isYoyFarmSeason,
        ],
    );

    useEffect(() => {
        const onUpdate = (e: { features: any[] }) => {
            // Check if the updated feature is the one being edited
            if (e.features && e.features[0].id === editedFeatureId) {
                const drawingInsideBoundaries = isDrawingInsideBoundaries(e.features[0].geometry, farmBoundaries);
                if (!drawingInsideBoundaries) {
                    setIsSavedDisabled(true);
                } else if (drawingInsideBoundaries) setIsSavedDisabled(false);
            }
        };

        if (drawOptions && map) {
            map.on('draw.update', onUpdate);
        }

        // Clean up the event listener on unmount or when dependencies change
        return () => {
            if (drawOptions && map) {
                map.off('draw.update', onUpdate);
            }
        };
    }, [drawOptions, editedFeatureId, map, enqueueSnackbar, t, farmBoundaries]);

    const edit = () => {
        if (!map || !drawOptions) return;

        setIsSavedDisabled(true);
        setMapMode?.(MAPMODE.EDIT);
        setUpdatedField(null);

        if (drawControl) {
            map.removeControl(drawControl);
        }

        if (!map.hasControl(drawOptions)) {
            map.addControl(drawOptions);
            setDrawControl(drawOptions);
        }
    };

    const stopEditing = () => {
        if (editedField && fields) setFields?.([editedField, ...fields]);
        if (editedInvalidField && invalidFields) setInvalidFields?.([editedInvalidField, ...invalidFields]);

        if (drawControl) {
            map?.removeControl(drawControl);
            setDrawControl(null);
        }

        setMapMode(MAPMODE.NONE);
        setEditedField(null);
        setEditedInvalidField(null);
    };

    const saveUpdatedField = () => {
        if (!drawOptions) {
            return;
        }

        const updatedFeature = drawOptions.get(editedFeatureId ?? '');

        if (!updatedFeature) {
            return;
        }

        const updatedPolygon = updatedFeature.geometry as PolygonGeometryT;
        const unEditedFieldsGeometry = fields?.map((field) => field.polygon.geometry) || [];
        const fieldToUpdate = editedField || editedInvalidField;
        if (!isDrawingOnOtherPolygons(updatedPolygon, unEditedFieldsGeometry) && fieldToUpdate) {
            const newArea = calculateArea(updatedPolygon);

            const updatedFieldData = {
                ...fieldToUpdate,
                original_area: Number(newArea.toFixed(3)),
                area: Number(newArea.toFixed(3)),
                polygon: { ...fieldToUpdate?.polygon, geometry: updatedPolygon },
            };

            setUpdatedField(updatedFieldData);
        } else {
            enqueueSnackbar(t('encoding-rotation.pac-file.edit-fields-toast.error'), {
                variant: 'error',
            });
        }
        stopEditing();
    };

    useEffect(() => {
        setInvalidFields(currentInvalidFields);
    }, [currentInvalidFields]);

    useEffect(() => {
        if (mapMode === MAPMODE.EDIT) {
            setOnClick(() => handleDrawUpdate);
        } else {
            setOnClick(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapMode, handleDrawUpdate]);

    const {
        open: editFieldModalOpen,
        handleOpen: onEditFieldModalOpen,
        handleClose: onEditFieldModalClose,
    } = useToggle();

    return {
        edit,
        stopEditing,
        saveUpdatedField,
        editedFeatureId,
        updatedField,
        isSaveDisabled,
        editFieldModalOpen,
        onEditFieldModalOpen,
        onEditFieldModalClose,
        handleDrawUpdate,
    };
};

import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetFarmPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import { useToggle } from '@shared/hooks/useToggle';
import { useEffect } from 'react';
import { useGetFarmer } from '@shared/hooks/useGetFarmer';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useIsRenewalDisabled } from '@shared/hooks/useIsRenewalDisabled';

export const usePaymentCTAModalLogic = () => {
    const { currentSeason } = useCurrentSeason();
    const { currentSeasonId } = useCurrentSeasonId();
    const { data: farmPaymentStatus } = useGetFarmPaymentStatusQuery({ farmId: currentSeason?.farm_id });

    const { farmer } = useGetFarmer();
    const { data: partnerInfo } = useGetPartnerInfoQuery({ userId: farmer?.id });
    const isPartnerPrepaying = !!partnerInfo?.is_partner_prepaying;
    const { isRenewalDisabled } = useIsRenewalDisabled(currentSeasonId, !!partnerInfo?.id, currentSeason?.farm_id);

    const hasToPayForBaseline = !!(
        farmPaymentStatus?.status === 'deferred' &&
        currentSeason?.has_results_published &&
        !isPartnerPrepaying &&
        !isRenewalDisabled
    );

    const hasToRenew = !!(
        farmPaymentStatus?.status === 'to_be_renewed' &&
        currentSeason?.has_results_published &&
        !isPartnerPrepaying &&
        !isRenewalDisabled
    );

    const showModal = hasToPayForBaseline || hasToRenew;

    const {
        open: paymentCTAModalOpen,
        handleOpen: onPaymentCTAModalOpen,
        handleClose: onPaymentCTAModalClose,
    } = useToggle();

    useEffect(() => {
        if (showModal) {
            onPaymentCTAModalOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    return {
        paymentCTAModalOpen,
        onPaymentCTAModalOpen,
        onPaymentCTAModalClose,
        renewal: hasToRenew,
    };
};

import { Polygon } from '@turf/helpers';
import { useForm } from '@shared/hooks';
import * as z from 'zod';
import { useCreateDrawnFarmSeasonField } from '@modules/encoding/shared/hooks/useCreateDrawnFarmSeasonField';
import { useCreateEmptySpotField } from '@modules/encoding/shared/hooks/useCreateEmptySpotField';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useRef, useCallback, useEffect } from 'react';
import { calculateAreaProperties } from '@shared/utils/calculateAreaProperties';

export const DRAWN_FIELD_CREATION = {
    NAME: 'field_name',
    AREA: 'area',
} as const;

export const useDrawnFieldCreationModalFormLogic = ({
    polygon,
    area,
    onClose,
    open,
    type,
    invalidFieldId,
    name,
}: {
    polygon: Polygon;
    area: number;
    onClose: () => void;
    open: boolean;
    type: 'empty-spot' | 'drawn';
    invalidFieldId?: number | null;
    name?: string | null;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const shouldFocus = useRef(true);
    const { treshold, maxArea, minArea } = calculateAreaProperties(area);

    const defaultValues = {
        [DRAWN_FIELD_CREATION.NAME]: name ?? '',
        [DRAWN_FIELD_CREATION.AREA]: Number(area.toFixed(2)),
    };

    const schema = z.object({
        [DRAWN_FIELD_CREATION.NAME]: z.string().min(1, t('encoding.rotation.field-creation-modal.error-name')),
        [DRAWN_FIELD_CREATION.AREA]: z
            .number()
            .min(
                Number(minArea.toFixed(2)),
                t('encoding.rotation.field-creation-modal.error-under-treshold', { treshold }),
            )
            .max(
                Number(maxArea.toFixed(2)),
                t('encoding.rotation.field-creation-modal.error-over-treshold', { treshold }),
            ),
    });

    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });

    const { createDrawnField, isLoading: isLoadingDrawn } = useCreateDrawnFarmSeasonField();
    const { createEmptySpotField, isLoading: isLoadingEmptySpot } = useCreateEmptySpotField();
    const {
        handleSubmit,
        formState: { isValid, errors },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        const name = data[DRAWN_FIELD_CREATION.NAME];
        const userArea =
            data[DRAWN_FIELD_CREATION.AREA] !== Number(area.toFixed(2)) ? data[DRAWN_FIELD_CREATION.AREA] : null;
        const fieldBody = {
            name: name,
            area: area,
            user_area: userArea,
            geometry: {
                coordinates: polygon.coordinates,
                type: polygon.type,
            },
            geometry_data_id: invalidFieldId ?? null,
        };

        const response = type === 'drawn' ? await createDrawnField(fieldBody) : await createEmptySpotField(fieldBody);
        if (response && 'error' in response) {
            enqueueSnackbar(t('encoding.rotation.field-creation-modal.error'), {
                variant: 'error',
            });
        }
    });

    const onConfirm = async () => {
        await onSubmit();

        if (isValid) {
            onClose();
        }
    };

    const inputRef = useCallback(
        (node: HTMLInputElement) => {
            if (node && open && shouldFocus.current) {
                node.focus();
                shouldFocus.current = false;
            }
        },
        [open],
    );

    useEffect(() => {
        methods.reset({
            [DRAWN_FIELD_CREATION.NAME]: name ?? '',
            [DRAWN_FIELD_CREATION.AREA]: Number(area.toFixed(2)),
        });
    }, [area, name, methods]);

    return { methods, onConfirm, isCreatingField: isLoadingDrawn || isLoadingEmptySpot, errors, treshold, inputRef };
};

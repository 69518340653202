import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { Button, SidePanel, SelectableList, Switch, SearchInput, Checkbox } from '@soil-capital/ui-kit/components';
import { IconPlusCircle, IconCheckCircle, IconLightning } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useOperationDuplicationRightPanelStyle } from './useOperationDuplicationRightPanel.style';
import { useTranslation } from 'react-i18next';
import { FieldCropWarningModal } from '../FieldCropWarningModal/FieldCropWarningModal';
import { ControlledSelectableList } from '@shared/components/form/ControlledSelectableList/ControlledSelectableCheckboxList';
import { useDuplicationModals } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useDuplicationModals';
import { formatNumber } from '@shared/utils';
import { useDuplicationFieldFilters } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useDuplicationFieldFilters';
import { useCanConfirmDuplication } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useCanConfirmDuplication';
import { useFieldSelectionLogic } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useFieldSelectionLogic';
import { type DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { OPERATION_DUPLICATION_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationForm';
import { useOperationDuplicationFormValues } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationFormValues';
import { featureSelectionLayers, MapControl, MapLayers, MapPortal, MapRoot } from '@shared/map';
import { MapBanner } from '@shared/map/components/MapBanner/MapBanner';
import { fieldSelectionDuplicationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import { useOperationDuplicationRightPanelLogic } from './useOperationDuplicationRightPanel.logic';
import { FieldCropWarningModalForSelectAll } from '../FieldCropWarningModalForSelectAll/FieldCropWarningModalForSelectAll';

export type OperationDuplicationRightPanelProps = {
    sourceField: DuplicationFieldT;
    targetFields: DuplicationFieldT[];
    onConfirmDuplicationClick: (selectedFieldNbr: number) => void;
};

export const OperationDuplicationRightPanel = ({
    sourceField,
    targetFields,
    onConfirmDuplicationClick,
}: OperationDuplicationRightPanelProps) => {
    const { t } = useTranslation();
    const { classes } = useOperationDuplicationRightPanelStyle();

    const { fieldCropIds, operationIds } = useOperationDuplicationFormValues();

    const {
        search,
        handleResetSearch,
        handleSearchChange,
        handleToggleSelectedFields,
        toggleSelectedFields,
        doesFieldMatchFilters,
    } = useDuplicationFieldFilters();

    const isAllowedToConfirm = useCanConfirmDuplication();

    const {
        openWarningModal,
        handleCloseWarningModal,
        handleOpenWarningModal,
        openWarningModalForSelectAll,
        handleOpenWarningModalForSelectAll,
        handleCloseWarningModalForSelectAll,
    } = useDuplicationModals();

    const {
        selectedField,
        selectedFieldOperationTypes,
        handleFieldSelection,
        handleConfirmSelection,
        selectAll,
        handleSelectAllFieldsClick,
        handleConfirmSelectAllFields,
    } = useFieldSelectionLogic({
        sourceField,
        targetFields,
        onFieldSelected: handleOpenWarningModal,
        onConfirmSelection: handleCloseWarningModal,
        onSelectAllFields: handleOpenWarningModalForSelectAll,
        onConfirmSelectAllFields: handleCloseWarningModalForSelectAll,
    });

    const {
        allFields,
        dispatch,
        mapLayerSource,
        polygons,
        onFeatureClick,
        onFeatureHover,
        fieldIdHovered,
        fieldIdsFocused,
        selectedFieldIds,
    } = useOperationDuplicationRightPanelLogic(sourceField, targetFields);

    return (
        <SidePanel
            className={classes.panel}
            header={
                <SidePanel.Header>
                    <Typography variant="h3" marginBottom={2}>
                        {t('encoding-technical-itinerary.duplication.modal.right-panel.title')}
                    </Typography>
                    <Typography color="neutral" variant="body2" component="p" marginBottom={1}>
                        {t('encoding-technical-itinerary.duplication.modal.right-panel.description')}
                    </Typography>
                    <SearchInput
                        placeholder={t('constants.search-field')}
                        value={search}
                        onClearClick={handleResetSearch}
                        onChange={handleSearchChange}
                    />
                    <Switch
                        className={classes.switch}
                        labelPlacement="start"
                        checked={toggleSelectedFields}
                        onChange={handleToggleSelectedFields}
                        label={t('encoding-technical-itinerary.duplication.modal.right-panel.toggle-selected')}
                    />
                    <div className={classes.selectAll}>
                        <Typography color="neutral" variant="body2">
                            {selectAll ? t('constants.unselect-all') : t('constants.select-all')}
                        </Typography>
                        <Checkbox checked={selectAll} onClick={handleSelectAllFieldsClick} />
                    </div>
                </SidePanel.Header>
            }
            body={
                <SidePanel.Body>
                    <ControlledSelectableList
                        name={OPERATION_DUPLICATION_INPUT_NAMES.TARGET_FIELD_CROP_IDS}
                        options={targetFields}
                        getOptionKey={(fc) => fc.fieldCrop.id}
                        getOptionValue={(fc) => fc.fieldCrop.id}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        renderOption={(option, { isSelected: ignoredIsSelected, ...restProps }) => {
                            const isSelected = selectedFieldIds.includes(option.id);
                            const shouldBeDisplayed = doesFieldMatchFilters(option, isSelected);
                            return (
                                <SelectableList.Item
                                    key={option.id}
                                    className={shouldBeDisplayed ? classes.hide : ''}
                                    isSelected={isSelected}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleFieldSelection(e, option);
                                    }}
                                    {...restProps}
                                >
                                    <FieldCropInfoBox
                                        key={option.id}
                                        className={classes.fieldInfoBoxOverride}
                                        icon={isSelected ? <IconCheckCircle color="success" /> : <IconPlusCircle />}
                                        fieldName={option.name}
                                        fieldArea={`${formatNumber(option.area, 2)} HA`}
                                        cropNames={[
                                            {
                                                id: option.staticCrop.id,
                                                name: t(`db.crop.slug-${option.staticCrop.slug}`),
                                            },
                                        ]}
                                        mapSnapshot={option.snapshot}
                                        handleFieldClick={(event) => {
                                            event.preventDefault();
                                            dispatch(
                                                fieldSelectionDuplicationSlice.actions.setFocusedFieldIds([option.id]),
                                            );
                                        }}
                                    />
                                </SelectableList.Item>
                            );
                        }}
                    />
                </SidePanel.Body>
            }
            footer={
                <SidePanel.Footer>
                    <Button
                        onClick={() => {
                            onConfirmDuplicationClick(fieldCropIds.length);
                        }}
                        disabled={!isAllowedToConfirm}
                        fullWidth
                        variant="contained"
                        type="submit"
                    >
                        <IconLightning />
                        {t('encoding-technical-itinerary.duplication.modal.right-panel.confirm-copy', {
                            count: fieldCropIds.length,
                        })}
                    </Button>
                </SidePanel.Footer>
            }
        >
            <MapRoot />
            <MapPortal>
                <MapBanner helpText={t('selection-on-map-available')} />
                <MapControl
                    polygons={polygons}
                    fallbackCoord={[
                        // as we are in a modal after the field assignation and use initialFieldIdsfocused, we don't need a real fallback. We always have polygons
                        4.8151,
                        50.6242,
                    ]}
                />
                <MapLayers
                    onFeatureClick={onFeatureClick}
                    onFeatureHover={onFeatureHover}
                    focusedIds={fieldIdsFocused || allFields.map((field) => field.id)}
                    selectedIds={selectedFieldIds}
                    hoveredId={fieldIdHovered}
                    dataSource={mapLayerSource}
                    layers={featureSelectionLayers}
                />
            </MapPortal>
            {selectedField && selectedFieldOperationTypes.length > 0 && (
                <FieldCropWarningModal
                    open={openWarningModal}
                    onClose={handleCloseWarningModal}
                    onConfirm={handleConfirmSelection}
                    sourceFieldName={sourceField.name}
                    selectedFieldName={selectedField.name}
                    selectedOperationTypeNames={selectedFieldOperationTypes}
                />
            )}
            <FieldCropWarningModalForSelectAll
                open={openWarningModalForSelectAll}
                onClose={handleCloseWarningModalForSelectAll}
                onConfirm={handleConfirmSelectAllFields}
                sourceFieldName={sourceField.name}
                nbrOfSelectedFields={targetFields.length}
                selectedOperationTypes={operationIds}
            />
        </SidePanel>
    );
};

import entities from '@shared/entities';
import { ReplaceCropModalProps } from './ReplaceCropModal';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export const useReplaceCropModalLogic = ({
    controller,
}: {
    controller: ReplaceCropModalProps['replaceCropModalController'];
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { currentFarm } = useCurrentFarm();
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });

    const [createFieldCrop, { isLoading: addFieldCropLoading }] = entities.fieldCrop.useCreate();
    const [replaceCropMutation, { isLoading: replaceCropLoading }] = entities.field.useReplaceCrops();

    const existingFieldCrops = controller.data?.existingFieldCropIds.map((fieldCropId) =>
        fieldCropState.getById(fieldCropId),
    );

    const previousCrops = existingFieldCrops?.map((fieldCrop) => cropState.getById(fieldCrop?.farm_season_crop_id));
    const previousStaticCrops = previousCrops?.map((crop) => staticCropState.getById(crop?.crop_id));
    const previousCropNames = previousStaticCrops
        ?.map((staticCrop) => (staticCrop?.name ? t(`db.crop.slug-${staticCrop.slug}`) : null))
        .filter((name): name is string => !!name);
    const previousCropCount = previousCropNames?.length ?? 0;
    const previousCropName = previousCropNames?.join(', ');

    const currentField = fieldState.getById(existingFieldCrops?.[0]?.farm_season_field_id);
    const fieldName = currentField?.name;
    const newCrop = cropState.getById(controller.data?.newCropId);
    const newStaticCrop = staticCropState.getById(newCrop?.crop_id);
    const newCropName = newStaticCrop?.name ? t(`db.crop.slug-${newStaticCrop?.slug}`) : '';

    const addCrop = async () => {
        if (!newCrop || !currentField) {
            return;
        }
        try {
            await createFieldCrop({
                farmSeasonId: currentSeasonId,
                body: {
                    farm_season_crop_id: newCrop.id,
                    farm_season_field_id: currentField.id,
                },
            }).unwrap();
            enqueueSnackbar(t('encoding-rotation.replace-crop-modal.add-success-snackbar', { newCropName }), {
                variant: 'success',
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            if (e.status === 400 && e.data?.message) {
                const yearRegex = /\d{4}(?:,\s*\d{4})*/g;
                const yearsMatch = e.data.message.match(yearRegex);
                const errorMessage = yearsMatch
                    ? t('encoding-rotation.replace-crop-modal.add-fail-with-years', {
                          harvestYears: yearsMatch[0],
                      })
                    : e.data.message;
                enqueueSnackbar(errorMessage, { variant: 'error' });
            } else {
                enqueueSnackbar(
                    t('encoding-rotation.replace-crop-modal.add-fail-snackbar', {
                        newCropName,
                    }),
                    { variant: 'error' },
                );
            }
        }
        controller.onClose?.();
    };

    const replaceCrop = async () => {
        if (!newCrop || !currentField) {
            return;
        }
        try {
            await replaceCropMutation({
                farmSeasonId: currentSeasonId,
                fieldId: currentField.id,
                body: {
                    newCropId: newCrop.id,
                },
            }).unwrap();
            enqueueSnackbar(
                t('encoding-rotation.replace-crop-modal.replace-success-snackbar', {
                    newCropName,
                    previousCropName,
                    count: previousCropCount,
                }),
                { variant: 'success' },
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            if (e.status === 400 && e.data?.message) {
                const yearRegex = /\d{4}(?:,\s*\d{4})*/g;
                const yearsMatch = e.data.message.match(yearRegex);
                const errorMessage = yearsMatch
                    ? t('encoding-rotation.replace-crop-modal.replace-fail-with-years', {
                          harvestYears: yearsMatch[0],
                      })
                    : e.data.message;
                enqueueSnackbar(errorMessage, { variant: 'error' });
            } else {
                enqueueSnackbar(
                    t('encoding-rotation.replace-crop-modal.replace-fail-snackbar', {
                        newCropName,
                        previousCropName,
                        count: previousCropCount,
                    }),
                    { variant: 'error' },
                );
            }
        }
        controller.onClose?.();
    };

    return {
        previousCropName,
        newCropName,
        previousCropCount,
        fieldName,
        replaceCrop,
        addCrop,
        addFieldCropLoading,
        replaceCropLoading,
    };
};

import { BillingInfoForm } from '@modules/billing/components/BillingInfoForm/BillingInfoForm';
import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { BillingFormSkeleton } from '@modules/payment/pages/BillingInfo/components/BillingFormSkeleton';
import { Grid, useTheme } from '@soil-capital/ui-kit/material-core';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useBillingInformationLogic } from './useBillingInformationLogic';
import { UpdateBankAccountForm } from '@modules/onboarding/components/UpdateBankAccountForm/UpdateBankAccountForm';

export const BillingInformation = (): JSX.Element => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        isLoading,
        billingInfo,
        refetch,
        isFormValid,
        isFormSubmitting,
        setIsFormSubmitting,
        desactivateConfirmButton,
        partnerData,
    } = useBillingInformationLogic();
    const onConfirmRef = useRef<(() => void) | null>(null);
    const handleConfirmClick = async () => {
        if (onConfirmRef.current) {
            onConfirmRef.current();
        }
    };

    return (
        <OnboardingStepsModal
            stepProgress={1}
            ctaAction={handleConfirmClick}
            ctaText={t('pages.onboarding.eligibility.cta')}
            isCTAdisabled={!isFormValid}
            isCTAloading={isFormSubmitting}
            onBackClick={() => navigate('/onboarding/eligibility')}
        >
            <Grid paddingTop={theme.spacing(6)}>
                {isLoading ? (
                    <Grid gap={theme.spacing(3)} display="flex" flexDirection="column">
                        <BillingFormSkeleton />
                    </Grid>
                ) : partnerData?.is_partner_prepaying ? (
                    <UpdateBankAccountForm
                        refetch={refetch}
                        setOnConfirm={(onConfirm) => (onConfirmRef.current = onConfirm)}
                        setIsSubmitting={(isFormSubmitting) => {
                            setIsFormSubmitting(isFormSubmitting);
                        }}
                        nextAction={() => navigate('/onboarding/commitment')}
                        userCompany={billingInfo}
                        setDisableContinue={desactivateConfirmButton}
                    />
                ) : billingInfo ? (
                    <BillingInfoForm
                        refetch={refetch}
                        setOnConfirm={(onConfirm) => (onConfirmRef.current = onConfirm)}
                        setDisableContinue={desactivateConfirmButton}
                        setIsSubmitting={(isFormSubmitting) => setIsFormSubmitting(isFormSubmitting)}
                        nextAction={() => navigate('/onboarding/commitment')}
                        userCompany={billingInfo}
                    />
                ) : (
                    <BillingInfoForm
                        refetch={refetch}
                        setOnConfirm={(onConfirm) => (onConfirmRef.current = onConfirm)}
                        setDisableContinue={desactivateConfirmButton}
                        setIsSubmitting={(isFormSubmitting) => setIsFormSubmitting(isFormSubmitting)}
                        nextAction={() => navigate('/onboarding/commitment')}
                    />
                )}
            </Grid>
        </OnboardingStepsModal>
    );
};

import * as z from 'zod';
import {
    FARM_PROFILE_STATIC_DATA_DIFFICULTIES_SLUGS,
    FARM_PROFILE_STATIC_DATA_DOCUMENTS_FMIS_SLUGS,
    FARM_PROFILE_STATIC_DATA_DOCUMENTS_SLUGS,
    FARM_PROFILE_STATIC_DATA_EXPECTATIONS_SLUGS,
    FARM_PROFILE_STATIC_DATA_FARM_TYPE_SLUGS,
    FARM_PROFILE_STATIC_DATA_OBJECTIVES_SLUGS,
    FARM_PROFILE_STATIC_DATA_TILLAGE_PRACTICE_SLUGS,
    FARM_PROFILE_STATIC_DATA_TYPE,
    type FarmProfileFarmTypeSlugsT,
    type FarmProfileTillagePracticeSlugsT,
} from '@modules/encoding/modules/farmProfile/farmProfile.types';
import { useForm } from '@shared/hooks';
import {
    type FarmSeasonProfileDataComputedT,
    useFarmSeasonProfileComputed,
} from '@modules/encoding/modules/farmProfile/hooks/useFarmSeasonProfileComputed';
import { useFarmProfileOptions } from '@modules/encoding/modules/farmProfile/hooks/useFarmProfileOptions';
import { useParams } from 'react-router-dom';
import { useDeleteFarmSeasonProfileStaticDataMutation } from '@modules/encoding/modules/farmProfile/api/farmProfileApi';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import { useModalController } from '@shared/hooks/useModalController';
import { ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import entities from '@shared/entities';
import { useUpdateFarmSeasonProfile } from '@modules/encoding/modules/farmProfile/hooks/useUpdateFarmSeasonProfile';

export const FARM_PROFILE_INPUT_NAME = {
    HAS_INTER_CROP_COVER: 'has_inter_crop_cover',
    USE_ORGANIC_FERTILIZER: 'use_organic_fertilizer',
    HAS_AGROFORESTRY: 'has_agroforestry',
    HAS_DRAINED_FIELDS: 'has_drained_fields',
    HAS_IRRIGATED_FIELDS: 'has_irrigated_fields',
    HAS_LIVESTOCK: 'has_livestock',
    FARM_TYPE: 'farm_type',
    TILLAGE_PRACTICE: 'tillage_practice',
    OBJECTIVES: 'objectives',
    DIFFICULTIES: 'difficulties',
    EXPECTATIONS: 'expectations',
    DOCUMENTS: 'documents',
    DOCUMENT_OTHER: 'document_other',
    DOCUMENTS_FMIS: 'documents_fmis',
    DOCUMENT_FMIS_OTHER: 'document_fmis_other',
} as const;

const schema = z.object({
    [FARM_PROFILE_INPUT_NAME.HAS_INTER_CROP_COVER]: z.boolean().nullable(),
    [FARM_PROFILE_INPUT_NAME.USE_ORGANIC_FERTILIZER]: z.boolean().nullable(),
    [FARM_PROFILE_INPUT_NAME.HAS_AGROFORESTRY]: z.boolean().nullable(),
    [FARM_PROFILE_INPUT_NAME.HAS_DRAINED_FIELDS]: z.boolean().nullable(),
    [FARM_PROFILE_INPUT_NAME.HAS_IRRIGATED_FIELDS]: z.boolean().nullable(),
    [FARM_PROFILE_INPUT_NAME.HAS_LIVESTOCK]: z.boolean().nullable(),
    [FARM_PROFILE_INPUT_NAME.FARM_TYPE]: z.nativeEnum(FARM_PROFILE_STATIC_DATA_FARM_TYPE_SLUGS).or(z.literal('')),
    [FARM_PROFILE_INPUT_NAME.TILLAGE_PRACTICE]: z
        .nativeEnum(FARM_PROFILE_STATIC_DATA_TILLAGE_PRACTICE_SLUGS)
        .or(z.literal('')),
    [FARM_PROFILE_INPUT_NAME.OBJECTIVES]: z.array(
        z.object({
            id: z.number(),
            type: z.literal(FARM_PROFILE_STATIC_DATA_TYPE.FARM_OBJECTIVES),
            name: z.string(),
            slug: z.nativeEnum(FARM_PROFILE_STATIC_DATA_OBJECTIVES_SLUGS),
        }),
    ),
    [FARM_PROFILE_INPUT_NAME.DIFFICULTIES]: z.array(
        z.object({
            id: z.number(),
            type: z.literal(FARM_PROFILE_STATIC_DATA_TYPE.FARM_DIFFICULTIES),
            name: z.string(),
            slug: z.nativeEnum(FARM_PROFILE_STATIC_DATA_DIFFICULTIES_SLUGS),
        }),
    ),
    [FARM_PROFILE_INPUT_NAME.EXPECTATIONS]: z.array(
        z.object({
            id: z.number(),
            type: z.literal(FARM_PROFILE_STATIC_DATA_TYPE.FARM_EXPECTATIONS),
            name: z.string(),
            slug: z.nativeEnum(FARM_PROFILE_STATIC_DATA_EXPECTATIONS_SLUGS),
        }),
    ),
    [FARM_PROFILE_INPUT_NAME.DOCUMENTS]: z.array(
        z.object({
            id: z.number(),
            type: z.literal(FARM_PROFILE_STATIC_DATA_TYPE.FARM_DOCUMENTS),
            name: z.string(),
            slug: z.nativeEnum(FARM_PROFILE_STATIC_DATA_DOCUMENTS_SLUGS),
        }),
    ),
    [FARM_PROFILE_INPUT_NAME.DOCUMENT_OTHER]: z.string().nullable(),
    [FARM_PROFILE_INPUT_NAME.DOCUMENTS_FMIS]: z.array(
        z.object({
            id: z.number(),
            type: z.literal(FARM_PROFILE_STATIC_DATA_TYPE.FARM_DOCUMENTS_FMIS),
            name: z.string(),
            slug: z.nativeEnum(FARM_PROFILE_STATIC_DATA_DOCUMENTS_FMIS_SLUGS),
        }),
    ),
    [FARM_PROFILE_INPUT_NAME.DOCUMENT_FMIS_OTHER]: z.string().nullable(),
});

type ModalType = 'livestock' | 'irrigation' | 'drainage' | 'agroforestry';
type FarmProfileFieldNameType = (typeof FARM_PROFILE_INPUT_NAME)[keyof typeof FARM_PROFILE_INPUT_NAME];

const computeFarmSeasonProfileDataToMatchSchema = (data: FarmSeasonProfileDataComputedT) => {
    const { farmType, tillagePractice, objectives, difficulties, expectations, documents, documentsFmis, ...rest } =
        data || {};
    return {
        ...rest,
        // Need casting, or it will be considered string because of empty string
        farm_type: (farmType?.slug ? farmType.slug : '') as FarmProfileFarmTypeSlugsT | '',
        tillage_practice: (tillagePractice?.slug ? tillagePractice.slug : '') as FarmProfileTillagePracticeSlugsT | '',
        objectives: objectives || [],
        difficulties: difficulties || [],
        expectations: expectations || [],
        documents: documents || [],
        documents_fmis: documentsFmis || [],
        document_other: data?.document_other ?? null,
        document_fmis_other: data?.document_fmis_other || null,
    };
};

export type FarmProfileFormData = z.infer<typeof schema>;

export const useFarmProfileForm = () => {
    const { seasonId } = useParams();
    // We know from encoding guard that this param exists
    const farmSeasonId = Number(seasonId as string);

    const { data: defaultValues, farmSeasonProfileStaticDataIds } = useFarmSeasonProfileComputed({
        farmSeasonId,
    });
    const { livestockState } = entities.livestock.useState({ farmSeasonId: farmSeasonId });
    const { irrigationState } = entities.irrigation.useState({ farmSeasonId: farmSeasonId });
    const { fieldState } = entities.field.useState({ farmSeasonId: farmSeasonId });

    const farmSeasonProfileId = defaultValues?.id;

    const { ...options } = useFarmProfileOptions();
    const { mutate } = useUpdateFarmSeasonProfile({ farmSeasonId, farmSeasonProfileId });
    const [deleteStaticData] = useDeleteFarmSeasonProfileStaticDataMutation();

    const methods = useForm<typeof schema>({
        schema,
        defaultValues: computeFarmSeasonProfileDataToMatchSchema(defaultValues ?? null),
        displaySnackbarOnSchemaError: true,
    });

    const { handleSubmit } = methods;

    const onSubmit = useStableDebounce(
        handleSubmit(async (data: FarmProfileFormData) => {
            const {
                farm_type,
                tillage_practice,
                objectives,
                difficulties,
                expectations,
                documents,
                documents_fmis,
                ...rest
            } = data;
            const farmTypeStaticDataId = options?.farmTypes?.find((el) => el.slug === farm_type)?.id;
            const tillagePracticeStaticDataId = options?.tillagePractices?.find(
                (el) => el.slug === tillage_practice,
            )?.id;
            const staticDataIds = [
                ...(farmTypeStaticDataId ? [farmTypeStaticDataId] : []),
                ...(tillagePracticeStaticDataId ? [tillagePracticeStaticDataId] : []),
                ...(objectives ?? [])?.map((item) => item.id),
                ...(difficulties ?? [])?.map((item) => item.id),
                ...(expectations ?? [])?.map((item) => item.id),
                ...(documents ?? [])?.map((item) => item.id),
                ...(documents_fmis ?? [])?.map((item) => item.id),
            ];

            const staticDataIdsToDelete = farmSeasonProfileStaticDataIds?.filter((id) => !staticDataIds.includes(id));

            if (farmSeasonProfileId && staticDataIdsToDelete && staticDataIdsToDelete?.length > 0)
                await deleteStaticData({
                    farmSeasonId,
                    farmSeasonProfileId,
                    static_data_ids: staticDataIdsToDelete ?? [],
                });
            await mutate({ ...rest, static_data_ids: staticDataIds });
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    const { open: openConfirmDeleteModal, ...confirmDeleteModalProps } = useModalController<
        { modalType: ModalType },
        ConfirmModalCloseDataT
    >();

    const handleOpenWarningModal = async (
        value: 'true' | 'false',
        modalType: ModalType,
        condition: boolean,
        fieldName: FarmProfileFieldNameType,
    ) => {
        if (value === 'false' && condition) {
            const { confirmed } = await openConfirmDeleteModal({ modalType });
            if (!confirmed) {
                methods.resetField(fieldName);
                return;
            }
        }
        onSubmit();
    };

    const handleOpenLivestockWarningModal = (value: 'true' | 'false') =>
        handleOpenWarningModal(
            value,
            'livestock',
            livestockState.list?.length > 0,
            FARM_PROFILE_INPUT_NAME.HAS_LIVESTOCK,
        );

    const handleOpenIrrigationWarningModal = (value: 'true' | 'false') =>
        handleOpenWarningModal(
            value,
            'irrigation',
            irrigationState.list?.length > 0,
            FARM_PROFILE_INPUT_NAME.HAS_IRRIGATED_FIELDS,
        );

    const handleOpenDrainageWarningModal = (value: 'true' | 'false') =>
        handleOpenWarningModal(
            value,
            'drainage',
            fieldState.list.filter((el) => el.has_drainage)?.length > 0,
            FARM_PROFILE_INPUT_NAME.HAS_DRAINED_FIELDS,
        );

    const handleOpenAgroforestryWarningModal = (value: 'true' | 'false') =>
        handleOpenWarningModal(
            value,
            'agroforestry',
            fieldState.list.filter((el) => el.has_agroforestry)?.length > 0,
            FARM_PROFILE_INPUT_NAME.HAS_AGROFORESTRY,
        );

    return {
        methods,
        options: {
            farmType: options.farmTypes,
            tillagePractice: options.tillagePractices,
            objectives: options.objectives,
            difficulties: options.difficulties,
            expectations: options.expectations,
            documents: options.documents,
            fmis: options.fmis,
        },
        onSubmit,
        confirmDeleteModalProps,
        handleOpenLivestockWarningModal,
        handleOpenIrrigationWarningModal,
        handleOpenDrainageWarningModal,
        handleOpenAgroforestryWarningModal,
    };
};

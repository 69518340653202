import { MapLayerListT } from '../MapLayers';

export const farmSeasonBoundariesLayers: MapLayerListT = [
    {
        id: 'feature-farm-boundaries',
        type: 'line',
        paint: {
            'line-color': 'blue',
            'line-width': 5,
        },
    },
];

import palette from '@shared/constants/cropPalette';
import { FieldWithCropT } from '../../../../shared/map/types/mapTypes';
import { CropT } from '@shared/entities';
import getUniqueCrops from '../../modules/rotation/helpers/getUniqueCrops';

interface CropColorsT {
    [key: number]: string;
}

const sortAndAssignColors = (items: { id: number }[]): CropColorsT => {
    const sortedItems = [...items].sort((a, b) => a.id - b.id);
    const cropColors: CropColorsT = {};

    sortedItems.forEach((item, index) => {
        cropColors[item.id] = palette[index];
    });

    return cropColors;
};

const setupCropPalette = (fieldsArray: FieldWithCropT[]): CropColorsT => {
    const crops = getUniqueCrops(fieldsArray);
    return sortAndAssignColors(crops);
};

export const setupCropPaletteFromCrop = (crops: CropT[]): CropColorsT => {
    const sortedCrops = crops?.sort((a, b) => a.crop_id - b.crop_id) || [];
    return sortAndAssignColors(sortedCrops);
};

export default setupCropPalette;

import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCoverCropDestructionMethodQuestionStyles = makeStyles()((theme) => ({
    questionContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));

import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import useOperationContextualPanelLogic from './useOperationContextualPanel.logic';
import OperationTimelineNav from '../../components/OperationTimelineNav/OperationTimelineNav';
import { Breadcrumbs } from '@soil-capital/ui-kit/components';
import { FieldMapSnapshot } from '@modules/encoding/shared/components/FieldMapSnapshot/FieldMapSnapshot';
import { IconFile } from '@soil-capital/ui-kit/icons';
import { Grid, useTheme } from '@soil-capital/ui-kit/material-core';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const theme = useTheme();
    const {
        fieldCropId,
        operationId,
        seasonId,
        fieldId,
        isLoading,
        setFieldMapSnapshotIsLoading,
        setTimelineNavIsLoading,
        setFieldIdentityCardIsLoading,
        breadcrumbItems,
    } = useOperationContextualPanelLogic();

    return (
        <encodingShared.ContextualPanel isLoading={isLoading}>
            <FieldMapSnapshot
                farmSeasonId={seasonId}
                fieldId={fieldId}
                header={
                    <Grid display="flex" alignItems="center" gap={theme.spacing(1)}>
                        <IconFile fontSize="small" />
                        <Breadcrumbs items={breadcrumbItems} />
                    </Grid>
                }
                setFieldMapSnapshotIsLoading={setFieldMapSnapshotIsLoading}
            >
                <encodingShared.FieldIdentityCard
                    fieldCropId={fieldCropId}
                    setIsLoading={setFieldIdentityCardIsLoading}
                />
            </FieldMapSnapshot>

            <OperationTimelineNav
                fieldCropId={fieldCropId}
                operationId={operationId}
                seasonId={seasonId}
                navigate={navigate}
                setTimelineNavIsLoading={setTimelineNavIsLoading}
            />
        </encodingShared.ContextualPanel>
    );
};

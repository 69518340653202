import { FarmPaymentStatusT } from '@modules/payment/api/payment.types';
import { FARM_SEASON_STATUS, SeasonStatusT } from '@shared/entities/season/season.types';

export type GetHomePageStatusPropsT = {
    farmPaymentStatus?: FarmPaymentStatusT['status'];
    farmSeasonStatus?: SeasonStatusT;
    isYoY: boolean;
    hasSeenResultsAvailableMsg: boolean;
    isRenewalDisabled: boolean;
};

export type HomePageStatusT =
    | 'SELF_ENCODING_NOT_STARTED_BASELINE'
    | 'SELF_ENCODING_NOT_STARTED_YOY'
    | 'SELF_ENCODING_ONGOING'
    | 'DATA_VERIFICATION'
    | 'WAITING_FOR_RESULTS'
    | 'RESULTS_PUBLISHED_FIRST_CONNEXION'
    | 'RESULTS_PUBLISHED_DEFERRED_PAYMENT'
    | 'RESULTS_PUBLISHED_RENEWAL_PAYMENT'
    | 'RENEWAL_DISABLED'
    | null;

export const getHomePageStatus = ({
    farmSeasonStatus,
    farmPaymentStatus,
    isYoY,
    hasSeenResultsAvailableMsg,
    isRenewalDisabled,
}: GetHomePageStatusPropsT): HomePageStatusT => {
    switch (true) {
        case farmSeasonStatus === FARM_SEASON_STATUS.DATA_INPUT_NOT_STARTED && !isYoY:
            return 'SELF_ENCODING_NOT_STARTED_BASELINE';
        case farmSeasonStatus === FARM_SEASON_STATUS.DATA_INPUT_IN_PROGRESS:
            return 'SELF_ENCODING_ONGOING';
        case farmSeasonStatus === FARM_SEASON_STATUS.DATA_INPUT_VALIDATED:
            return 'DATA_VERIFICATION';
        case farmSeasonStatus === FARM_SEASON_STATUS.RESULTS_AVAILABLE ||
            farmSeasonStatus === FARM_SEASON_STATUS.WAITING_FOR_RESULTS:
            return 'WAITING_FOR_RESULTS';
        case farmSeasonStatus === FARM_SEASON_STATUS.RESULTS_PUBLISHED && !hasSeenResultsAvailableMsg:
            return 'RESULTS_PUBLISHED_FIRST_CONNEXION';
        case farmSeasonStatus === FARM_SEASON_STATUS.RESULTS_PUBLISHED &&
            farmPaymentStatus === 'deferred' &&
            !isRenewalDisabled:
            return 'RESULTS_PUBLISHED_DEFERRED_PAYMENT';
        case farmSeasonStatus === FARM_SEASON_STATUS.RESULTS_PUBLISHED &&
            farmPaymentStatus === 'to_be_renewed' &&
            !isRenewalDisabled:
            return 'RESULTS_PUBLISHED_RENEWAL_PAYMENT';
        case isRenewalDisabled:
            return 'RENEWAL_DISABLED';
        case farmSeasonStatus === FARM_SEASON_STATUS.DATA_INPUT_NOT_STARTED ||
            farmSeasonStatus === FARM_SEASON_STATUS.RESULTS_PUBLISHED:
            return 'SELF_ENCODING_NOT_STARTED_YOY';
        default:
            return null;
    }
};

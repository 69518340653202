import { useEffect, useState } from 'react';
import { FINALISE_FORM_INPUT_NAMES, useFinaliseForm } from './useFinaliseForm';
import { cropNamesT } from '@shared/map/types/mapTypes';

export const useFinaliseDuplicationModal = ({
    onConfirm,
    fieldCrops,
}: {
    onConfirm: () => void;
    fieldCrops: {
        id: number;
        fieldName: string;
        fieldArea: string;
        crops?: cropNamesT[];
        mapSnapshot: string | null;
    }[];
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const { methods, onSubmit } = useFinaliseForm({ onMutationSuccess: onConfirm });

    const selectedFields = methods.watch(FINALISE_FORM_INPUT_NAMES.FINALISED_FIELD_CROP_IDS);
    useEffect(() => {
        setSelectAll(selectedFields.length === fieldCrops.length);
    }, [fieldCrops.length, selectedFields]);

    const handleSelectAllFieldsClick = () => {
        const selectAllPreviousValue = selectAll;
        if (selectAllPreviousValue) {
            methods.setValue(FINALISE_FORM_INPUT_NAMES.FINALISED_FIELD_CROP_IDS, []);
        } else {
            methods.setValue(FINALISE_FORM_INPUT_NAMES.FINALISED_FIELD_CROP_IDS, [...fieldCrops.map((el) => el.id)]);
        }
    };

    return {
        methods,
        isLoading,
        selectAll,
        setSelectAll,
        onSubmit,
        setIsLoading,
        handleSelectAllFieldsClick,
    };
};

import React from 'react';
import { Select } from '@soil-capital/ui-kit/components';
import { MenuItem, Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import useFieldSorterStyles from './FieldSorter.style';
import { SORT_OPTIONS, SortOptionT } from '../../hooks/useSortedFields';

export type FieldSorterProps = {
    value: SortOptionT;
    onChange: (event: SelectChangeEvent<unknown>) => void;
    classname?: string;
};

export const FieldSorter: React.FC<FieldSorterProps> = ({ value, onChange, classname }) => {
    const { t } = useTranslation();
    const { classes, cx } = useFieldSorterStyles();

    const sortOptions = [
        { label: t('encoding.rotation.sort-field.area-asc'), value: SORT_OPTIONS.AREA_ASC },
        { label: t('encoding.rotation.sort-field.area-dsc'), value: SORT_OPTIONS.AREA_DSC },
        { label: t('encoding.rotation.sort-field.name-asc'), value: SORT_OPTIONS.NAME_ASC },
        { label: t('encoding.rotation.sort-field.name-dsc'), value: SORT_OPTIONS.NAME_DSC },
    ];

    return (
        <div className={cx(classes.flexbox, classname)}>
            <Select value={value} onChange={onChange} className={classes.flat}>
                {sortOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <Typography variant="caption" className={classes.label}>
                            {option.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

import * as z from 'zod';
import { useForm } from '@shared/hooks';
import { calculateAreaProperties } from '@shared/utils/calculateAreaProperties';
import { useTranslation } from 'react-i18next';
import { FieldPolygonT, FieldT } from '@shared/entities/field/field.types';
import { calculateArea } from '@modules/encoding/modules/rotation/helpers/polygonHelper';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef } from 'react';
import fieldApi from '@shared/entities/field/field.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { SharedStateT } from '@shared/store';
import { useSelector } from 'react-redux';

export const FIELD_MERGING_OPENDATA = {
    FIELD_NAME: 'field_name',
    AREA: 'area',
} as const;

export const useMergedFieldModalFormLogic = ({
    polygon,
    onClose,
    open,
}: {
    polygon: FieldPolygonT | null;
    onClose: () => void;
    open: boolean;
}) => {
    const shouldFocus = useRef(true);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [saveMergedFieldMutation, { isLoading }] = fieldApi.useSaveMerged();
    const polygonArea = calculateArea(polygon?.geometry as FieldPolygonT['geometry']);
    const { treshold, maxArea, minArea } = calculateAreaProperties(polygonArea);
    const { currentSeasonId } = useCurrentSeasonId();
    const selectedFieldIds = useSelector((state: SharedStateT) => state.fieldDefinition.selectedIds);

    const defaultValues = {
        [FIELD_MERGING_OPENDATA.FIELD_NAME]: '',
        [FIELD_MERGING_OPENDATA.AREA]: Number(polygonArea.toFixed(2)),
    };

    const schema = z.object({
        [FIELD_MERGING_OPENDATA.FIELD_NAME]: z.string().min(1, t('encoding.rotation.field-creation-modal.error-name')),
        [FIELD_MERGING_OPENDATA.AREA]: z
            .number()
            .min(
                Number(minArea.toFixed(2)),
                t('encoding.rotation.field-creation-modal.error-under-treshold', { treshold }),
            )
            .max(
                Number(maxArea.toFixed(2)),
                t('encoding.rotation.field-creation-modal.error-over-treshold', { treshold }),
            ),
    });

    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });

    const {
        reset,
        handleSubmit,
        formState: { isValid, errors },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        const name = data[FIELD_MERGING_OPENDATA.FIELD_NAME];

        const response = await saveMergedFieldMutation({
            farmSeasonId: currentSeasonId,
            body: {
                merged_field: {
                    name,
                    area: data[FIELD_MERGING_OPENDATA.AREA],
                    original_area: polygonArea,
                    polygon: polygon as FieldPolygonT,
                    polygon_geometry: polygon?.geometry as FieldPolygonT['geometry'],
                } as FieldT,
                from_field_ids: selectedFieldIds,
            },
        });
        if (response && 'error' in response) {
            enqueueSnackbar(t('encoding.rotation.field-creation-modal.error'), {
                variant: 'error',
            });
        }
    });

    const onConfirm = async () => {
        await onSubmit();

        if (isValid) {
            onClose();
        }
    };

    const inputRef = useCallback(
        (node: HTMLInputElement) => {
            if (node && open && shouldFocus.current) {
                node.focus();
                shouldFocus.current = false;
            }
        },
        [open],
    );

    useEffect(() => {
        reset({
            [FIELD_MERGING_OPENDATA.FIELD_NAME]: '',
            [FIELD_MERGING_OPENDATA.AREA]: Number(polygonArea.toFixed(2)),
        });
    }, [polygonArea, reset]);

    return {
        methods,
        onConfirm,
        isEditingField: isLoading,
        errors,
        inputRef,
        treshold,
    };
};

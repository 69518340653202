import { useForm } from '@shared/hooks';
import { useEffect } from 'react';
import { getOperationFormApi } from '@modules/encoding/modules/technicalItinerary/api/getOperationFormApi';
import entities from '@shared/entities';
import { useTranslation } from 'react-i18next';
import {
    SPRAYING_FORM_INPUT_NAMES,
    SprayingFormSchemaT,
    SprayingPesticideTypeT,
    sprayingFormSchema,
} from '@modules/encoding/modules/technicalItinerary/schema/sprayingFormschema';
import { useOperationFormDefaultValues } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationFormDefaultValues';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import { PESTICIDE_TYPE_SLUGS } from '@shared/entities/staticPesticide/staticPesticide.types';
import { useFieldCropHasCoverCropDestruction } from '@modules/encoding/modules/technicalItinerary/hooks/useFieldCropHasCoverCropDestruction';

export const useSprayingFormLogic = () => {
    const { t } = useTranslation();
    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();

    const { fieldCropHasCoverCropDestruction, isLoading } = useFieldCropHasCoverCropDestruction(
        Number(seasonId),
        Number(fieldCropId),
    );

    const { computeApiDataToMatchSchema, formApiData, isFormLoading, refetchFormData } =
        useOperationFormDefaultValues('spraying');

    // Mutations
    const [updateForm] = getOperationFormApi('spraying').useUpdate();

    const methods = useForm({
        schema: sprayingFormSchema.schema,
        defaultValues: computeApiDataToMatchSchema(),
        displaySnackbarOnSchemaError: true,
    });

    const { handleSubmit, reset, watch, setValue, getValues } = methods;

    // Reset form default values when API data is fetched
    useEffect(() => {
        if (formApiData) {
            reset(computeApiDataToMatchSchema(formApiData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiData, reset]);

    const pesticideTypes = watch(SPRAYING_FORM_INPUT_NAMES.PESTICIDE_TYPES);
    const totalPassage = watch(SPRAYING_FORM_INPUT_NAMES.TOTAL_PASSAGE);

    const getMinTotalPassage = (pesticideTypes: SprayingPesticideTypeT) => {
        return pesticideTypes?.reduce((acc, curr) => Math.max(acc, curr.passage || 0), 0);
    };

    const getMaxTotalPassage = (pesticideTypes: SprayingPesticideTypeT) => {
        return pesticideTypes?.reduce((acc, curr) => acc + (curr.passage || 0), 0);
    };

    const getTotalPassageError = (pesticideTypes: SprayingPesticideTypeT, totalPassage: number | null) => {
        const minTotalPassage = getMinTotalPassage(pesticideTypes);
        const maxTotalPassage = getMaxTotalPassage(pesticideTypes);

        return !totalPassage
            ? null
            : totalPassage < minTotalPassage
            ? t('encoding-technical-itinerary.spraying.total-passages.error.min', { minTotalPassage })
            : totalPassage > maxTotalPassage
            ? t('encoding-technical-itinerary.spraying.total-passages.error.max', { maxTotalPassage })
            : null;
    };

    const { staticPesticideTypeState } = entities.staticPesticideType.useState();

    // Form callbacks
    const submitForm = useStableDebounce(
        handleSubmit(async ({ pesticide_types, sprayer_width, total_passage }: SprayingFormSchemaT) => {
            try {
                const pesticideTypes = pesticide_types.map((element) => ({
                    id: element.id,
                    passage: element?.passage || null,
                    slug: element?.slug || null,
                }));

                if (pesticideTypes.length === 1) {
                    total_passage = pesticideTypes[0].passage;
                    setValue(SPRAYING_FORM_INPUT_NAMES.TOTAL_PASSAGE, total_passage);
                } else if (pesticideTypes.length === 0) {
                    total_passage = null;
                    setValue(SPRAYING_FORM_INPUT_NAMES.TOTAL_PASSAGE, total_passage);
                }

                const totalPassageError = getTotalPassageError(pesticideTypes, total_passage);

                if (totalPassageError === null) {
                    await updateForm({
                        farmSeasonId: seasonId,
                        fieldCropId,
                        operationId,
                        body: {
                            sprayer_width,
                            pesticide_types: pesticideTypes,
                            total_passage,
                        },
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    const staticPesticideTypelist = staticPesticideTypeState.list.sort((a, b) => {
        if (a.name === PESTICIDE_TYPE_SLUGS.MISCELANEOUS) {
            return 1;
        }
        if (b.name === PESTICIDE_TYPE_SLUGS.MISCELANEOUS) {
            return -1;
        }
        return 0;
    });

    return {
        methods,
        handleSubmit: submitForm,
        isFormLoading: isFormLoading || isLoading,
        pesticideTypes: staticPesticideTypelist,
        selectedPesticideTypes: pesticideTypes,
        totalPassageError: getTotalPassageError(pesticideTypes, totalPassage),
        fieldCropHasCoverCropDestruction,
        refetchFormData,
        getFormValues: getValues,
    };
};

import { IconEdit, IconCheckboxChecked, IconClose } from '@soil-capital/ui-kit/icons';
import { Button } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { EditedFieldModal } from '@modules/encoding/modules/rotation/components/EditedFieldModal/EditedFieldModal';
import { useEditFieldLogic } from './useEditField.logic';
import { FieldT, GeometryDataT } from '@shared/entities';
import { FeatureCollection, Geometry, GeometryCollection, Properties } from '@turf/helpers';
import { Dispatch, SetStateAction } from 'react';
import { MAPMODE, MapModeT } from '@modules/encoding/modules/rotation/components/PacImportMap/PacImportMap.logic';

export const EditField = ({
    readOnly,
    mapMode,
    setMapMode,
    currentFields,
    farmBoundaries,
    currentInvalidFields,
    isYoyFarmSeason,
    isDisabled,
}: {
    readOnly: boolean;
    mapMode: MapModeT;
    setMapMode: Dispatch<SetStateAction<MapModeT>>;
    currentFields: FieldT[];
    farmBoundaries: FeatureCollection<Geometry | GeometryCollection, Properties> | undefined;
    currentInvalidFields: GeometryDataT[];
    isYoyFarmSeason: boolean;
    isDisabled: boolean;
}) => {
    const { t } = useTranslation();
    const {
        editFieldModalOpen,
        onEditFieldModalOpen,
        onEditFieldModalClose,
        stopEditing,
        isSaveDisabled,
        editedFeatureId,
        saveUpdatedField,
        edit,
        updatedField,
    } = useEditFieldLogic({
        mapMode,
        setMapMode,
        currentFields,
        farmBoundaries,
        currentInvalidFields,
        isYoyFarmSeason,
    });

    return (
        <>
            {mapMode === MAPMODE.EDIT ? (
                <>
                    <Button startIcon={<IconClose sx={{ width: '20px', height: '20px' }} />} onClick={stopEditing}>
                        {t('constants.cancel')}
                    </Button>
                    <Button
                        startIcon={<IconCheckboxChecked sx={{ width: '20px', height: '20px' }} />}
                        disabled={!editedFeatureId || isSaveDisabled}
                        variant="contained"
                        onClick={() => {
                            editedFeatureId && saveUpdatedField();
                            onEditFieldModalOpen();
                        }}
                    >
                        {t('constants.save')}
                    </Button>
                </>
            ) : mapMode === MAPMODE.NONE ? (
                <Button
                    onClick={edit}
                    startIcon={<IconEdit color="primary" />}
                    disabled={readOnly || isDisabled}
                    sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                    {t('encoding-rotation.map.edit-field')}
                </Button>
            ) : null}
            {updatedField && (
                <EditedFieldModal
                    updatedField={updatedField}
                    open={editFieldModalOpen}
                    onClose={() => {
                        onEditFieldModalClose();
                        stopEditing();
                    }}
                />
            )}
        </>
    );
};

import { ERROR_TYPES } from '@shared/constants/errorTypes';

export const mapErrorName = (errorName: string) => {
    switch (errorName) {
        case ERROR_TYPES.PERMANENT_CROP_IN_PREVIOUS_YEARS:
            return 'errors.permanent_crop_in_previous_years';
        default:
            return 'constants.server-error';
    }
};

export const getErrorMessage = (error: unknown) => {
    if (!error) {
        return '';
    }

    if (error instanceof Error) {
        return error.message;
    }

    if (typeof error === 'string') {
        return error;
    }

    if (typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
        return error.message;
    }

    // RTK error
    if (typeof error === 'object' && 'data' in error) {
        if (
            error.data &&
            typeof error.data === 'object' &&
            'name' in error.data &&
            typeof error.data.name === 'string'
        ) {
            return mapErrorName(error.data.name);
        }
    }

    return 'constants.server-error';
};

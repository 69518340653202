import { useNavigate, useRoutes } from 'react-router-dom';
import { rotationRoutesConfig } from './rotation.routes';
import { IntroductionContentPage } from './pages/IntroductionContentPage/IntroductionContentPage';
import { encodingShared } from '@modules/encoding/shared';
import { PacImportContextualPanel } from './pages/PacImportContextualPanel/PacImportContextualPanel';
import { PacImportActionPage } from './pages/PacImportActionPage/PacImportActionPage';
import { Guard } from '@shared/guards/Guard';
import { IsStepVisibleAsyncGuard, IsStepVisibleGuard } from '@shared/guards/IsStepVisibleGuard';
import { ActionPageSkeleton } from '@modules/encoding/shared/components/ActionPage/ActionPageSkeleton';
import { ArePreviousStepCompletedGuard } from '@shared/guards/ArePreviousStepCompletedGuard';
import { CropDestinationPage } from './pages/CropDestinationPage/CropDestinationPage';
import { HasCropDestinationPageAccessAsyncGuard } from '@shared/guards/HasCropDestinationPageAccessAsyncGuard';
import { MapDrawingEventContextProvider } from './context/mapDrawingEventContextProvider';

export default () => {
    const navigate = useNavigate();

    const routes = useRoutes([
        {
            path: rotationRoutesConfig.introduction,
            element: (
                <Guard
                    element={<IntroductionContentPage />}
                    loader={<ActionPageSkeleton />}
                    guards={[IsStepVisibleGuard, ArePreviousStepCompletedGuard]}
                />
            ),
        },
        {
            path: '/',
            element: <PacImportContextualPanel navigate={navigate} />,
            children: [
                {
                    path: rotationRoutesConfig.pacImport,
                    element: (
                        <Guard
                            element={<PacImportActionPage navigate={navigate} />}
                            loader={<ActionPageSkeleton />}
                            guards={[IsStepVisibleGuard, ArePreviousStepCompletedGuard]}
                        />
                    ),
                },
                {
                    path: rotationRoutesConfig.cropDestination,
                    element: (
                        <Guard
                            element={<CropDestinationPage />}
                            guards={[
                                IsStepVisibleAsyncGuard,
                                ArePreviousStepCompletedGuard,
                                HasCropDestinationPageAccessAsyncGuard,
                            ]}
                        />
                    ),
                },
            ],
        },
    ]);

    return (
        <encodingShared.FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
            <MapDrawingEventContextProvider>{routes}</MapDrawingEventContextProvider>
        </encodingShared.FadeTransition>
    );
};

import { MapLayerListT } from '../MapLayers';
import { MAP_OPENDATA_CONFIG_SLUGS } from '../../../modules/encoding/modules/rotation/components/MapOpendataControl/MapOpendataControl.logic';

/**
 * Predefined layers for open-data parcels
 */
export const openDataLayers = (source: string): MapLayerListT => [
    {
        id: `${MAP_OPENDATA_CONFIG_SLUGS.LAYER_FILL}-${source}`,
        type: 'fill',
        'source-layer': MAP_OPENDATA_CONFIG_SLUGS.SOURCE_LAYER,
        paint: {
            'fill-color': 'white',
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hovered'], false], 0.3, 0],
        },
    },
    {
        id: `${MAP_OPENDATA_CONFIG_SLUGS.LAYER_LINES}-${source}`,
        type: 'line',
        'source-layer': MAP_OPENDATA_CONFIG_SLUGS.SOURCE_LAYER,
        paint: {
            'line-color': 'white',
            'line-width': 2,
            'line-opacity': ['case', ['boolean', ['feature-state', 'hovered'], false], 1, 0],
        },
    },
];

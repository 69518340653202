import { createSearchFieldsFetcher, SearchFieldParams } from '@modules/croppingView/api/searchFieldsFetcher';
import { Button, Input, MenuItem, Select } from '@soil-capital/ui-kit/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { formatNumber } from '@shared/utils';
import { makeStyles } from '@soil-capital/ui-kit/style';
import { SelectChangeEvent, Typography } from '@soil-capital/ui-kit/material-core';
import { IconArrowLeft, IconArrowRight, IconCross, IconUser } from '@soil-capital/ui-kit/icons';
import { useLocation, useNavigate } from 'react-router-dom';

export const CroppingFilter = ({
    onFieldIdChange,
    selectedFieldId,
    onSearchDone,
}: {
    onFieldIdChange: (fieldId: number | null) => void;
    selectedFieldId: number | null;
    onSearchDone: (fieldIds: number[]) => void;
}) => {
    const { classes } = makeStyles()(() => ({
        select: {
            paddingLeft: '4px',
            width: '280px',
            height: '59px',
            transform: 'translateY(2px)',
            '.MuiSelect-select': {
                padding: '4px',
            },
            '.MuiList-root': {
                background: 'red !important',
            },
        },
        menuPaper: {
            maxHeight: '600px !important',
        },
        item: { padding: 0, width: '100%', marginLeft: '-2px' },
        buttonCross: {
            svg: {
                fontSize: '16px !important',
            },
            ':hover': {
                backgroundColor: '#e0e0e0',
            },
        },
        buttonHover: {
            ':hover': {
                backgroundColor: '#e0e0e0',
            },
        },
        outlinedButton: {
            display: 'inline-block',
            marginLeft: '6px',
            padding: '4px 8px',
            border: '1px solid #cacaca',
            ':hover': {
                backgroundColor: '#cacaca',
            },
        },
    }))();

    const [maxSearch, setMaxSearch] = useState<number>(20);

    const searchFieldsFetcher = createSearchFieldsFetcher();

    const isFetching = searchFieldsFetcher.fetching;

    const [farmerInfo, setFarmerInfo] = useState('');
    const [fieldInfo, setFieldInfo] = useState('');
    const [fieldPickerOpen, setFieldPickerOpen] = useState(false);
    const [farmerPickerOpen, setFarmerPickerOpen] = useState(false);
    const [selectedFarmerEmail, setSelectedFarmerEmail] = useState<string | null>(null);

    const navigate = useNavigate();
    const location = useLocation();
    const [preFilteredFieldIds, setPreFilteredFieldIds] = useState<null | string>(
        new URLSearchParams(location.search).get('field_ids') ?? '',
    );

    const clearUrlParamFieldIds = () => {
        navigate({ search: '' });
        setPreFilteredFieldIds(null);
    };

    const handleSearchFields = useCallback(
        async (overrideLimit?: number) => {
            const params: SearchFieldParams = {
                farmer_info: farmerInfo,
                field_info: fieldInfo,
                limit: maxSearch,
                field_ids: preFilteredFieldIds ?? undefined,
            };
            if (overrideLimit) {
                params.limit = overrideLimit;
            }
            const fieldsData = await searchFieldsFetcher.fetch(params);
            onSearchDone(fieldsData?.map((fieldData) => fieldData.field_id) ?? []);

            // Preset FE filter
            if (fieldsData?.length === 1) {
                setSelectedFarmerEmail(fieldsData?.[0].email ?? null);
                onFieldIdChange(fieldsData[0].field_id);
                return;
            }

            // Make sure FE filters are reset
            onFieldIdChange(null);
            setSelectedFarmerEmail(null);
            if (!fieldsData?.length) {
                return;
            }

            // Preset Farmer Email
            const emailsSet = new Set(fieldsData?.map((fieldData) => fieldData.email));
            if (emailsSet.size === 1) {
                setSelectedFarmerEmail(fieldsData?.[0].email ?? null);
            }

            // Preopen a picker 'search' inputs was filled, meaning we want to target specific user or field.
            if (farmerInfo && emailsSet.size > 1) {
                setFarmerPickerOpen(true);
            } else if (fieldInfo && fieldsData?.length > 1) {
                setFieldPickerOpen(true);
            } else if (fieldsData[0]) {
                // If we do a ~anonym field search (like pre-filtered ids from url), we preselect the first field
                onFieldIdChange(fieldsData[0].field_id);
            }
        },
        [farmerInfo, fieldInfo, onFieldIdChange, onSearchDone, searchFieldsFetcher, maxSearch, preFilteredFieldIds],
    );

    useEffect(() => {
        // little trick, we consider '' is the initial empty state got from the url, then when we empty the field_ids, we set null
        if (preFilteredFieldIds !== '') {
            handleSearchFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preFilteredFieldIds]);

    const increaseSearchLimitAndRefetch = useCallback(() => {
        handleSearchFields(maxSearch + 100);
        setMaxSearch((prev) => prev + 100);
    }, [handleSearchFields, setMaxSearch, maxSearch]);

    const handleSelectFarmer = (value: SelectChangeEvent<unknown>) => {
        onFieldIdChange(null);
        setSelectedFarmerEmail(String(value.target.value));
        const remainingFields = searchFieldsFetcher.data?.filter((fieldData) => fieldData.email === value.target.value);
        if (remainingFields?.length === 1) {
            onFieldIdChange(remainingFields?.[0].field_id ?? null);
        } else {
            setFieldPickerOpen(true);
        }
    };

    const handleClearFilters = () => {
        setSelectedFarmerEmail(null);
        onFieldIdChange(null);
    };

    const prevFieldId = useMemo(() => {
        const fields =
            searchFieldsFetcher.data?.filter((fieldData) =>
                selectedFarmerEmail ? fieldData.email === selectedFarmerEmail : true,
            ) ?? [];
        const i = fields.findIndex((fieldData) => fieldData.field_id === selectedFieldId);
        if (i === undefined) {
            return null;
        }
        return fields?.[i - 1]?.field_id ?? null;
    }, [searchFieldsFetcher.data, selectedFarmerEmail, selectedFieldId]);
    const nextFieldId = useMemo(() => {
        const fields =
            searchFieldsFetcher.data?.filter((fieldData) =>
                selectedFarmerEmail ? fieldData.email === selectedFarmerEmail : true,
            ) ?? [];
        const i = fields.findIndex((fieldData) => fieldData.field_id === selectedFieldId);

        if (i === undefined) {
            return null;
        }
        return fields[i + 1]?.field_id ?? null;
    }, [searchFieldsFetcher.data, selectedFarmerEmail, selectedFieldId]);

    const memoizedFarmerItems = useMemo(() => {
        return Object.entries(Object.fromEntries(searchFieldsFetcher.data?.map((d) => [d.email, d]) ?? []))?.map(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([_, fieldData]) => (
                <MenuItem key={fieldData.email + fieldData.field_id} value={fieldData.email}>
                    <div style={{ height: '59px' }} className="p-1 flex items-center w-full">
                        <div className="ml-1 mr-2">
                            <IconUser />
                        </div>
                        <div>
                            <Typography className="text-nowrap ellipsis w-full overflow-hidden" component="div">
                                {fieldData.first_name} - {fieldData.last_name}
                            </Typography>
                            <Typography variant="subcaption" className="text-gray-500" component="div">
                                {fieldData.email}
                            </Typography>
                        </div>
                    </div>
                </MenuItem>
            ),
        );
    }, [searchFieldsFetcher.data]);

    const memoizedFieldItems = useMemo(() => {
        return searchFieldsFetcher.data
            ?.filter((fieldData) => (selectedFarmerEmail ? fieldData.email === selectedFarmerEmail : true))
            ?.map((fieldData) => (
                <MenuItem key={fieldData.field_id} value={fieldData.field_id}>
                    <FieldCropInfoBox
                        disabled={false}
                        mapSnapshot={`${process.env.REACT_APP_API_BASE_URL}/v3/farm-seasons/${fieldData.farm_season_id}/fields/${fieldData.field_id}/thumbnail`}
                        fieldName={fieldData.field_name}
                        fieldArea={`${formatNumber(fieldData.field_area, 2)} HA`}
                        cropNames={[]}
                        className={classes.item}
                    />
                </MenuItem>
            ));
    }, [classes.item, searchFieldsFetcher.data, selectedFarmerEmail]);

    return (
        <div>
            <div className="flex items-end gap-2 my-4 border-gray-300 p-2 rounded-lg border w-fit">
                <Input
                    label="Farmer"
                    value={farmerInfo}
                    type="text"
                    disabled={isFetching}
                    onChange={(e) => setFarmerInfo(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSearchFields()}
                />
                <Input label="Season" type="text" value="2023-2024" disabled={true} />
                <Input
                    label="Field"
                    type="text"
                    value={fieldInfo}
                    disabled={isFetching}
                    onChange={(e) => setFieldInfo(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSearchFields()}
                />
                <Button
                    variant="contained"
                    onClick={() => handleSearchFields()}
                    loading={isFetching}
                    className="my-px"
                    style={{ height: '56px' }}
                >
                    Go!
                </Button>
            </div>
            {/* TEXT Warnings */}
            {searchFieldsFetcher.loaded && searchFieldsFetcher.data?.length === 0 && (
                <div className="w-full text-orange-500">No data found</div>
            )}

            {preFilteredFieldIds && (
                <div className="w-full text-blue-700 mb-2 block">
                    `field_ids` url param active ({preFilteredFieldIds?.split(',').length} ids)
                    <Button
                        size="small"
                        onClick={clearUrlParamFieldIds}
                        variant="text"
                        color="inherit"
                        className={classes.outlinedButton}
                    >
                        Clear
                    </Button>
                </div>
            )}

            {searchFieldsFetcher.data?.length === maxSearch && (
                <div className="w-full text-gray-500">
                    Fields search limit reached ({maxSearch})
                    <Button
                        size="small"
                        onClick={increaseSearchLimitAndRefetch}
                        variant="text"
                        color="inherit"
                        className={`${classes.outlinedButton}`}
                    >
                        Increase limit
                    </Button>
                </div>
            )}
            <div className="flex items-end gap-2 my-4 border-gray-300 p-2 w-fit">
                {/* SELECT Farmer */}
                <Select
                    value={selectedFarmerEmail ?? '0'}
                    disabled={!searchFieldsFetcher.data?.length || isFetching}
                    onChange={handleSelectFarmer}
                    open={farmerPickerOpen}
                    onClick={() => setFarmerPickerOpen((v) => !v)}
                    className={classes.select}
                    MenuProps={{ classes: { paper: classes.menuPaper }, transitionDuration: 100 }}
                >
                    {/* placeholder */}
                    <MenuItem value="0" hidden>
                        No Farmers
                    </MenuItem>

                    {memoizedFarmerItems}
                </Select>

                {/* SELECT */}
                <Select
                    value={selectedFieldId ?? '0'}
                    disabled={!searchFieldsFetcher.data?.length || isFetching}
                    onChange={(value) => onFieldIdChange(Number(value.target.value))}
                    open={fieldPickerOpen}
                    onClick={() => setFieldPickerOpen((v) => !v)}
                    className={classes.select}
                    MenuProps={{ classes: { paper: classes.menuPaper }, transitionDuration: 100 }}
                >
                    {/* placeholder */}
                    <MenuItem value="0" hidden>
                        No Fields
                    </MenuItem>

                    {memoizedFieldItems}
                </Select>
                <Button
                    variant="text"
                    color="inherit"
                    disabled={!prevFieldId || !searchFieldsFetcher.data?.length || isFetching}
                    onClick={() => onFieldIdChange(prevFieldId)}
                    className={`my-px ${classes.buttonHover}`}
                    style={{ height: '56px' }}
                >
                    <IconArrowLeft />
                </Button>
                <Button
                    variant="text"
                    color="inherit"
                    disabled={!nextFieldId || !searchFieldsFetcher.data?.length || isFetching}
                    onClick={() => onFieldIdChange(nextFieldId)}
                    className={`my-px ${classes.buttonHover}`}
                    style={{ height: '56px' }}
                >
                    <IconArrowRight />
                </Button>
                <Button
                    variant="text"
                    color="inherit"
                    disabled={isFetching || (!selectedFieldId && !selectedFarmerEmail)}
                    onClick={handleClearFilters}
                    className={`my-px ${classes.buttonCross}`}
                    style={{ height: '56px' }}
                >
                    <IconCross />
                </Button>
            </div>
        </div>
    );
};

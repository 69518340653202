import { TimelineInputGroupCardPropsT } from './OperationTimelineInputGroupCard.types';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Loader, Picker } from '@soil-capital/ui-kit/components';
import { IconClose, IconPlus } from '@soil-capital/ui-kit/icons';
import useOperationTimelineInputGroupCardLogic from './useOperationTimelineInputGroupCard.logic';
import useOperationTimelineList from '../../hooks/useOperationTimelineList';
import OperationDatePicker from '../OperationDatePicker/OperationDatePicker';
import useOperationTimelineInputGroupCardStyle from './useOperationTimelineInputGroupCard.style';
import { useOperationTypeItemsForPicker } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationTypeItemsForPicker';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';
import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { ConfirmDeleteOperationModal } from '../ConfirmDeleteOperationModal/ConfirmDeleteOperationModal';
import { Card, ConditionalTooltip } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { ConfirmSameSeedingDateModal } from '../WarningSameDateForSeedingModal/ConfirmSameSeedingDateModal';

export default ({
    period,
    fieldCropId,
    seasonId,
    title,
    tooltip,
    harvestYear,
    displayErrors,
    operationErrors,
}: TimelineInputGroupCardPropsT) => {
    const { t } = useTranslation();
    const { classes } = useOperationTimelineInputGroupCardStyle();
    const {
        isPickerOpen,
        canDeleteSeedingOperation,
        confirmDeleteOperationModalProps,
        operationIdToDeleteLoading,
        setIsPickerOpen,
        handleCreateOperationFromPicker,
        handleDeleteOperation,
        handleUpdateOperation,
        canSelectDate,
        getOperationTypeName,
        //getDatesToDisable,
        warningSameSeedingDateModalProps,
    } = useOperationTimelineInputGroupCardLogic({
        fieldCropId,
        seasonId,
        period,
    });
    const isOptimisticOperation = (operationId: number) => operationId < 0;
    const anyOperationDeleteIsLoading = !!operationIdToDeleteLoading;
    const operationDeleteIsLoading = (operationId: number) => operationIdToDeleteLoading === operationId;
    const operationList = useOperationTimelineList({ period, fieldCropId, seasonId });
    const operationTypeItemsForPicker = useOperationTypeItemsForPicker({ period });
    const decemberIndex = 11;
    const lastDayOfDecember = 31;

    if (operationList.isLoading || operationTypeItemsForPicker.isLoading) return <></>;

    return (
        <>
            <div>
                <div className={classes.operationGroupTitle}>
                    <Typography variant="h3">{title}</Typography>
                    <ConditionalTooltip title={tooltip?.title} text={tooltip?.text} scheme="dark" display="flex" />
                </div>

                <div className={classes.flexColumn}>
                    {operationList.items
                        .filter((item) => !!item.operations.length)
                        .map(({ config, operations, type }) => (
                            <div key={config.id} className={classes.spacedCard}>
                                <Card spacing={1}>
                                    <div className={classes.flexColumn}>
                                        {operations.map((operation, index) => (
                                            <FormRow key={operation.id}>
                                                <FormQuestion
                                                    question={index === 0 ? getOperationTypeName(type) : ''}
                                                />
                                                <div className={classes.inputRowChild}>
                                                    {canSelectDate(operation) ? (
                                                        <OperationDatePicker
                                                            disabled={isOptimisticOperation(operation.id)}
                                                            max={
                                                                harvestYear
                                                                    ? new Date(
                                                                          harvestYear,
                                                                          decemberIndex,
                                                                          lastDayOfDecember,
                                                                      )
                                                                    : undefined
                                                            }
                                                            value={
                                                                operation.operation_date
                                                                    ? new Date(operation.operation_date)
                                                                    : null
                                                            }
                                                            onChange={(newDate) =>
                                                                handleUpdateOperation({
                                                                    id: operation.id,
                                                                    operationDate: newDate,
                                                                })
                                                            }
                                                            validationError={
                                                                displayErrors &&
                                                                (!operation.operation_date ||
                                                                    operationErrors.includes(operation.id))
                                                            }
                                                            // datesToDisable={getDatesToDisable(operations)}
                                                        />
                                                    ) : (
                                                        <div style={{ width: '250px' }}>
                                                            <Typography variant="subcaption" color={'darkScale.600'}>
                                                                {t(
                                                                    'encoding-technical-itinerary.timeline.no-date-required',
                                                                )}
                                                            </Typography>
                                                        </div>
                                                    )}

                                                    <div className={classes.iconCloseZone}>
                                                        {config.is_deletable ||
                                                        index !== 0 ||
                                                        // Temporary fix to allow the user to delete the seeding operation
                                                        (type.slug === OPERATION_TYPE_SLUGS.SEEDING_SEEDING &&
                                                            canDeleteSeedingOperation) ? (
                                                            operationDeleteIsLoading(operation.id) ? (
                                                                <Loader className={classes.iconCloseZone} size={20} />
                                                            ) : (
                                                                <IconButton
                                                                    onClick={() => handleDeleteOperation(operation.id)}
                                                                    data-testid="delete-button"
                                                                    disabled={
                                                                        isOptimisticOperation(operation.id) ||
                                                                        anyOperationDeleteIsLoading
                                                                    }
                                                                    size="small"
                                                                >
                                                                    <IconClose className={classes.iconClose} />
                                                                </IconButton>
                                                            )
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </FormRow>
                                        ))}
                                        {config.can_have_multiple_dates &&
                                        !!operations[operations.length - 1].operation_date ? (
                                            <>
                                                <FormRow>
                                                    <FormQuestion question={''} />
                                                    <div className={classes.inputRowChild}>
                                                        <div className={classes.addNewDate}>
                                                            <OperationDatePicker
                                                                variant="button"
                                                                max={
                                                                    harvestYear
                                                                        ? new Date(
                                                                              harvestYear,
                                                                              decemberIndex,
                                                                              lastDayOfDecember,
                                                                          )
                                                                        : undefined
                                                                }
                                                                value={null}
                                                                onChange={(newDate) =>
                                                                    handleCreateOperationFromPicker({
                                                                        operationTypeConfigId: config.id,
                                                                        operationTypeId: type.id,
                                                                        operationDate: newDate,
                                                                    })
                                                                }
                                                                //datesToDisable={getDatesToDisable(operations)}
                                                            />
                                                        </div>
                                                        <div className={classes.iconCloseZone}></div>
                                                    </div>
                                                </FormRow>
                                            </>
                                        ) : null}
                                    </div>
                                </Card>
                            </div>
                        ))}
                    <Picker
                        isOpen={isPickerOpen}
                        onClose={() => setIsPickerOpen(false)}
                        items={operationTypeItemsForPicker.items}
                        onClick={handleCreateOperationFromPicker}
                        popOverWidth={370}
                        popOverHeight={348}
                    >
                        <Button
                            startIcon={<IconPlus color="inherit" fontSize="small" />}
                            variant="contained"
                            color="primary"
                            onClick={() => setIsPickerOpen(true)}
                        >
                            {t('encoding-technical-itinerary.timeline.add-operation-button')}
                        </Button>
                    </Picker>
                </div>
            </div>

            {/* Modals */}
            <ConfirmDeleteOperationModal
                {...confirmDeleteOperationModalProps}
                farmSeasonId={seasonId}
                fieldCropId={fieldCropId}
            />
            <ConfirmSameSeedingDateModal {...warningSameSeedingDateModalProps} />
        </>
    );
};

import { useCroppingViewAccessCheck } from './hooks/useCroppingViewAccessCheck';
import { useTailwindCdn } from './hooks/useTailwindCdn';
import { LegendOperations } from './components/LegendOperations/LegendOperations';
import { CroppingViewTable } from './components/CroppingViewTable/CroppingViewTable';
import { CroppingLayout } from './components/CroppingLayout/CroppingLayout';
import CroppingViewData from './CroppingView.data';
import { CroppingFilter } from './components/CroppingFilter/CroppingFilter';
import { useCallback, useEffect, useState } from 'react';
import { LoaderFullScreen } from '@shared/components';
import { FieldView } from './components/FieldView/FieldView';
import { MapProvider } from '@shared/map';

export const CroppingView = () => {
    useTailwindCdn();

    const [fadeIn, setFadeIn] = useState(true);
    useEffect(() => {
        setTimeout(() => setFadeIn(false), 500);
    }, []);

    useCroppingViewAccessCheck();
    const [selectedFieldId, setSelectedFieldId] = useState<number | null>(null);
    const [selectedFragmentId, setSelectedFragmentId] = useState<number | null>(null);

    const { dndcCroppingFetcher, mscCroppingFetcher } = CroppingViewData();
    const isFetching = dndcCroppingFetcher.fetching || mscCroppingFetcher.fetching;

    const mscCroppingData = mscCroppingFetcher.data
        ?.filter((mscData) => mscData.field_id === selectedFieldId)
        ?.sort((mscData1, mscData2) => mscData2.fragment_area - mscData1.fragment_area);

    const selectedFragmentData = selectedFragmentId
        ? mscCroppingData?.find((fieldFragment) => fieldFragment.fragment_id === selectedFragmentId)
        : mscCroppingData?.[0];

    const dndcCroppingData = dndcCroppingFetcher.data?.find(
        (dndcData) =>
            dndcData.field_id === selectedFieldId && dndcData.fragment_id === selectedFragmentData?.fragment_id,
    );

    const handleGetCropping = useCallback(
        (fieldIds: number[]) => {
            dndcCroppingFetcher.fetch({ field_ids: fieldIds });
            mscCroppingFetcher.fetch({ field_ids: fieldIds });
        },
        [dndcCroppingFetcher, mscCroppingFetcher],
    );

    return (
        <LoaderFullScreen isLoading={fadeIn} displayChildrenWhileLoading displayLoader={false}>
            <CroppingLayout>
                <section className="p-8 text-gray-700" style={{ minWidth: '1300px' }}>
                    <h1 className="text-2xl font-bold">Cropping View</h1>
                    <div className="flex">
                        <CroppingFilter
                            onFieldIdChange={(id) => {
                                setSelectedFieldId(() => id);
                                setSelectedFragmentId(() => null);
                            }}
                            onSearchDone={handleGetCropping}
                            selectedFieldId={selectedFieldId}
                        />
                        <MapProvider>
                            <FieldView
                                fieldFragmentData={mscCroppingData ?? []}
                                onSelectFragment={(id) => setSelectedFragmentId(id)}
                                selectedFragmentData={selectedFragmentData}
                                className={isFetching ? 'opacity-50' : ''}
                            />
                        </MapProvider>
                    </div>

                    <div className="w-full mt-10">
                        <CroppingViewTable
                            yearStart={2020}
                            years={5}
                            fieldsCroppingData={selectedFragmentData?.cropping_fields}
                            dndcCroppingData={dndcCroppingData}
                            monthsBeforeStart={3}
                            monthsAfterEnd={3}
                            className={isFetching ? 'opacity-50' : ''}
                        />
                        <LegendOperations />
                    </div>
                </section>
            </CroppingLayout>
        </LoaderFullScreen>
    );
};

import { Button, Tooltip } from '@soil-capital/ui-kit/components';
import { IconPolygon } from '@soil-capital/ui-kit/icons';
import { IconClose } from '@soil-capital/ui-kit/icons';
import { IconCheckboxChecked } from '@soil-capital/ui-kit/icons';
import { DrawnFieldsCreationModal } from '@modules/encoding/modules/rotation/components/DrawnFieldCreationModal/DrawnFieldCreationModal';
import { useDrawFieldLogic } from './useDrawField.logic';
import { FeatureCollection, Geometry, GeometryCollection, Polygon, Properties } from '@turf/helpers';
import { Dispatch, SetStateAction } from 'react';
import { MAPMODE, MapModeT } from '@modules/encoding/modules/rotation/components/PacImportMap/PacImportMap.logic';

export const DrawField = ({
    readOnly,
    farmBoundaries,
    existingPolygons,
    mapMode,
    setMapMode,
    invalidFieldsNumber,
    isDisabled,
}: {
    readOnly: boolean;
    farmBoundaries: FeatureCollection<Geometry | GeometryCollection, Properties> | undefined;
    mapMode: MapModeT;
    setMapMode: Dispatch<SetStateAction<MapModeT>>;
    existingPolygons: Polygon[];
    invalidFieldsNumber: number;
    isDisabled: boolean;
}) => {
    const {
        t,
        startDrawing,
        stopDrawing,
        polygonArea,
        drawnPolygon,
        drawnFieldCreationModalOpen,
        onDrawnFieldCreationModalOpen,
        onDrawnFieldCreationModalClose,
        isSaveDisabled,
    } = useDrawFieldLogic({ farmBoundaries, existingPolygons, mapMode, setMapMode });

    return (
        <>
            {mapMode === MAPMODE.DRAW ? (
                <>
                    <Button
                        startIcon={<IconClose sx={{ width: '20px', height: '20px' }} />}
                        onClick={() => stopDrawing(true)}
                    >
                        {t('constants.cancel')}
                    </Button>
                    <Button
                        startIcon={<IconCheckboxChecked sx={{ width: '20px', height: '20px' }} />}
                        disabled={!drawnPolygon || isSaveDisabled}
                        variant="contained"
                        onClick={onDrawnFieldCreationModalOpen}
                    >
                        {t('constants.save')}
                    </Button>
                </>
            ) : mapMode === MAPMODE.NONE ? (
                <Tooltip text={invalidFieldsNumber ? t('encoding-rotation.introduction.invalid-fields-tooltip') : ''}>
                    <Button
                        startIcon={<IconPolygon color="primary" />}
                        sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                        onClick={startDrawing}
                        disabled={readOnly || !!invalidFieldsNumber || isDisabled}
                    >
                        {t('encoding-rotation.map.draw-field')}
                    </Button>
                </Tooltip>
            ) : null}
            {drawnPolygon && polygonArea && (
                <DrawnFieldsCreationModal
                    polygon={drawnPolygon.geometry}
                    area={polygonArea}
                    open={drawnFieldCreationModalOpen}
                    onClose={() => {
                        onDrawnFieldCreationModalClose();
                        stopDrawing(true);
                    }}
                />
            )}
        </>
    );
};

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Polygon, Properties } from '@turf/helpers';
import { intersectPolygon } from '@modules/encoding/modules/rotation/helpers/polygonHelper';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import field from '@shared/entities/field';
import { useMap } from '../../../../../../shared/map/useMap';
import { MapLayerCallbackFeatureDataT } from '../../../../../../shared/map/MapLayers';
import { useToggleWithPayload } from '@shared/hooks/useToggleWithPayload';
import { geoJsonFeatureT } from '@shared/map/types/mapTypes';
import { openDataLayers } from '@shared/map/layers/openDataLayers';

export const MAP_OPENDATA_CONFIG_SLUGS = {
    SOURCE_GLOBAL_2020: 'open-data-parcels',
    SOURCE_LAYER: 'parcels',
    LAYER_FILL: 'opendata-fill',
    LAYER_LINES: 'opendata-lines',
} as const;

const MAP_OPENDATATA_PARCELS = {
    BE: ['open-data-parcels-flanders-2022', 'open-data-parcels-wallonia-2022'],
    FR: ['open-data-parcels-france-2022'],
    IE: ['open-data-parcels-ireland-2023'],
};

const initOpendataSources = (isoCode?: string) => {
    return MAP_OPENDATATA_PARCELS[isoCode as keyof typeof MAP_OPENDATATA_PARCELS] || [];
};

export const useMapOpendataControlLogic = () => {
    const {
        open: fieldCreationModalOpen,
        payload: fieldCreationModalPayload,
        handleOpen: onFieldCreationModalOpen,
        handleClose: onFieldCreationModalClose,
    } = useToggleWithPayload<geoJsonFeatureT>();

    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = field.useState({ farmSeasonId: currentSeasonId });
    const { currentFarm } = useCurrentFarm();
    const [fieldIdHovered, setFieldIdHovered] = useState<number | null>(null);
    const [vectorSourceId, setVectorSourceId] = useState<string | undefined>(undefined);
    const map = useMap();

    const polygonsRef = useRef<Polygon[]>([]);
    useEffect(() => {
        const fieldsPolygon = fieldState.list.map((f) => f.polygon.geometry);
        polygonsRef.current = fieldsPolygon;
    }, [fieldState.list]);

    const sources = useMemo(() => {
        return initOpendataSources(currentFarm?.country?.iso_code);
    }, [currentFarm]);

    const vectorSource = useMemo(() => {
        return sources.map((source) => ({
            id: source,
            type: 'vector',
            url: `${process.env.REACT_APP_MAPBOX_OPENDATA_BASE_URL}.${source}`,
        }));
    }, [sources]);

    const layers = useMemo(() => {
        return sources.flatMap((source) => openDataLayers(source));
    }, [sources]);

    const [openDataGeoJSON, setOpenDataGeoJSON] = useState<GeoJSON.FeatureCollection>({
        type: 'FeatureCollection',
        features: [],
    });

    const openDataLayerIds = useMemo(() => {
        return layers.map((layer) => layer.id);
    }, [layers]);

    const onFeatureHover = useCallback(
        (data: MapLayerCallbackFeatureDataT<Properties> | null, layerId: string, e: mapboxgl.MapLayerMouseEvent) => {
            setFieldIdHovered(null);

            if (data) {
                const featureGeometry = e.features?.[0].geometry as Polygon | undefined;
                if (featureGeometry) {
                    const isIntersecting = polygonsRef.current.some((polygon) =>
                        intersectPolygon(featureGeometry, [polygon]),
                    );
                    if (isIntersecting) {
                        return false;
                    }
                    const sourceId = layerId.replace(/^opendata-fill-/, '');
                    setVectorSourceId(sourceId);
                    setFieldIdHovered(data.featureId);
                }
            }
        },
        [],
    );

    const onFeatureClick = useCallback(
        (data: MapLayerCallbackFeatureDataT<Properties> | null, layerId: string, e: mapboxgl.MapLayerMouseEvent) => {
            const feature = e.features?.[0] as geoJsonFeatureT | undefined;
            const featureGeometry = e.features?.[0].geometry as Polygon | undefined;
            if (featureGeometry) {
                const isIntersecting = polygonsRef.current.some((polygon) =>
                    intersectPolygon(featureGeometry, [polygon]),
                );
                if (isIntersecting) {
                    return false;
                }
            }
            const isIntersecting = intersectPolygon(featureGeometry as Polygon, polygonsRef.current);
            if (!isIntersecting && feature) {
                onFieldCreationModalOpen(feature);
            }
        },
        [onFieldCreationModalOpen],
    );

    // Update the GeoJSON state by querying the rendered features from our vector layers.
    useEffect(() => {
        if (!map) return;
        const updateOpenDataGeoJSON = () => {
            const existingLayerIds = openDataLayerIds.filter((layerId) => map.getLayer(layerId));

            if (existingLayerIds.length === 0) return;
            // Query features from all open-data layers
            const features = map.queryRenderedFeatures(undefined, { layers: openDataLayerIds });

            // Convert to a plain GeoJSON FeatureCollection.
            setOpenDataGeoJSON({
                type: 'FeatureCollection',
                features: features.map((f) => ({
                    id: f.id,
                    type: 'Feature',
                    properties: f.properties,
                    geometry: f.geometry,
                })),
            });
        };

        // Listen to the 'idle' event to update after the map has rendered features.
        map.on('idle', updateOpenDataGeoJSON);
        // Also run it immediately in case the map is already loaded.
        if (map.isStyleLoaded()) {
            updateOpenDataGeoJSON();
        }

        return () => {
            map.off('idle', updateOpenDataGeoJSON);
        };
    }, [map, openDataLayerIds]);

    return {
        openDataGeoJSON,
        layers,
        onFeatureClick,
        onFeatureHover,
        fieldCreationModalOpen,
        onFieldCreationModalClose,
        vectorSource,
        fieldCreationModalPayload,
        fieldIdHovered,
        sourceLayerFill: MAP_OPENDATA_CONFIG_SLUGS.SOURCE_LAYER,
        vectorSourceId,
    };
};

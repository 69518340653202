export const mscOperationTypes = {
    'Organic fertilisation': {
        name: 'Organic fertilisation',
        type: 'Fertilisation',
        color: '#039BE5',
    },
    'Mineral fertilisation': {
        name: 'Mineral fertilisation',
        type: 'Fertilisation',
        color: '#0D47A1',
    },
    'Cover crop seeding': {
        name: 'Cover crop seeding',
        type: 'Cover crop management',
        color: '#66BB6A',
    },
    'Cover crop destruction': {
        name: 'Cover crop destruction',
        type: 'Cover crop management',
        color: '#388E3C',
    },
    'Weeding machine': {
        name: 'Weeding machine',
        type: 'Soil work',
        color: '#8D6E63',
    },
    'Deep work machine (> 5 cm)': {
        name: 'Deep work machine (> 5 cm)',
        type: 'Soil work',
        color: '#4E342E',
    },
    'Shallow work machine (< 5 cm)': {
        name: 'Shallow work machine (< 5 cm)',
        type: 'Soil work',
        color: '#6D4C41',
    },
    Roller: {
        name: 'Roller',
        type: 'Soil work',
        color: '#A1887F',
    },
    Plough: {
        name: 'Plough',
        type: 'Soil work',
        color: '#3E2723',
    },
    Harvest: {
        name: 'Harvest',
        type: 'Harvest',
        color: '#FF5252',
    },
    Destruction: {
        name: 'Destruction',
        type: 'Harvest',
        color: '#E53935',
    },
    Seeding: {
        name: 'Seeding',
        type: 'Seeding',
        color: '#7E57C2',
    },
    'Spraying All': {
        name: 'Spraying All',
        type: 'Spraying',
        color: '#FF8F00',
    },
    'Companion seeding': {
        name: 'Companion seeding',
        type: 'Seeding',
        color: '#4A148C',
    },
    'Maintenance shredding': {
        name: 'Maintenance shredding',
        type: 'Harvest',
        color: '#B71C1C',
    },
};

import { useFormContext } from 'react-hook-form';
import { useDuplicationCropList } from '@modules/yoy/components/YoyDuplicationCropList/useDuplicationCropList';
import { useDuplicationSeasonSelection } from '@modules/yoy/hooks/useDuplicationSeasonSelection';

export const useDuplicationCropSelection = () => {
    const context = useFormContext();
    const { farmSeasonCrops } = useDuplicationCropList();
    const { watch, setValue } = context || {};
    const { selectedCropsDontExistOnSelectedYear } = useDuplicationSeasonSelection();

    const selectedCrops = watch('crop_ids') ?? [];

    if (!context) {
        throw new Error('useDuplicationCropSelection must be used within a FormProvider');
    }

    const handleSelectAllCrops = () => {
        if (selectedCrops?.length === farmSeasonCrops?.length) {
            return setValue('crop_ids', []);
        }

        return setValue(
            'crop_ids',
            farmSeasonCrops?.map((fsc) => fsc.cropId),
        );
    };

    const handleSelectCrop = (cropId?: number) => {
        if (selectedCrops?.includes(cropId)) {
            return setValue(
                'crop_ids',
                selectedCrops?.filter((crop: number) => crop !== cropId),
            );
        }

        return setValue('crop_ids', [...(selectedCrops || []), cropId]);
    };

    const allowDuplication = selectedCrops && selectedCrops?.length > 0;
    const allCropsAreSelected = selectedCrops?.length === farmSeasonCrops?.length;
    const selectedCropsHaveDuplication = farmSeasonCrops?.some(
        (fsc) => selectedCrops?.includes(fsc.cropId) && fsc.hasOneDuplicatedFieldCropITKUntouched,
    );

    return {
        allowDuplication,
        selectedCrops,
        allCropsAreSelected,
        selectedCropsHaveDuplication,
        selectedCropsDontExistOnSelectedYear,
        handleSelectAllCrops,
        handleSelectCrop,
    };
};

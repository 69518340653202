import { useParams } from 'react-router-dom';
import technicalItineraryRoutes, { TechnicalItineraryRouteParamsT } from '../../technicalItinerary.routes';
import entities from '@shared/entities';
import { useMemo, useState } from 'react';
import { useGetStaticCrops } from '@shared/hooks/useGetStaticCrops';
import { useTranslation } from 'react-i18next';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';

export default () => {
    const { t } = useTranslation();

    const { seasonId, fieldCropId, operationId } = useParams<TechnicalItineraryRouteParamsT['operationForm']>();
    const [fieldMapSnapshotIsLoading, setFieldMapSnapshotIsLoading] = useState<boolean>(true);
    const [timelineNavIsLoading, setTimelineNavIsLoading] = useState<boolean>(false);
    const [fieldIdentityCardIsLoading, setFieldIdentityCardIsLoading] = useState<boolean>(false);

    const { cropState } = entities.crop.useState({ farmSeasonId: Number(seasonId) });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: Number(seasonId) });
    const { fieldState } = entities.field.useState({ farmSeasonId: Number(seasonId) });
    const { staticCropState } = useGetStaticCrops();

    const fieldCrop = fieldCropState.getById(fieldCropId);
    const fieldId = Number(fieldCrop?.farm_season_field_id);
    const staticCropId = cropState.list.find((crop) => crop.id === fieldCrop?.farm_season_crop_id)?.crop_id;
    const cropName = staticCropState.list.find((crop) => crop.id === staticCropId)?.translation_slug;

    const breadcrumbItems = useMemo(() => {
        const dropdownItems = fieldState.list
            .map((field) => {
                const correspondingFieldCrop = fieldCropState.list.find(
                    (fc) =>
                        fc.farm_season_field_id === field.id &&
                        fc.farm_season_crop_id === fieldCrop?.farm_season_crop_id,
                );
                if (!correspondingFieldCrop) return null;
                return {
                    id: field.id,
                    name: field.name,
                    href: `/${seasonId}/${
                        encodingRoutesConfig.TECHNICAL_ITINERARY
                    }/${technicalItineraryRoutes.globalCharacteristics({ fieldCropId: correspondingFieldCrop.id })}`,
                    selected: field.id === fieldId,
                };
            })
            .filter(Boolean);

        return [
            {
                label: t('constants.crops'),
                href: `/${seasonId}/${encodingRoutesConfig.TECHNICAL_ITINERARY}`,
            },
            { label: t(`${cropName}`) },
            {
                dropdown: { name: t('constants.fields'), items: dropdownItems },
            },
        ];
    }, [fieldState.list, fieldCropState.list, fieldCrop?.farm_season_crop_id, seasonId, fieldId, cropName, t]);

    const isLoading = fieldMapSnapshotIsLoading || timelineNavIsLoading || fieldIdentityCardIsLoading;

    return {
        seasonId: Number(seasonId),
        fieldCropId: Number(fieldCropId),
        operationId: Number(operationId),
        fieldId,
        isLoading,
        setFieldMapSnapshotIsLoading,
        setTimelineNavIsLoading,
        setFieldIdentityCardIsLoading,
        breadcrumbItems,
    };
};

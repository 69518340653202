import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useFieldCropWarningModalStyles } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/FieldCropWarningModal/FieldCropWarningModal.style';
import { WarningBox } from '@shared/components/WarningBox/WarningBox';

type FieldCropWarningModalForSelectAllProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    sourceFieldName: string;
    nbrOfSelectedFields: number;
    selectedOperationTypes: number[];
};

export const FieldCropWarningModalForSelectAll = ({
    open,
    onClose,
    onConfirm,
    sourceFieldName,
    nbrOfSelectedFields,
    selectedOperationTypes,
}: FieldCropWarningModalForSelectAllProps) => {
    const { t } = useTranslation();
    const { classes } = useFieldCropWarningModalStyles();

    return (
        <Modal
            size={'medium'}
            open={open}
            onClose={onClose}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>
                        {t('encoding-technical-itinerary.duplication.modal.warning-modal.select-all.title')}
                    </Modal.HeaderTitle>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body className={classes.body}>
                    <WarningBox displayWarningIcon>
                        <Trans
                            i18nKey="encoding-technical-itinerary.duplication.modal.warning-modal.select-all.description"
                            values={{
                                nbrOfOperations: selectedOperationTypes.length,
                                nbrOfSelectedFields,
                                sourceFieldName,
                            }}
                        />
                    </WarningBox>
                    <Typography variant="body2">
                        <Trans i18nKey="encoding-technical-itinerary.duplication.modal.warning-modal.select-all.summary" />
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Button variant="text" onClick={onClose}>
                        {t('constants.cancel')}
                    </Button>
                    <Button onClick={onConfirm} variant="contained">
                        {t('constants.confirm-selection')}
                    </Button>
                </Modal.Footer>
            }
        />
    );
};

import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '../../hooks/useCurrentSeasonId';
import { useCallback, useMemo, useState } from 'react';
import entities, { StaticCropT } from '@shared/entities';
import { FieldPolygonT } from '@shared/entities/field/field.types';
import { MapLayerCallbackFeatureDataT, useMap } from '@shared/map';
import { getTextLayoutLabel } from '@modules/encoding/shared/utils/layerHelper';
import setupCropPalette from '@modules/encoding/shared/utils/setupCropPalette';
import useCurrentFarm from '../../hooks/useCurrentFarm';
import { FieldWithCropT } from '@shared/map/types/mapTypes';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { fieldSelectionAssignationSlice, fieldSelectionDuplicationSlice } from '../../store/fieldSelectionSlice';

export const useFieldSelectionMapLogic = ({
    hiddenFieldIds,
    onSelect,
    onDeselect,
    isDuplication,
    excludedFieldIds,
}: {
    hiddenFieldIds?: number[];
    onSelect: (fieldId: number) => void;
    onDeselect: (fieldId: number) => void;
    isDuplication?: boolean;
    excludedFieldIds?: number[];
}) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const [hoveredFeatureFieldOverview, setHoveredFeatureFieldOverview] = useState<FieldPolygonT | undefined>();
    const { getCropsAssignedToAField } = cropState.useGetCropsAssignedToAField();
    const { currentFarm } = useCurrentFarm();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const dispatch = useDispatch();
    const map = useMap();

    const treePattern = new Image(72, 72);
    treePattern.src = '/assets/patterns/tree.svg';

    treePattern.onload = () => {
        if (map && !map.hasImage('trees')) map.addImage('trees', treePattern, { pixelRatio: 2 });
    };

    const selectedFieldIds = useSelector((state: SharedStateT) =>
        isDuplication ? state.fieldSelectionDuplication.selectedIds : state.fieldSelectionAssignation.selectedIds,
    );
    const disabledFieldIdsSelectorSet = new Set(
        useSelector((state: SharedStateT) =>
            isDuplication ? state.fieldSelectionDuplication.disabledIds : state.fieldSelectionAssignation.disabledIds,
        ),
    );
    const excludedFieldIdsSet = new Set(excludedFieldIds);
    const disabledFieldIds = Array.from(new Set([...disabledFieldIdsSelectorSet, ...excludedFieldIdsSet]));

    const onFeatureHover = useCallback(
        (data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => {
            const targetFieldOverviewFeature = fieldState.getById(data?.featureId);
            const fieldOverviewFeature = targetFieldOverviewFeature?.polygon;
            setHoveredFeatureFieldOverview(fieldOverviewFeature);

            // the disabled fields are not hoverable
            const isFieldDisabled = data?.featureId && disabledFieldIds.includes(data.featureId);
            if (isFieldDisabled) {
                return false;
            }

            // don't trigger hover effect on text layer.
            // It causes the fieldOverview Popup to flicer when hovering the text layer
            if (layerId === 'feature-text') return;
            if (isDuplication) {
                dispatch(fieldSelectionDuplicationSlice.actions.setHoveredFieldId(data ? data.featureId : null));
            } else {
                dispatch(fieldSelectionAssignationSlice.actions.setHoveredFieldId(data ? data.featureId : null));
            }
        },
        [fieldState, disabledFieldIds, isDuplication, dispatch],
    );

    const onFeatureClick = useCallback(
        (data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => {
            if (!data) {
                return;
            }

            // the disabled fields are not clickable
            const isFieldDisabled = disabledFieldIds.includes(data.featureId);
            if (isFieldDisabled) {
                return;
            }

            // make sure we don't trigger the toggle twice (exemple on clicking on symbol layer on top of the fill layer)
            if (layerId !== 'feature-fill') {
                return;
            }

            const isFieldSelected = selectedFieldIds.includes(data.featureId);
            if (isFieldSelected) {
                onDeselect(data.featureId);
            } else {
                // reset the hover to make sure the "disabled" state is checked again even if we don't leave the field.
                if (isDuplication) {
                    dispatch(fieldSelectionDuplicationSlice.actions.setHoveredFieldId(null));
                } else {
                    dispatch(fieldSelectionAssignationSlice.actions.setHoveredFieldId(null));
                }
                onSelect(data.featureId);
            }
        },
        [disabledFieldIds, selectedFieldIds, onDeselect, isDuplication, onSelect, dispatch],
    );

    const readOnly = fieldState.list.length === disabledFieldIds.length;
    const defaultMapBannerHelpText = readOnly ? undefined : t('selection-on-map-available');

    const polygons = useMemo(
        () => fieldState.list.map((field) => field.polygon).filter((field) => !hiddenFieldIds?.includes(field.id)),
        [fieldState.list, hiddenFieldIds],
    );

    const fieldsWithCrop: FieldWithCropT[] = useMemo(() => {
        return fieldState.list.map((field) => {
            const allFsc = getCropsAssignedToAField(field.id);
            const allCrops = allFsc
                .map((fsc) => staticCropState.getById(fsc.crop_id))
                .filter((staticCrop): staticCrop is StaticCropT => !!staticCrop);
            return { ...field, crops: allCrops };
        });
    }, [fieldState.list, getCropsAssignedToAField, staticCropState]);

    // the source build for the map layer should be reusable for other field selections
    const mapLayerSource = useMemo(() => {
        const staticCropColorsMap = setupCropPalette(fieldsWithCrop);
        return {
            type: 'FeatureCollection',
            features: fieldsWithCrop.map((field) => ({
                ...field.polygon,
                properties: {
                    color: disabledFieldIds.includes(field.id)
                        ? '#808080'
                        : staticCropColorsMap[field.crops?.[0]?.id] ?? '#fff',
                    label: getTextLayoutLabel(
                        field,
                        field.crops?.map((crop) => t(`${crop.translation_slug}`)).join(' • '),
                    ),
                    'pattern-image': field.has_agroforestry ? 'trees' : null,
                },
            })),
        } as const;
    }, [fieldsWithCrop, t, disabledFieldIds]);

    return {
        onFeatureClick,
        defaultMapBannerHelpText,
        onFeatureHover,
        hoveredFeatureFieldOverview,
        polygons,
        mapLayerSource,
        selectedFieldIds,
    };
};

import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { usePacImportActionPageStyles } from '@modules/encoding/modules/rotation/pages/PacImportActionPage/PacImportActionPage.style';
import { usePacImportActionPageLogic } from '@modules/encoding/modules/rotation/pages/PacImportActionPage/usePacImportActionPageLogic';
import ActionPage from '@modules/encoding/shared/components/ActionPage/ActionPage';
import { ValidateRotationConfirmModal } from '../../components/ValidateRotationConfirmModal/ValidateRotationConfirmModal';
import { AreaDecreaseModal } from '@modules/encoding/modules/rotation/components/TotalArea/AreaDecreaseModal/AreaDecreaseModal';
import { AreaIncreaseModal } from '@modules/encoding/modules/rotation/components/TotalArea/AreaIncreaseModal/AreaIncreaseModal';
import { PacImportMap } from '../../components/PacImportMap/PacImportMap';

export const PacImportActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const { classes } = usePacImportActionPageStyles();
    const {
        canContinue,
        continueDisableMessage,
        isLoading,
        onConfirm,
        onCancelPacImport,
        fields,
        invalidFields,
        openValidateRotationModal,
        onValidateRotationWarningModalClose,
        onValidateRotationWarningModalConfirm,
        farmCoordinates,
        readOnly,
        isDecreaseModalOpen,
        isIncreaseModalOpen,
        handleIncreaseModalClose,
        handleDecreaseModalClose,
        isLoadingCheck,
        isYoyFarmSeason,
        isBaseline,
        harvestYear,
        isCancelingPacImport,
    } = usePacImportActionPageLogic({ navigate });

    return (
        <>
            <ActionPage
                isLoading={isLoading}
                classes={{ body: classes.body }}
                rightButtonProps={{
                    loading: isLoadingCheck,
                    disabled: !canContinue,
                    hoverText: continueDisableMessage,
                    onClick: onConfirm,
                    children: t('encoding-rotation.pac-file.action-page.confirm-button'),
                }}
                leftButtonProps={
                    invalidFields.length
                        ? {
                              loading: isCancelingPacImport,
                              onClick: onCancelPacImport,
                              children: t('encoding-rotation.pac-file.action-page.cancel-pac-import', { harvestYear }),
                          }
                        : undefined
                }
            >
                {!isLoading && (
                    <PacImportMap
                        isBaseline={isBaseline}
                        isYoyFarmSeason={isYoyFarmSeason}
                        farmCoordinates={farmCoordinates}
                        readOnly={readOnly}
                        fields={fields}
                        invalidFields={invalidFields}
                    />
                )}
            </ActionPage>
            <ValidateRotationConfirmModal
                open={openValidateRotationModal}
                onClose={onValidateRotationWarningModalClose}
                onConfirm={onValidateRotationWarningModalConfirm}
            />
            {!invalidFields.length && (
                <>
                    <AreaDecreaseModal isOpen={isDecreaseModalOpen} onClose={handleDecreaseModalClose} />
                    <AreaIncreaseModal isOpen={isIncreaseModalOpen} onClose={handleIncreaseModalClose} />
                </>
            )}
        </>
    );
};

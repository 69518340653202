import { Checkbox, Modal, SelectableList } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useFinaliseDuplicationModalStyles } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/FinaliseDuplicationModal/FinaliseDuplicationModal.style';
import { Trans, useTranslation } from 'react-i18next';
import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { FINALISE_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/FinaliseDuplicationModal/useFinaliseForm';
import { FormProvider } from 'react-hook-form';
import { ControlledSelectableList } from '@shared/components/form/ControlledSelectableList/ControlledSelectableCheckboxList';
import { cropNamesT } from '@shared/map/types/mapTypes';
import { useFinaliseDuplicationModal } from './useFinaliseDuplicationModal';

export type FinaliseDuplicationModalProps = {
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
    harvestYear: number;
    fieldCrops: {
        id: number;
        fieldName: string;
        fieldArea: string;
        crops?: cropNamesT[];
        mapSnapshot: string | null;
    }[];
};

export const FinaliseDuplicationModal = ({
    open,
    onConfirm,
    onClose,
    harvestYear,
    fieldCrops,
}: FinaliseDuplicationModalProps) => {
    const { t } = useTranslation();
    const { classes } = useFinaliseDuplicationModalStyles();
    const { isLoading, selectAll, setIsLoading, methods, onSubmit, handleSelectAllFieldsClick } =
        useFinaliseDuplicationModal({
            fieldCrops,
            onConfirm,
        });

    return open ? (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <Modal
                    size="medium"
                    open={open}
                    onClose={onClose}
                    onConfirm={async () => {
                        setIsLoading(true);
                        await onSubmit();
                        setIsLoading(false);
                    }}
                    header={
                        <Modal.Header>
                            <Modal.HeaderTitle>
                                {t('encoding-technical-itinerary.duplication.modal.finalise-modal.title')}
                            </Modal.HeaderTitle>
                        </Modal.Header>
                    }
                    body={
                        <Modal.Body className={classes.body}>
                            <Typography variant="body2">
                                <Trans
                                    i18nKey="encoding-technical-itinerary.duplication.modal.finalise-modal.description"
                                    values={{ harvestYear }}
                                />
                            </Typography>
                            <div className={classes.selectAll}>
                                <Typography color="neutral" variant="body2">
                                    {selectAll ? t('constants.unselect-all') : t('constants.select-all')}
                                </Typography>
                                <Checkbox checked={selectAll} onClick={handleSelectAllFieldsClick} />
                            </div>
                            <ControlledSelectableList
                                className={classes.fieldCropList}
                                name={FINALISE_FORM_INPUT_NAMES.FINALISED_FIELD_CROP_IDS}
                                options={fieldCrops}
                                getOptionKey={(option) => option.id}
                                getOptionValue={(option) => option.id}
                                renderOption={({ id, crops, ...option }, props) => (
                                    <li key={id} className={classes.fieldCropListItem}>
                                        <FieldCropInfoBox
                                            key={option.fieldName}
                                            snapshotSize="medium"
                                            cropNames={crops}
                                            className={classes.fieldCropInfo}
                                            {...option}
                                        />
                                        <SelectableList.CheckboxRadio {...props}>
                                            {t('constants.finalise')}
                                        </SelectableList.CheckboxRadio>
                                    </li>
                                )}
                            />
                        </Modal.Body>
                    }
                    footer={
                        <Modal.Footer>
                            <Modal.Cancel disabled={isLoading}>{t('constants.back')}</Modal.Cancel>
                            <Modal.Confirm loading={isLoading}>{t('constants.end')}</Modal.Confirm>
                        </Modal.Footer>
                    }
                />
            </form>
        </FormProvider>
    ) : (
        <></>
    );
};

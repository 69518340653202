import { ReactComponent } from 'tss-react/tools/ReactComponent';

type CroppingLayoutPropsT = {
    children: React.ReactNode;
};
export const CroppingLayout: ReactComponent<CroppingLayoutPropsT> = (props) => {
    return (
        <section className="p-4 min-h-screen flex flex-col mb-px">
            <div className="flex-1 rounded-xl bg-gray-100 w-full min-w-fit mt-2">{props.children}</div>
        </section>
    );
};

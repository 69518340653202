import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    hoveredId: null as number | null,
    focusedIds: null as number[] | null,
    selectedIds: [] as number[],
    loadingIds: [] as number[],
    disabledIds: [] as number[],
    highlightedIds: null as number[] | null,
};

export const createFieldDefinitionSlice = <T extends 'fieldDefinition'>(name: T) => {
    return createSlice({
        name: name,
        initialState,
        reducers: {
            /** Useful to highlight the field hovered */
            setHoveredFieldId(state, action: PayloadAction<number | null>) {
                state.hoveredId = action.payload;
            },
            /** The ids provided will be focused on the map (to be used like `setFocusedFieldIds([fieldIdClickedFromThumbnail])`. The initial fields to focus on map can be passed to the `<FieldSelectionMap/>` */
            setFocusedFieldIds(state, action: PayloadAction<number[] | null>) {
                state.focusedIds = action.payload;
            },
            /* Display a loader on the field list */
            setLoadingFieldIds(state, action: PayloadAction<number[]>) {
                state.loadingIds = action.payload;
            },
            /** Fields not selectable on the map as well of the field list */
            setDisabledFieldIds(state, action: PayloadAction<number[]>) {
                state.disabledIds = action.payload;
            },
            setSelectedFieldIds(state, action: PayloadAction<number[]>) {
                state.selectedIds = action.payload;
            },
            setHighlightedFieldIds(state, action: PayloadAction<number[]>) {
                state.highlightedIds = action.payload;
            },
            removeHighlightedFieldIds(state, action: PayloadAction<number>) {
                state.highlightedIds = state.highlightedIds?.filter((id) => id !== action.payload) ?? null;
            },
            addLoadingFieldId(state, action: PayloadAction<number>) {
                if (!state.loadingIds.includes(action.payload)) {
                    state.loadingIds.push(action.payload);
                }
            },
            removeLoadingFieldId(state, action: PayloadAction<number>) {
                state.loadingIds = state.loadingIds.filter((id) => id !== action.payload);
            },
            addDisabledFieldId(state, action: PayloadAction<number>) {
                if (!state.disabledIds.includes(action.payload)) {
                    state.disabledIds.push(action.payload);
                }
            },
            removeDisabledFieldId(state, action: PayloadAction<number>) {
                state.disabledIds = state.disabledIds.filter((id) => id !== action.payload);
            },
            /** To make sure another field selection list don't herit irrelevant data \
             * => Done once in FieldSelectionList as it's the "central" component of this feature */
            reset(state) {
                state.disabledIds = initialState.disabledIds;
                state.focusedIds = initialState.focusedIds;
                state.hoveredId = initialState.hoveredId;
                state.loadingIds = initialState.loadingIds;
                state.selectedIds = initialState.selectedIds;
                state.highlightedIds = initialState.highlightedIds;
            },
        },
    });
};

export const fieldDefinitionSlice = createFieldDefinitionSlice('fieldDefinition');

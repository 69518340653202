import { Checkbox, HelpBox, Modal } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@soil-capital/ui-kit/material-core';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { useState } from 'react';

export const YoyDuplicationNoCropsForSelectedYearModal = ({
    open,
    onClose,
    harvestYear,
}: {
    open: boolean;
    onClose: () => void;
    harvestYear: number;
}) => {
    const [checked, setChecked] = useState(false);
    const { t } = useTranslation();
    const isOpen = !localStorage.getItem('duplicationNoCropsOnSelectedYearRemember') && open;

    const handleRemember = () => setChecked((checked) => !checked);

    const handleClose = () => {
        if (checked) localStorage.setItem('duplicationNoCropsOnSelectedYearRemember', 'true');
        setChecked(false);
        onClose();
    };

    const handleConfirm = () => {
        if (checked) localStorage.setItem('duplicationNoCropsOnSelectedYearRemember', 'true');
        handleClose();
    };

    return (
        <Modal
            size="medium"
            open={isOpen}
            onClose={handleClose}
            onConfirm={handleConfirm}
            header={
                <Modal.Header>
                    <Typography variant="h3">
                        {t('yoy.duplication.modal.crop-not-present.title', { harvestYear })}
                    </Typography>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <HelpBox>{t('yoy.duplication.modal.crop-not-present.body', { harvestYear })}</HelpBox>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                        <Checkbox onClick={handleRemember} />
                        <Typography>{t('yoy.duplication.modal.crop-not-present.remember')}</Typography>
                    </Box>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Confirm>
                        <IconLightning />
                        <Typography>{t('yoy.duplication.modal.crop-not-present.confirm')}</Typography>
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};

import { Button, ConditionalTooltip, Tooltip, Card } from '@soil-capital/ui-kit/components';
import { Grid, Skeleton, Typography } from '@soil-capital/ui-kit/material-core';
import usePaymentStepTimelineStyle from './PaymentStepTimeline.style';
import { Trans, useTranslation } from 'react-i18next';
import { IconArrowRight, IconStar, IconEyeOutlined, IconWarning } from '@soil-capital/ui-kit/icons';
import { formatDateWithTranslatedMonth } from '@shared/utils/formatDateWithTranslatedMonth';
import { usePaymentStepTimelineLogic } from './usePaymentStepTimelineLogic';
import { PaymentStepTimelinePropsT } from './PaymentStepTimeline.types';

export const PaymentStepTimeline = ({ payment, status, farmId }: PaymentStepTimelinePropsT) => {
    const { classes } = usePaymentStepTimelineStyle();
    const { t } = useTranslation();
    const {
        currency,
        title,
        displayedPrice,
        priceTooltip,
        displayedPayment,
        paymentTooltip,
        displayCTA,
        CTAlabel,
        canRenew,
        displayDescription,
        displayDescriptionIcon,
        descriptionTransKey,
        discountedPrice,
        isFuturePayment,
        isBaselinePayment,
        redirectToPaymentFlow,
        openPdfInNewTab,
        isPdfLoading,
        invoiceId,
        isReadOnly,
        isFailedPayment,
        isPendingPayment,
        isLoading,
        isRenewalDisabled,
    } = usePaymentStepTimelineLogic({ payment, status, farmId });

    return (
        <div className={classes.container}>
            {isLoading ? (
                <Grid display="flex" flexDirection="column" gap="8px">
                    <Skeleton variant="text" width={150} height={20} />
                    <Grid display="flex" gap="8px" justifyContent="space-between">
                        <div className={classes.textBlock}>
                            <Skeleton variant="text" width={150} height={20} />
                            <Skeleton variant="text" width={150} height={20} />
                        </div>
                        <div className={classes.textBlock}>
                            <Skeleton variant="text" width={150} height={20} />
                            <Skeleton variant="text" width={150} height={20} />
                        </div>
                        <Skeleton variant="text" width={150} height={40} />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Typography variant="caps" className={classes.title}>
                        {title}
                    </Typography>
                    <div className={classes.content}>
                        <div className={classes.textBlock}>
                            <Typography variant="body" color="darkScale.700">
                                {t('constants.price')}
                            </Typography>
                            <Typography variant="body" className={classes.textTooltip}>
                                {discountedPrice && canRenew && !isFuturePayment ? (
                                    <>
                                        <span className={classes.crossed}>
                                            {t('formatted-price', {
                                                price: payment.subtotal,
                                                currency,
                                            })}
                                        </span>
                                        {t('formatted-price', {
                                            price: discountedPrice,
                                            currency,
                                        })}
                                    </>
                                ) : (
                                    displayedPrice
                                )}
                                <ConditionalTooltip
                                    text={priceTooltip}
                                    placement="top-start"
                                    PopperProps={{
                                        sx: {
                                            [`& .MuiTooltip-arrow`]: {
                                                left: '2px !important',
                                            },
                                        },
                                    }}
                                />
                            </Typography>
                        </div>
                        {displayedPayment && (
                            <div className={classes.textBlock}>
                                <Typography variant="body" color="darkScale.700">
                                    {t('constants.payment')}
                                </Typography>
                                <Typography
                                    variant="body"
                                    className={`${classes.textTooltip} ${
                                        isFailedPayment ? classes.redText : isPendingPayment ? classes.orangeText : ''
                                    }`}
                                >
                                    {isFailedPayment && <IconWarning color="error" />}
                                    {displayedPayment}
                                    <ConditionalTooltip
                                        text={paymentTooltip}
                                        placement="top-start"
                                        PopperProps={{
                                            sx: {
                                                [`& .MuiTooltip-arrow`]: {
                                                    left: '2px !important',
                                                },
                                            },
                                        }}
                                    />
                                </Typography>
                            </div>
                        )}
                        {displayCTA && (
                            <Tooltip
                                text={
                                    isRenewalDisabled
                                        ? t('billing.subscription.timeline.renewal-disabled')
                                        : isPendingPayment
                                        ? t('billing.subscription.timeline.sepa-info')
                                        : !isBaselinePayment && !canRenew
                                        ? t('billing.subscription.timeline.renewal-info')
                                        : ''
                                }
                                placement="top-end"
                                className={classes.tooltip}
                            >
                                <Button
                                    variant="contained"
                                    onClick={redirectToPaymentFlow}
                                    disabled={
                                        (!isBaselinePayment && !canRenew) ||
                                        isReadOnly ||
                                        isPendingPayment ||
                                        isRenewalDisabled
                                    }
                                    className={classes.buttonStyle}
                                >
                                    {CTAlabel}
                                    <IconArrowRight />
                                </Button>
                            </Tooltip>
                        )}
                        {invoiceId && !isFailedPayment && !isPendingPayment && (
                            <Button onClick={openPdfInNewTab} className={classes.buttonStyle} loading={isPdfLoading}>
                                <IconEyeOutlined />
                                {t('billing.subscription.timeline.invoice')}
                            </Button>
                        )}
                        {displayDescription && (
                            <Card className={classes.greyContainer}>
                                {displayDescriptionIcon && <IconStar color="success" />}
                                <Typography variant="body2">
                                    <Trans
                                        t={t}
                                        i18nKey={descriptionTransKey}
                                        values={{
                                            harvest_year: payment.harvest_year,
                                            price: payment.subtotal,
                                            discounted_price: payment.total_excluding_tax,
                                            //The BE is suppose to return the current discount so payment.discounts[0]
                                            discount_end_date: formatDateWithTranslatedMonth(
                                                payment.discounts[0]?.end_date || '',
                                                t,
                                            ),
                                            discount_amount: payment.discounts[0]?.value,
                                            currency,
                                        }}
                                    />
                                </Typography>
                            </Card>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

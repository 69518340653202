import { Button, Tooltip } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { IconClose, IconMerge } from '@soil-capital/ui-kit/icons';
import { IconCheckboxChecked } from '@soil-capital/ui-kit/icons';
import { MergedFieldModal } from '@modules/encoding/modules/rotation/components/MergedFieldModal/MergedFieldModal';
import { useMergeFieldLogic } from './MergeField.logic';
import { FieldT, GeometryDataT } from '@shared/entities';
import { Dispatch, SetStateAction } from 'react';
import { MAPMODE, MapModeT } from '@modules/encoding/modules/rotation/components/PacImportMap/PacImportMap.logic';

export const MergeField = ({
    readOnly,
    invalidFields,
    fields,
    mapMode,
    setMapMode,
    isYoyFarmSeason,
    isDisabled,
}: {
    readOnly: boolean;
    invalidFields: GeometryDataT[];
    fields: FieldT[];
    mapMode: MapModeT;
    setMapMode: Dispatch<SetStateAction<MapModeT>>;
    isYoyFarmSeason: boolean;
    isDisabled: boolean;
}) => {
    const { t } = useTranslation();
    const {
        merge,
        stopMerging,
        onFinishMerge,
        newMergedPolygon,
        selectedFieldIds,
        mergedFieldModalOpen,
        onMergedFieldModalClose,
        invalidFieldsNumber,
    } = useMergeFieldLogic({ invalidFields, fields, mapMode, setMapMode, isYoyFarmSeason });

    return (
        <>
            {fields?.length && mapMode === MAPMODE.NONE ? (
                <Tooltip text={invalidFieldsNumber ? t('encoding-rotation.introduction.invalid-fields-tooltip') : ''}>
                    <Button
                        startIcon={<IconMerge sx={{ width: '20px', height: '20px' }} />}
                        onClick={merge}
                        disabled={readOnly || !!invalidFieldsNumber || isDisabled}
                        sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                        {t('encoding-rotation.map.merge-field')}
                    </Button>
                </Tooltip>
            ) : (
                <></>
            )}
            {mapMode === MAPMODE.MERGE && (
                <>
                    <Button startIcon={<IconClose sx={{ width: '20px', height: '20px' }} />} onClick={stopMerging}>
                        {t('constants.cancel')}
                    </Button>
                    <Button
                        startIcon={<IconCheckboxChecked sx={{ width: '20px', height: '20px' }} />}
                        disabled={!selectedFieldIds || selectedFieldIds?.length < 2}
                        variant="contained"
                        onClick={onFinishMerge}
                    >
                        {t('constants.save')}
                    </Button>
                </>
            )}
            {newMergedPolygon && (
                <MergedFieldModal
                    polygon={newMergedPolygon}
                    open={mergedFieldModalOpen}
                    onClose={onMergedFieldModalClose}
                />
            )}
        </>
    );
};

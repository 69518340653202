import { useEffect, useState } from 'react';
import { useOperationDuplicationFormValues } from '../../../shared/hooks/useOperationDuplicationFormValues';
import { OPERATION_DUPLICATION_INPUT_NAMES } from '../../../shared/hooks/useOperationDuplicationForm';
import { useFormContext } from 'react-hook-form';
import { DuplicationFieldT } from '../../../shared/hooks/useGetDuplicationFieldCropData';

export const useOperationDuplicationList = ({ sourceField }: { sourceField: DuplicationFieldT }) => {
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const { hasSameTillageId, hasSameOrganic, hasSameCompanionCrop, operationIds } =
        useOperationDuplicationFormValues();

    const methods = useFormContext();

    useEffect(() => {
        const allOperationsAreSelected =
            hasSameTillageId &&
            hasSameCompanionCrop &&
            hasSameOrganic &&
            operationIds.length ===
                sourceField.operationsByOperationType.reduce((acc, curr) => {
                    return acc + curr.operations.length;
                }, 0);
        setSelectAll(allOperationsAreSelected);
    }, [
        hasSameCompanionCrop,
        hasSameOrganic,
        hasSameTillageId,
        operationIds.length,
        sourceField.operationsByOperationType,
    ]);

    const handleSelectAllOperationsClick = () => {
        const selectAllPreviousValue = selectAll;
        if (selectAllPreviousValue) {
            methods.setValue(OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_COMPANION_CROP_ID, false);
            methods.setValue(OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_ORGANIC, false);
            methods.setValue(OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_TILLAGE_ID, false);
            methods.setValue(OPERATION_DUPLICATION_INPUT_NAMES.OPERATION_IDS, []);
        } else {
            methods.reset();
        }
    };

    return {
        selectAll,
        handleSelectAllOperationsClick,
    };
};

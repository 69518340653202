import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useCoverCropDestructionMethodQuestion } from './useCoverCropDestructionMethodQuestion';
import { useTranslation } from 'react-i18next';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';
import { FormAutoCompleteOptionT, FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';
import { WarningBox } from '@shared/components/WarningBox/WarningBox';
import { useCoverCropDestructionMethodQuestionStyles } from './useCoverCropDestructionMethodQuestion.style';

export const CoverCropDestructionMethodQuestion = ({
    options,
    onChange,
}: {
    options: FormAutoCompleteOptionT[];
    onChange: () => void;
}) => {
    const { isVisible, showWarning } = useCoverCropDestructionMethodQuestion();
    const { t } = useTranslation();
    const { classes } = useCoverCropDestructionMethodQuestionStyles();

    return (
        <>
            {isVisible && (
                <div className={classes.questionContainer}>
                    <FormRow>
                        <FormQuestion
                            question={t('encoding-cover-crop.question.destruction-method')}
                            tooltipTitleKey="encoding-cover-crop.question.tooltips.title.destruction-method"
                            tooltipTextKey="encoding-cover-crop.question.tooltips.text.destruction-method"
                        />
                        <FormAutocomplete
                            name={COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_METHOD_ID}
                            placeholder={t('constants.option.placeholder')}
                            onChange={onChange}
                            options={options}
                            multiple={false}
                        />
                    </FormRow>
                    {showWarning && (
                        <WarningBox displayWarningIcon={true}>
                            {t('encoding-cover-crop.question.destruction-method-warning')}
                        </WarningBox>
                    )}
                </div>
            )}
        </>
    );
};

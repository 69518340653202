import { useGetFarmSeasonProfileQuery } from '@modules/encoding/modules/farmProfile/api/farmProfileApi';
import {
    FarmProfileDifficultyStaticDataT,
    FarmProfileDocumentsStaticDataT,
    FarmProfileExpectationStaticDataT,
    FarmProfileFarmTypeStaticDataT,
    FarmProfileObjectiveStaticDataT,
    FarmProfileTillagePracticeStaticDataT,
    FarmProfileFmisStaticDataT,
    type FarmSeasonProfileT,
} from '@modules/encoding/modules/farmProfile/api/farmProfileApi.types';
import { FARM_PROFILE_STATIC_DATA_TYPE } from '@modules/encoding/modules/farmProfile/farmProfile.types';
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

const computeFarmSeasonProfile = (farmSeasonProfile?: FarmSeasonProfileT) => {
    if (!farmSeasonProfile) return null;
    const { static_data, ...rest } = farmSeasonProfile;

    const staticData = static_data?.reduce(
        (acc, curr) => {
            if (curr.type === FARM_PROFILE_STATIC_DATA_TYPE.FARM_TYPE) acc.farmType = curr;
            if (curr.type === FARM_PROFILE_STATIC_DATA_TYPE.FARM_TILLAGE_PRACTICE) acc.tillagePractice = curr;
            if (curr.type === FARM_PROFILE_STATIC_DATA_TYPE.FARM_OBJECTIVES) acc.objectives.push(curr);
            if (curr.type === FARM_PROFILE_STATIC_DATA_TYPE.FARM_DIFFICULTIES) acc.difficulties.push(curr);
            if (curr.type === FARM_PROFILE_STATIC_DATA_TYPE.FARM_EXPECTATIONS) acc.expectations.push(curr);
            if (curr.type === FARM_PROFILE_STATIC_DATA_TYPE.FARM_DOCUMENTS) acc.documents.push(curr);
            if (curr.type === FARM_PROFILE_STATIC_DATA_TYPE.FARM_DOCUMENTS_FMIS) acc.documentsFmis.push(curr);

            return acc;
        },
        {
            farmType: null,
            tillagePractice: null,
            objectives: [],
            difficulties: [],
            expectations: [],
            documents: [],
            documentsFmis: [],
        } as {
            farmType: FarmProfileFarmTypeStaticDataT | null;
            tillagePractice: FarmProfileTillagePracticeStaticDataT | null;
            objectives: FarmProfileObjectiveStaticDataT[];
            difficulties: FarmProfileDifficultyStaticDataT[];
            expectations: FarmProfileExpectationStaticDataT[];
            documents: FarmProfileDocumentsStaticDataT[];
            documentsFmis: FarmProfileFmisStaticDataT[];
        },
    );

    return {
        ...rest,
        ...staticData,
    };
};

type useFarmSeasonProfileComputedProps = { farmSeasonId?: number };

export const useFarmSeasonProfileComputed = ({ farmSeasonId }: useFarmSeasonProfileComputedProps) => {
    const { data, ...rest } = useGetFarmSeasonProfileQuery(farmSeasonId ? { farmSeasonId } : skipToken);
    const farmSeasonProfileStaticDataIds = useMemo(() => data?.static_data?.map((data) => data.id), [data]);

    const computedFarmSeasonProfile = useMemo(() => computeFarmSeasonProfile(data), [data]);

    return { data: computedFarmSeasonProfile, farmSeasonProfileStaticDataIds, ...rest };
};

export type FarmSeasonProfileDataComputedT = ReturnType<Awaited<typeof useFarmSeasonProfileComputed>>['data'];

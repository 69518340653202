import { EntityState } from '@reduxjs/toolkit';
import api from '@shared/api';
import fieldCropCache from '../fieldCrop/fieldCrop.cache';
import fieldCache from './field.cache';
import {
    FieldEndpointParamsT,
    FieldT,
    FieldUpdatePayloadT,
    SaveMergedFieldRequestT,
    SaveMergedFieldResponseT,
    SaveSplittedFieldRequestT,
    SaveSplittedFieldResponseT,
    opendataFieldT,
    drawnFieldT,
    ReplaceCropResponseT,
    ReplaceCropRequestT,
    SoilAnalysisDuplicateBodyT,
    emptySpotFieldT,
} from './field.types';
import { GeometryDataT } from '../geometryData/geometryData.types';
import geometryDataCache from '../geometryData/geometryData.cache';

const url = (fsId?: number) => `/v3/farm-seasons/${fsId}/fields`;

export const fieldApiInstance = api.injectEndpoints({
    endpoints: (build) => ({
        getField: build.query<EntityState<FieldT>, FieldEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: url(farmSeasonId),
                method: 'GET',
            }),
            providesTags: ['Field'],
            transformResponse: fieldCache.transformResponse,
        }),

        createField: build.mutation<FieldT, FieldEndpointParamsT & { body: opendataFieldT }>({
            query: ({ body, farmSeasonId }) => ({
                url: url(farmSeasonId),
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Progress', 'TotalArea'],
            onQueryStarted: fieldCache.add('fetch-only'),
        }),

        createEmptySpotField: build.mutation<FieldT, FieldEndpointParamsT & { body: emptySpotFieldT }>({
            query: ({ body, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/empty-spot`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Progress', 'TotalArea'],
            onQueryStarted: ({ farmSeasonId }, apiContext) => {
                apiContext.queryFulfilled
                    .then(({ data: field }) => {
                        fieldCache.add('cache-only')({ farmSeasonId, body: field }, apiContext);
                        geometryDataCache.remove('cache-only')(
                            { farmSeasonId, id: field.geometry_data_id as number },
                            apiContext,
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        }),

        createDrawnField: build.mutation<FieldT, FieldEndpointParamsT & { body: drawnFieldT }>({
            query: ({ body, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/drawn`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Progress', 'TotalArea'],
            onQueryStarted: fieldCache.add('fetch-only'),
        }),

        createCorrectedInvalidField: build.mutation<FieldT, FieldEndpointParamsT & { body: GeometryDataT }>({
            query: ({ body, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/corrected-invalid-field`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Progress', 'TotalArea', 'Crop', 'FieldCrop'],
            onQueryStarted: ({ farmSeasonId }, apiContext) => {
                apiContext.queryFulfilled
                    .then(({ data: field }) => {
                        fieldCache.add('cache-only')({ farmSeasonId, body: field }, apiContext);
                        geometryDataCache.remove('cache-only')(
                            { farmSeasonId, id: field.geometry_data_id as number },
                            apiContext,
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        }),

        updateField: build.mutation<FieldT, FieldEndpointParamsT & { body: FieldUpdatePayloadT; id: number }>({
            query: ({ body, farmSeasonId, id }) => ({
                url: `${url(farmSeasonId)}/${id}`,
                method: 'PATCH',
                body,
            }),
            onQueryStarted: fieldCache.update('cache-then-fetch'),
            invalidatesTags: ['TotalArea'],
        }),

        deleteField: build.mutation<void, FieldEndpointParamsT & { id: number }>({
            query: ({ farmSeasonId, id }) => ({
                url: `${url(farmSeasonId)}/${id}`,
                method: 'DELETE',
            }),
            onQueryStarted: fieldCache.remove('cache-only'),
            invalidatesTags: ['Progress', 'TotalArea'],
        }),

        deleteAllFields: build.mutation<void, FieldEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: `${url(farmSeasonId)}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Field', 'FieldCrop', 'Operation', 'Operations', 'Progress', 'TotalArea'],
        }),

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getFieldSnapshot: build.query<any, FieldEndpointParamsT & { fieldId?: number }>({
            query: ({ farmSeasonId, fieldId }) => ({
                url: `${url(farmSeasonId)}/${fieldId}/snapshot`,
                method: 'GET',
                responseHandler: (response) => {
                    return response.blob();
                },
            }),
            transformResponse: (response) => {
                return URL.createObjectURL(response as Blob);
            },
        }),

        /* --------------------------------- ACTIONS -------------------------------- */
        saveMergedField: build.mutation<SaveMergedFieldResponseT, SaveMergedFieldRequestT>({
            query: ({ body, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/save-merged-field`,
                method: 'POST',
                body,
            }),
            onQueryStarted: async ({ farmSeasonId }, apiContext) => {
                const { data: mergedFieldResp } = await apiContext.queryFulfilled;
                fieldCache.add('cache-only')({ farmSeasonId, body: mergedFieldResp.field_created }, apiContext);

                mergedFieldResp.fields_deleted.forEach((id) => {
                    fieldCache.remove('cache-only')({ farmSeasonId, id }, apiContext);
                });

                mergedFieldResp.field_crops_created.forEach((fc) => {
                    fieldCropCache.add('cache-only')({ farmSeasonId, body: fc }, apiContext);
                });
            },
            invalidatesTags: ['Operation', 'Progress'],
        }),

        saveSplittedField: build.mutation<SaveSplittedFieldResponseT, SaveSplittedFieldRequestT>({
            query: ({ body, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/save-splitted-field`,
                method: 'POST',
                body,
            }),
            onQueryStarted: async ({ farmSeasonId }, apiContext) => {
                const { data: splittedFieldResp } = await apiContext.queryFulfilled;

                splittedFieldResp.fields_created.forEach((f) => {
                    fieldCache.add('cache-only')({ farmSeasonId, body: f }, apiContext);
                });

                fieldCache.remove('cache-only')({ farmSeasonId, id: splittedFieldResp.field_deleted }, apiContext);

                splittedFieldResp.field_crops_created.forEach((fc) => {
                    fieldCropCache.add('cache-only')({ farmSeasonId, body: fc }, apiContext);
                });
            },
            invalidatesTags: ['Operation', 'Progress'],
        }),

        // Replace FarmSeasonCrops linked to the field by a new one
        replaceCrops: build.mutation<ReplaceCropResponseT, ReplaceCropRequestT>({
            query: ({ fieldId, body: { newCropId }, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/${fieldId}/replace-crops`,
                method: 'POST',
                body: { new_farm_season_crop_id: newCropId },
            }),
            onQueryStarted: async ({ farmSeasonId }, apiContext) => {
                try {
                    const { data: replaceCropsResp } = await apiContext.queryFulfilled;

                    if (replaceCropsResp && replaceCropsResp.field_crop_created) {
                        await fieldCropCache.add('cache-only')(
                            { farmSeasonId, body: replaceCropsResp.field_crop_created },
                            apiContext,
                        );
                    }
                    if (replaceCropsResp && Array.isArray(replaceCropsResp.field_crops_deleted)) {
                        await Promise.all(
                            replaceCropsResp.field_crops_deleted.map((fieldCropId) => {
                                return fieldCropCache.remove('cache-only')(
                                    { farmSeasonId, id: fieldCropId },
                                    apiContext,
                                );
                            }),
                        );
                    }
                } catch (error) {
                    console.error('Error during replaceCrops cache update:', error);
                }
            },
        }),

        duplicateSoilAnalysis: build.mutation<
            { fields_updated: FieldT[] },
            FieldEndpointParamsT & { id: number; body: SoilAnalysisDuplicateBodyT }
        >({
            query: ({ id, body, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/${id}/duplicate-soil-analysis`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Field'],
        }),
        addMissingField: build.mutation<
            FieldT,
            { farmSeasonId: number; farmSeasonFieldId: number; targetFarmSeasonId: number }
        >({
            query: ({ farmSeasonId, farmSeasonFieldId, targetFarmSeasonId }) => ({
                url: `${url(farmSeasonId)}/add-missing-field`,
                body: { target_farm_season_id: targetFarmSeasonId, field_id: farmSeasonFieldId },
                method: 'POST',
            }),
            invalidatesTags: ['Progress'],
            onQueryStarted: async ({ farmSeasonId }, apiContext) => {
                const { data: missingFieldRes } = await apiContext.queryFulfilled;
                fieldCache.add('cache-only')({ farmSeasonId, body: missingFieldRes }, apiContext);
            },
        }),
    }),
});

export default {
    useGet: fieldApiInstance.useGetFieldQuery,
    useCreate: fieldApiInstance.useCreateFieldMutation,
    useCreateDrawn: fieldApiInstance.useCreateDrawnFieldMutation,
    useCreateEmptySpot: fieldApiInstance.useCreateEmptySpotFieldMutation,
    useCreateCorrectedInvalidField: fieldApiInstance.useCreateCorrectedInvalidFieldMutation,
    useUpdate: fieldApiInstance.useUpdateFieldMutation,
    useDelete: fieldApiInstance.useDeleteFieldMutation,
    useDeleteAll: fieldApiInstance.useDeleteAllFieldsMutation,
    useGetSnapshot: fieldApiInstance.useGetFieldSnapshotQuery,
    useSaveMerged: fieldApiInstance.useSaveMergedFieldMutation,
    useSaveSplited: fieldApiInstance.useSaveSplittedFieldMutation,
    useReplaceCrops: fieldApiInstance.useReplaceCropsMutation,
    useDuplicateSoilAnalysis: fieldApiInstance.useDuplicateSoilAnalysisMutation,
    useAddMissingField: fieldApiInstance.useAddMissingFieldMutation,
};

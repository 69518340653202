import { auth } from '@modules/auth';
import { STATIC_ROLE_SLUGS } from '@modules/auth/api/authApi.types';
import { useLogErrorMutation } from '@shared/api/logErrorApi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCroppingViewAccessCheck = () => {
    const navigate = useNavigate();
    const [logError] = useLogErrorMutation();
    const user = auth.useAuthUser();

    useEffect(() => {
        const isAgronomist = user?.role?.name === STATIC_ROLE_SLUGS.AGRONOMIST;
        if (!isAgronomist) {
            const message = 'User is not an agronomist and cannot access the cropping view';
            console.error(message);
            logError({ errors: [message, `user.role.name = ${user?.role?.name}`] });
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

import MapPopup from '@shared/components/MapPopup/MapPopup';
import { FieldT, GeometryDataT } from '@shared/entities';
import { MapRoot, MapPortal, MapControl, MapLayers, featureSelectionLayers } from '@shared/map';
import { MapBanner } from '@shared/map/components/MapBanner/MapBanner';
import { farmSeasonBoundariesLayers } from '@shared/map/layers/farmBoundariesLayers';
import { invalidFieldsLayers } from '@shared/map/layers/invalidFieldsLayers';
import { t } from 'i18next';
import { MapDrawingControl } from '../MapDrawingControl/MapDrawingControl';
import { MapOpendataControl } from '../MapOpendataControl/MapOpendataControl';
import { usePacImportActionPageMap, MAPMODE } from './PacImportMap.logic';

export const PacImportMap = ({
    readOnly,
    farmCoordinates,
    fields,
    invalidFields,
    isYoyFarmSeason,

    isBaseline,
}: {
    farmCoordinates: [number, number];
    readOnly: boolean;
    fields: FieldT[];
    invalidFields: GeometryDataT[];
    isYoyFarmSeason: boolean;
    isBaseline?: boolean;
}) => {
    const {
        mapLayerSource,
        invalidFieldsMapLayerSource,
        farmBoundariesLayerSource,
        onFeatureHover,
        onClick,
        onHover,
        fieldIdHovered,
        fieldIdsFocused,
        fieldIdsSelected,
        helpText,
        mapMode,
        setMapMode,
        hoveredFeatureFieldOverview,
        farmSeasonBoundaries,
        allFieldIds,
        allInvalidFieldIds,
        fieldIdsHighligted,
    } = usePacImportActionPageMap(fields, invalidFields);

    return (
        <>
            <MapRoot />
            <MapPortal coordinates={fields.length || invalidFields.length ? undefined : farmCoordinates}>
                <MapBanner helpText={t(helpText)} />
                <MapControl polygons={fields?.map((field) => field.polygon)} fallbackCoord={farmCoordinates} />
                <MapDrawingControl
                    readOnly={readOnly}
                    fields={fields}
                    invalidFields={invalidFields}
                    farmBoundaries={farmSeasonBoundaries}
                    existingPolygons={fields.map((field) => field.polygon.geometry)}
                    mapMode={mapMode}
                    setMapMode={setMapMode}
                    isYoyFarmSeason={isYoyFarmSeason}
                />
                {/* OpenData Layer */}
                {mapMode === MAPMODE.NONE && isBaseline && !readOnly && <MapOpendataControl />}
                {/* Fields Layer */}
                <MapLayers
                    onFeatureClick={onClick}
                    onFeatureHover={mapMode === MAPMODE.NONE ? onFeatureHover : onHover}
                    focusedIds={allInvalidFieldIds.length ? [] : fieldIdsFocused ?? allFieldIds}
                    selectedIds={fieldIdsSelected}
                    hoveredId={fieldIdHovered}
                    dataSource={mapLayerSource}
                    layers={featureSelectionLayers}
                    highlightedIds={fieldIdsHighligted}
                />
                {/* Invalid Fields Layer */}
                <MapLayers
                    onFeatureClick={onClick}
                    focusedIds={allInvalidFieldIds.length ? fieldIdsFocused ?? allInvalidFieldIds : []}
                    dataSource={invalidFieldsMapLayerSource}
                    layers={invalidFieldsLayers}
                    highlightedIds={fieldIdsHighligted}
                />
                {farmSeasonBoundaries?.features && (
                    // Farm boundaries Layer
                    <MapLayers dataSource={farmBoundariesLayerSource} layers={farmSeasonBoundariesLayers} />
                )}
                {/* {!isBaseline && (
                       // Display selectable parcel inside farm boundaries gap, feature on hold don't uncomment/delete pls
                         <MapEmptySpotFields
                            isActive={mapMode === MAPMODE.NONE && !readOnly}
                            farmSeasonBoundaries={farmSeasonBoundaries}
                            mapLayerFieldsSource={mapLayerSource}
                            invalidFields={invalidFields}
                        /> )}
                */}
                <MapPopup hoveredFeatureFieldOverview={hoveredFeatureFieldOverview} />
            </MapPortal>
        </>
    );
};

import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { useFormContext } from 'react-hook-form';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const useDuplicationSeasonSelection = () => {
    const context = useFormContext();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { currentSeasonId } = useCurrentSeasonId();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });
    const { staticSeasonState } = entities.staticSeason.useState();
    const { watch } = context || {};

    const selectedCrops = watch('crop_ids') ?? [];
    const selectedFarmSeasonId = watch('source_season_id') ?? null;

    const selectedFarmSeason = seasonState?.list?.find((fs) => fs.season_id === selectedFarmSeasonId);
    const selectedSeason = staticSeasonState?.list?.find((s) => s.id === selectedFarmSeason?.season_id);
    const { cropState: selectedFarmSeasonCrops } = entities.crop.useState(
        { farmSeasonId: selectedFarmSeason?.id },
        { skip: !selectedFarmSeason?.id },
    );
    const targetSeason = seasonState?.list?.find((fs) => fs.id === currentSeasonId);
    const targetSeasonIsYoySeason = !!targetSeason?.carbon_programme_year && targetSeason.carbon_programme_year > 0;

    if (!context) {
        throw new Error('useDuplicationSeasonSelection must be used within a FormProvider');
    }

    const selectedCropsDontExistOnSelectedYear =
        selectedFarmSeasonId && selectedFarmSeasonCrops?.list?.length > 0
            ? selectedCrops.filter(
                  (selectedCrop: number) =>
                      !selectedFarmSeasonCrops?.list?.some((fsCrop) => fsCrop.crop_id === selectedCrop),
              )?.length > 0
            : false;

    return {
        selectedFarmSeason,
        selectedSeason,
        targetSeasonIsYoySeason,
        selectedFarmSeasonCrops,
        selectedCropsDontExistOnSelectedYear,
    };
};

import { useWeedingMachineFormStyle } from '@modules/encoding/modules/technicalItinerary/module/weedingMachine/components/WeedingMachineForm/useWeedingMachineForm.style';
import { FormProvider } from 'react-hook-form';
import { useWeedingMachineForm } from './useWeedingMachineForm.logic';
import WeedingMachineFormSkeleton from './WeedingMachineFormSkeleton';
import { WeedingMachineMachineryInput } from '../WeedingMachineMachineryInput/WeedingMachineMachineryInput';
import { WeedingMachineDepthInput } from '../WeedingMachineDepthInput/WeedingMachineDepthInput';
import { WeedingMachinePassageInput } from '../WeedingMachinePassageInput/WeedingMachinePassageInput';
import { ReuseOperationSelect } from '../../../operationDuplication/reuse/components/ReuseOperationSelect/ReuseOperationSelect';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { useReuseOperation } from '../../../operationDuplication/reuse/hooks/useReuseOperation';
import { WarningBox } from '@shared/components/WarningBox/WarningBox';
import { useTranslation } from 'react-i18next';

const WeedingMachineForm = () => {
    const { classes, cx } = useWeedingMachineFormStyle();
    const { t } = useTranslation();
    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();

    const {
        methods,
        options,
        isFormLoading,
        onSubmit,
        getFormValues,
        refetchFormData,
        changeMachineryRelatedInputs,
        fieldCropHasCoverCropDestruction,
    } = useWeedingMachineForm();

    const { machineries } = options;
    const { handleReuseOperation, isReuseOperationLoading } = useReuseOperation({
        onOperationReused: refetchFormData,
        getFormValues,
    });

    return (
        <>
            {isFormLoading ? <WeedingMachineFormSkeleton /> : null}
            <FormProvider {...methods}>
                <form onSubmit={onSubmit} className={cx(classes.form, isFormLoading && classes.hiddenForm)}>
                    {fieldCropHasCoverCropDestruction && (
                        <WarningBox displayWarningIcon={true}>
                            {t('encoding-technical-itinerary.warning.cover-crop-double-passage')}
                        </WarningBox>
                    )}
                    <ReuseOperationSelect
                        fieldCropId={fieldCropId}
                        farmSeasonId={seasonId}
                        operationId={operationId}
                        onReuseOperationTriggered={handleReuseOperation}
                        disabled={isReuseOperationLoading}
                        actionLoading={isReuseOperationLoading}
                    />

                    <WeedingMachineMachineryInput
                        machineries={machineries}
                        disabled={isReuseOperationLoading}
                        onChange={() => {
                            changeMachineryRelatedInputs();
                            onSubmit();
                        }}
                    />
                    <WeedingMachinePassageInput onChange={onSubmit} disabled={isReuseOperationLoading} />
                    <WeedingMachineDepthInput onChange={onSubmit} disabled={isReuseOperationLoading} />
                </form>
            </FormProvider>
        </>
    );
};

export default WeedingMachineForm;

import { useWatch } from 'react-hook-form';
import {
    FARM_PROFILE_STATIC_DATA_DOCUMENTS_FMIS_SLUGS,
    FARM_PROFILE_STATIC_DATA_DOCUMENTS_SLUGS,
} from '@modules/encoding/modules/farmProfile/farmProfile.types';
import { FARM_PROFILE_INPUT_NAME } from '../useFarmProfileForm';
import { FarmProfileStaticDataT } from '../../../api/farmProfileApi.types';

export const useFarmProfileDocumentsQuestion = () => {
    const documents = useWatch({ name: FARM_PROFILE_INPUT_NAME.DOCUMENTS });
    const documentsFmis = useWatch({ name: FARM_PROFILE_INPUT_NAME.DOCUMENTS_FMIS });

    return {
        isOtherVisible: documents.some(
            (doc: FarmProfileStaticDataT) => doc.slug === FARM_PROFILE_STATIC_DATA_DOCUMENTS_SLUGS.FARM_DOCUMENTS_OTHER,
        ),
        isFmisvisible: documents.some(
            (doc: FarmProfileStaticDataT) => doc.slug === FARM_PROFILE_STATIC_DATA_DOCUMENTS_SLUGS.FARM_DOCUMENTS_FMIS,
        ),
        isOtherFmisVisible: documentsFmis?.some(
            (doc: FarmProfileStaticDataT) =>
                doc.slug === FARM_PROFILE_STATIC_DATA_DOCUMENTS_FMIS_SLUGS.FARM_DOCUMENTS_FMIS_OTHER,
        ),
    };
};

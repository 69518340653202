import { useMemo, useState } from 'react';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { sortFields } from '../helpers/sortFields';

export const SORT_OPTIONS = {
    NAME_ASC: 'name:asc',
    NAME_DSC: 'name:dsc',
    AREA_ASC: 'area:asc',
    AREA_DSC: 'area:dsc',
};

export type SortOptionT = (typeof SORT_OPTIONS)[keyof typeof SORT_OPTIONS];

export function useSortedFields<
    T extends { area: string | number; name: string },
    U extends { area: number; name: string },
>(fields: T[], invalidFields?: U[]) {
    const [sortOption, setSortOption] = useState<SortOptionT>(SORT_OPTIONS.AREA_ASC);

    const handleSortChange = (event: SelectChangeEvent<unknown>) => {
        if (typeof event.target.value === 'string') {
            setSortOption(event.target.value as SortOptionT);
        }
    };

    const sortedFields = useMemo(() => sortFields(fields, sortOption), [fields, sortOption]);
    const sortedInvalidFields = useMemo(() => sortFields(invalidFields || [], sortOption), [invalidFields, sortOption]);

    return { sortedFields, sortedInvalidFields, sortOption, handleSortChange };
}

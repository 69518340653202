import { GeometryDataT } from '@shared/entities';
import { FieldT } from '@shared/entities/field/field.types';

export const getTextLayoutLabel = (field: FieldT | GeometryDataT, cropNames?: string) => {
    return cropNames ? `${field.name} - ${field.area} ha  \n ${cropNames}` : `${field.name} \n - ${field.area} ha`;
};

export const getTextLayout = (field: FieldT | GeometryDataT, cropNames?: string): mapboxgl.SymbolLayout => {
    const text = getTextLayoutLabel(field, cropNames);

    return {
        'text-field': text,
        'text-size': 12,
        'text-font': ['literal', ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
        'text-anchor': 'center',
        'text-max-width': 10,
    };
};

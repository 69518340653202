import { SplittedFieldModal } from '@modules/encoding/modules/rotation/components/SplittedFieldModal/SplittedFieldModal';
import { Button, Tooltip } from '@soil-capital/ui-kit/components';
import { IconSplit, IconCheckboxChecked, IconClose } from '@soil-capital/ui-kit/icons';
import { useTranslation } from 'react-i18next';
import { useSplitFieldLogic } from './useSplitField.logic';
import { FieldT, GeometryDataT } from '@shared/entities';
import { Dispatch, SetStateAction } from 'react';
import { MAPMODE, MapModeT } from '@modules/encoding/modules/rotation/components/PacImportMap/PacImportMap.logic';

export const SplitField = ({
    readOnly,
    fields,
    invalidFields,
    mapMode,
    setMapMode,
    isYoyFarmSeason,
    isDisabled,
}: {
    readOnly: boolean;
    fields: FieldT[];
    invalidFields: GeometryDataT[];
    mapMode: MapModeT;
    setMapMode: Dispatch<SetStateAction<MapModeT>>;
    isYoyFarmSeason: boolean;
    isDisabled: boolean;
}) => {
    const { t } = useTranslation();

    const { split, stopSplitting, newPolygons, onFinisSplit, modalController, invalidFieldsNumber } =
        useSplitFieldLogic(fields, invalidFields, mapMode, setMapMode, isYoyFarmSeason);

    return (
        <>
            {mapMode === MAPMODE.SPLIT ? (
                <>
                    <Button startIcon={<IconClose sx={{ width: '20px', height: '20px' }} />} onClick={stopSplitting}>
                        {t('constants.cancel')}
                    </Button>
                    <Button
                        startIcon={<IconCheckboxChecked sx={{ width: '20px', height: '20px' }} />}
                        disabled={!newPolygons?.length}
                        variant="contained"
                        onClick={onFinisSplit}
                    >
                        {t('constants.save')}
                    </Button>
                </>
            ) : mapMode === MAPMODE.NONE ? (
                <Tooltip text={invalidFieldsNumber ? t('encoding-rotation.introduction.invalid-fields-tooltip') : ''}>
                    <Button
                        onClick={split}
                        startIcon={<IconSplit color="primary" />}
                        disabled={readOnly || !!invalidFieldsNumber || isDisabled}
                        sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                        {t('encoding-rotation.map.split-field')}
                    </Button>
                </Tooltip>
            ) : null}
            <SplittedFieldModal newPolygons={newPolygons} modalController={modalController} />
        </>
    );
};

import { BoxedImg, Checkbox, Tooltip } from '@soil-capital/ui-kit';
import { Typography, Box, Skeleton } from '@soil-capital/ui-kit/material-core';
import { useYoyDuplicationCropListStyles } from '@modules/yoy/components/YoyDuplicationCropList/YoyDuplicationCropList.style';
import { useDuplicationCropList } from '@modules/yoy/components/YoyDuplicationCropList/useDuplicationCropList';
import { TextEllipsisTooltip } from '@soil-capital/ui-kit/TextEllipsisTooltip';
import { useDuplicationCropSelection } from '@modules/yoy/hooks/useDuplicationCropSelection';
import { useTranslation } from 'react-i18next';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { YoyDuplicationNoCropsForSelectedYearModal } from '@modules/yoy/components/YoyDuplicationNoCropsForSelectedYearModal/YoyDuplicationNoCropsForSelectedYearModal';
import { useToggle } from '@shared/hooks/useToggle';
import { useEffect } from 'react';
import { useDuplicationSeasonSelection } from '@modules/yoy/hooks/useDuplicationSeasonSelection';

const CropListSkeleton = () => {
    const { classes } = useYoyDuplicationCropListStyles();

    return (
        <>
            {Array.from({ length: 5 }).map((_, index) => (
                <Box
                    key={index}
                    className={classes.check}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Skeleton variant="rectangular" width="22px" height="22px" />
                        <Skeleton variant="rounded" width="48px" height="48px" />
                        <Skeleton variant="text" width="150px" height="22px" />
                    </Box>
                    <Skeleton variant="text" width="150px" height="22px" />
                </Box>
            ))}
        </>
    );
};

export const YoyDuplicationCropList = () => {
    const { t } = useTranslation();
    const { classes } = useYoyDuplicationCropListStyles();
    const { open, handleOpen, handleClose } = useToggle();

    const { farmSeasonCrops, isLoading } = useDuplicationCropList();
    const {
        handleSelectCrop,
        handleSelectAllCrops,
        selectedCrops,
        allCropsAreSelected,
        selectedCropsDontExistOnSelectedYear,
    } = useDuplicationCropSelection();
    const { selectedSeason, selectedFarmSeason } = useDuplicationSeasonSelection();

    useEffect(() => {
        if (selectedCropsDontExistOnSelectedYear) handleOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCropsDontExistOnSelectedYear, selectedCrops, selectedFarmSeason?.id]);

    if (isLoading) return <CropListSkeleton />;

    return (
        <>
            <div className={classes.check}>
                <Checkbox checked={allCropsAreSelected ?? false} onChange={handleSelectAllCrops} />
                <Typography variant="h3">{t('yoy.duplication.page.all-crops')}</Typography>
            </div>
            <div className={classes.cropList}>
                {farmSeasonCrops?.map((fsc) => (
                    <div key={fsc.id} className={classes.check}>
                        <Checkbox
                            checked={selectedCrops?.includes(fsc.cropId) ?? false}
                            onChange={() => handleSelectCrop(fsc.cropId)}
                        />
                        <BoxedImg size="medium" src={fsc.image} imgPosition="top" />
                        <TextEllipsisTooltip
                            text={fsc.name}
                            tooltipProps={{ text: fsc.name }}
                            typographyProps={{ variant: 'body' }}
                        />
                        <div className={classes.progressContainer}>
                            <Typography color="neutral">{fsc.progress}</Typography>
                            <Tooltip
                                text={
                                    fsc.hasOneDuplicatedFieldCropITKUntouched
                                        ? t('yoy.duplication.page.crop.has-duplication', {
                                              harvestYear: fsc.duplicationSourceHarvestYears,
                                          })
                                        : undefined
                                }
                            >
                                <IconLightning
                                    sx={{
                                        visibility: fsc.hasOneDuplicatedFieldCropITKUntouched ? '' : 'hidden',
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                ))}
            </div>
            <YoyDuplicationNoCropsForSelectedYearModal
                open={open}
                onClose={handleClose}
                harvestYear={selectedSeason?.harvest_year as number}
            />
        </>
    );
};

import * as z from 'zod';
import { useForm } from '@shared/hooks';
import { calculateAreaProperties } from '@shared/utils/calculateAreaProperties';
import { useTranslation } from 'react-i18next';
import { FieldPolygonT, FieldT } from '@shared/entities/field/field.types';
import { useEffect, useState } from 'react';
import fieldApi from '@shared/entities/field/field.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { SplittedFieldSameOriginT } from '@modules/encoding/modules/rotation/components/MapDrawingControl/components/SplitField/useSplitField.logic';
import { ModalPropsT } from '@shared/hooks/useModalController';
import { enqueueSnackbar } from 'notistack';

export const FIELD_SPLITTING_PROPERTIES = {
    FIELD_NAME: 'field_name',
    AREA: 'area',
} as const;

export type AreaPropertiesT = {
    treshold: number;
    maxArea: number;
    minArea: number;
} | null;

export const useSplittedFieldModalFormLogic = ({
    newPolygons,
    modalController,
}: {
    newPolygons: SplittedFieldSameOriginT[] | null;
    modalController: ModalPropsT<{ newPolygon: FieldPolygonT }, boolean>;
}) => {
    const { t } = useTranslation();
    const [saveSplittedFieldMutation, { isLoading }] = fieldApi.useSaveSplited();
    const [areaProperties, setAreaProperties] = useState<AreaPropertiesT>(null);
    const { currentSeasonId } = useCurrentSeasonId();
    const defaultValues = {
        [FIELD_SPLITTING_PROPERTIES.FIELD_NAME]: '',
        [FIELD_SPLITTING_PROPERTIES.AREA]: 0,
    };
    const [newFields, setNewFields] = useState<FieldT[]>([]);

    const onClose = () => {
        if (modalController.onClose) {
            setAreaProperties(null);
            setNewFields([]);
            modalController.onClose(false);
        }
    };

    const schema = z.object({
        [FIELD_SPLITTING_PROPERTIES.FIELD_NAME]: z
            .string()
            .min(1, t('encoding.rotation.field-creation-modal.error-name')),
        [FIELD_SPLITTING_PROPERTIES.AREA]: z
            .number()
            .min(
                Number(areaProperties?.minArea.toFixed(2)),
                t('encoding.rotation.field-creation-modal.error-under-treshold', {
                    treshold: areaProperties?.treshold,
                }),
            )
            .max(
                Number(areaProperties?.maxArea.toFixed(2)),
                t('encoding.rotation.field-creation-modal.error-over-treshold', { treshold: areaProperties?.treshold }),
            ),
    });

    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });

    const {
        reset,
        handleSubmit,
        formState: { errors },
    } = methods;

    const formatPolygonToField = (newName: string, newArea: number) => {
        const newPolygon = modalController.data?.newPolygon;
        const newField = {
            farm_season_id: currentSeasonId,
            name: newName,
            area: newArea,
            original_area: Number(newPolygon?.properties.area.toFixed(2)),
            area_source: 'drawn',
            user_area: newArea,
            polygon_geometry: newPolygon?.geometry,
            polygon: {
                ...newPolygon,
                properties: {
                    ...newPolygon?.properties,
                    label: newName,
                },
            },
        } as FieldT;
        return newField;
    };

    const onSubmit = handleSubmit(async (data) => {
        const newName = data[FIELD_SPLITTING_PROPERTIES.FIELD_NAME];
        const newArea = data[FIELD_SPLITTING_PROPERTIES.AREA];

        const newField = formatPolygonToField(newName, newArea);

        setNewFields([...newFields, newField]);

        const currentOriginFieldId = modalController.data?.newPolygon.properties.originalFeatureId;

        if (
            [...newFields, newField].length !==
            newPolygons?.find((item) => Number(item.originFieldId) === currentOriginFieldId)?.fieldsPolygons.length
        ) {
            if (modalController.onClose) modalController.onClose(true);
        } else {
            const response = await saveSplittedFieldMutation({
                farmSeasonId: currentSeasonId,
                body: {
                    splitted_fields: [...newFields, newField],
                    from_field_id: currentOriginFieldId || 0,
                },
            });
            if (response && 'error' in response) {
                enqueueSnackbar(t('encoding.rotation.field-creation-modal.error'), {
                    variant: 'error',
                });
            } else {
                setNewFields([]);
                if (modalController.onClose) modalController.onClose(true);
            }
        }
    });

    const onConfirm = async () => {
        await onSubmit();
    };

    useEffect(() => {
        if (modalController.data) {
            setAreaProperties(calculateAreaProperties(modalController.data.newPolygon.properties.area));
            reset({
                [FIELD_SPLITTING_PROPERTIES.FIELD_NAME]: modalController.data.newPolygon.properties.label,
                [FIELD_SPLITTING_PROPERTIES.AREA]: Number(modalController.data.newPolygon.properties.area.toFixed(2)),
            });
        }
    }, [modalController.data, reset]);

    return {
        methods,
        onConfirm,
        isEditingField: isLoading,
        errors,
        modalController,
        onClose,
        treshold: areaProperties?.treshold,
    };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CroppingField } from '@modules/croppingView/api/fieldsCroppingFetcher';
import { useMemo } from 'react';
import { computeGridFromDate, getGridDayRatio } from '../utils/computeGridFromDate';
import { mscOperationTypes } from '@modules/croppingView/constants/msc-operation-types';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Tooltip } from '@soil-capital/ui-kit/components';
import { IconInfo } from '@soil-capital/ui-kit/icons';
import { Link } from 'react-router-dom';
import { MediaInfoBlock } from '@soil-capital/ui-kit/MediaInfoBlock';
import { makeStyles } from '@soil-capital/ui-kit/style';
import { GridStatus } from '../../GridStatus/GridStatus';
import { sortByUniques } from '../utils/sortByUniques';
import { mscCropTypes } from '@modules/croppingView/constants/msc-crop-types';

const TooltipContent = ({ date, type }: { type: string; date?: string }) => {
    return (
        <div className="h-full w-full">
            <Typography variant="body">{type}</Typography>
            <Typography variant="body2">
                <span className="text-gray-500">date: </span>
                {date}
            </Typography>
        </div>
    );
};

const TooltipFieldContent = ({
    year,
    name,
    area,
    fieldCropId,
    fieldId,
    farmSeasonId,
}: {
    year: number;
    name: string;
    area: number;
    fieldCropId: number;
    fieldId: number;
    farmSeasonId: number;
}) => {
    return (
        <div className="h-full w-full flex gap-3">
            <MediaInfoBlock.Image
                src={`${process.env.REACT_APP_API_BASE_URL}/v3/farm-seasons/${farmSeasonId}/fields/${fieldId}/thumbnail`}
                style={{ width: '150px', height: '150px' }}
            />
            <div>
                <Typography variant="body">Year {year}</Typography>
                <Typography variant="body2">
                    <span className="text-gray-500">name: </span>
                    {name}
                </Typography>
                <Typography variant="body2">
                    <span className="text-gray-500">area: </span>
                    {area} ha
                </Typography>
                <Typography variant="body2">
                    <span className="text-gray-500">field ID: </span>
                    {fieldId}
                </Typography>
                <Link
                    to={`/${farmSeasonId}/technical-itinerary/${fieldCropId}/global-characteristics`}
                    target="_blank"
                    className="text-blue-500 underline"
                >
                    Go to ITK
                </Link>
            </div>
        </div>
    );
};

export const MscFieldsCropping = ({
    gridTemplateColumns,
    monthsBeforeStart,
    monthsAfterEnd,
    yearStart,
    fieldsCroppingData,
}: {
    gridTemplateColumns: string;
    fieldsCroppingData?: CroppingField[] | null;
    yearStart: number;
    monthsBeforeStart: number;
    monthsAfterEnd: number;
}) => {
    const { classes } = makeStyles()(() => ({
        cropName: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            paddingLeft: '5px',
            fontFamily: 'BrownStd-Regular',
            display: 'block',
            userSelect: 'none',
        },
    }))();

    const operationsByCropping = useMemo(() => {
        const croppingIdOperationMap: Record<
            number,
            { id: number; label: string; date: number; color: string; labelDate?: string }[]
        > = {};

        fieldsCroppingData
            ?.map((fieldCropping) => fieldCropping.operations)
            .flat()
            .filter((op) => op.operation_date !== null)
            .forEach((op) => {
                croppingIdOperationMap[op.field_crop_id] = croppingIdOperationMap[op.field_crop_id] || [];
                croppingIdOperationMap[op.field_crop_id].push({
                    id: op.operation_id,
                    label: op.operation_name,
                    labelDate: op.operation_date,
                    date: computeGridFromDate(op.operation_date, yearStart, monthsBeforeStart) as number,
                    color: mscOperationTypes[op.operation_name]?.color,
                });
            }, []);

        for (const key in croppingIdOperationMap) {
            croppingIdOperationMap[key] = sortByUniques(croppingIdOperationMap[key], (op) => op.date);
        }

        return croppingIdOperationMap;
    }, [fieldsCroppingData, yearStart, monthsBeforeStart]);

    const coverByCropping = useMemo(() => {
        const coverCroppingMap: Record<number, { color: string; label: string; start: number; end: number }> = {};

        for (const key in operationsByCropping) {
            const startCover = operationsByCropping[key].find((op) => op.label === 'Cover crop seeding')?.date;
            const endCover = operationsByCropping[key].find((op) => op.label === 'Cover crop destruction')?.date;
            if (startCover && endCover) {
                coverCroppingMap[key] = {
                    color: mscCropTypes['Cover crops']?.color,
                    label: 'Cover crop',
                    start: startCover,
                    end: endCover,
                };
            }
        }

        return coverCroppingMap;
    }, [operationsByCropping]);

    const mscFieldCroppingComputed = useMemo(() => {
        const getCropSeedingOperation = (
            operations: { id: number; label: string; date: number; color: string; labelDate?: string }[],
        ) => {
            const firstSeedingOperation = operations
                ?.filter((op) => op.label === 'Seeding')
                ?.sort((a: any, b: any) => a.date - b.date);
            return firstSeedingOperation?.[0];
        };

        const getCropHarvestOperation = (
            operations: { id: number; label: string; date: number; color: string; labelDate?: string }[],
        ) => {
            const firstSeedingOperation = operations
                ?.filter((op) => op.label === 'Harvest' || op.label === 'Destruction')
                ?.sort((a: any, b: any) => b.date - a.date);
            return firstSeedingOperation?.[0];
        };

        return fieldsCroppingData
            ?.map((fieldCropping) => {
                return {
                    fieldName: fieldCropping.name,
                    fieldArea: fieldCropping.field_area,
                    fieldId: fieldCropping.id,
                    farmSeasonId: fieldCropping.farm_season_id,
                    year: fieldCropping.year,
                    crops: fieldCropping.crops.map((crop) => ({
                        color: (mscCropTypes as any)[crop.crop_group]?.color ?? '#2ecaac',
                        fieldCropId: crop?.field_crop_id,
                        label: crop?.crop_name,
                        labelGroup: crop?.crop_group,
                        labelStartDate: getCropSeedingOperation(operationsByCropping[crop.field_crop_id])?.labelDate,
                        labelEndDate: getCropHarvestOperation(operationsByCropping[crop.field_crop_id])?.labelDate,
                        start: getCropSeedingOperation(operationsByCropping[crop.field_crop_id])?.date,
                        end: getCropHarvestOperation(operationsByCropping[crop.field_crop_id])?.date ?? '-6',
                        isPermanent: crop.is_permanent,
                        isPerennial: crop.is_perennial,
                    })),
                };
            })
            ?.sort((a, b) => (a.year === b.year ? 0 : b.year - a.year));
    }, [fieldsCroppingData, operationsByCropping]);

    return (
        <>
            {mscFieldCroppingComputed?.map((fieldCroppingComputed, i) => (
                <div
                    key={fieldCroppingComputed.fieldId + i + Math.random()}
                    className={`
                        border-t-[1px]
                        border-gray-300
                        border-solid
                        min-h-12
                        bg-blue-100
                        py-2`}
                >
                    {/* YEAR info */}
                    <span className="absolute">
                        <Tooltip
                            elements={
                                <TooltipFieldContent
                                    year={fieldCroppingComputed.year}
                                    area={fieldCroppingComputed.fieldArea}
                                    name={fieldCroppingComputed.fieldName}
                                    fieldId={fieldCroppingComputed.fieldId}
                                    fieldCropId={fieldCroppingComputed.crops[0]?.fieldCropId}
                                    farmSeasonId={fieldCroppingComputed.farmSeasonId}
                                />
                            }
                            display="inline"
                            scheme="light"
                            placement="right-end"
                        >
                            <div className="m-2 mt-0 select-none">
                                <IconInfo color="primary" fontSize={'small'} className="mr-1" />Y{' '}
                                {fieldCroppingComputed.year}
                            </div>
                        </Tooltip>
                    </span>
                    {fieldCroppingComputed.crops.map((croppingComputed, i) => (
                        <div
                            key={croppingComputed.fieldCropId}
                            className="grid"
                            style={{
                                gridTemplateColumns,
                            }}
                        >
                            {/* COVER CROP */}
                            {coverByCropping[croppingComputed.fieldCropId] && (
                                <span
                                    className="rounded-md"
                                    style={{
                                        gridColumn: `${coverByCropping[croppingComputed.fieldCropId].start}/${
                                            coverByCropping[croppingComputed.fieldCropId].end
                                        }`,
                                        backgroundColor: coverByCropping[croppingComputed.fieldCropId].color,
                                        border: '1px solid rgb(55 96 78 / 30%)',
                                    }}
                                >
                                    <Tooltip
                                        className="w-full h-full"
                                        scheme="light"
                                        display="block"
                                        elements={
                                            <Typography variant="body">
                                                {coverByCropping[croppingComputed.fieldCropId].label}
                                            </Typography>
                                        }
                                        disableInteractive
                                    >
                                        <span className={classes.cropName}>
                                            {coverByCropping[croppingComputed.fieldCropId].label}
                                        </span>
                                    </Tooltip>
                                </span>
                            )}

                            {/* CROP */}
                            {croppingComputed.isPermanent ? (
                                <GridStatus
                                    bgColor="#942f2f"
                                    startPosition={getGridDayRatio(30 * (monthsBeforeStart + 2))}
                                    text="PERMANENT"
                                />
                            ) : (
                                <span
                                    className="text-nowrap rounded-md"
                                    style={{
                                        gridColumn: `${croppingComputed.start}/${croppingComputed.end}`,
                                        backgroundColor: croppingComputed.color,
                                        border: '1px solid rgb(55 96 78 / 30%)',
                                    }}
                                >
                                    <Tooltip
                                        className="w-full h-full"
                                        scheme="light"
                                        display="block"
                                        elements={
                                            <>
                                                <Typography variant="body">{croppingComputed.label}</Typography>
                                                <Typography variant="body2">
                                                    <span className="text-gray-500">dates: </span>
                                                    {croppingComputed.labelStartDate +
                                                        ' → ' +
                                                        croppingComputed.labelEndDate}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <span className="text-gray-500">group: </span>
                                                    {croppingComputed.labelGroup}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <span className="text-gray-500">perennial: </span>
                                                    {croppingComputed.isPerennial === true ? 'true' : 'false'}
                                                </Typography>
                                            </>
                                        }
                                        disableInteractive
                                    >
                                        <span className={classes.cropName}>{croppingComputed?.label}</span>
                                    </Tooltip>
                                </span>
                            )}

                            {/* OPERATIONS */}
                            {operationsByCropping[croppingComputed.fieldCropId]?.map((operation, i) => (
                                <span
                                    key={operation.id}
                                    className="rounded-md h-3 mt-px relative"
                                    style={{
                                        gridColumn: `${operation.date - 1}/${operation.date + 1}`,
                                        backgroundColor: operation.color,
                                    }}
                                >
                                    <Tooltip
                                        elements={<TooltipContent date={operation.labelDate} type={operation.label} />}
                                        display="inline"
                                        scheme="light"
                                        disableInteractive
                                    >
                                        <div className="w-full h-4" />
                                    </Tooltip>
                                </span>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

import { useEffect } from 'react';

export const useTailwindCdn = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/@tailwindcss/browser@4';
        script.async = false;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
};

import { BoxedImg } from '@soil-capital/ui-kit/components';
import { IconLeaf, IconLink04, IconHistory } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useFieldIdentityCardLogic } from './useFieldIdentityCard.logic';
import useFieldIdentityCardStyle from './useFieldIdentityCard.style';
import { FieldIdentityCardPropsT } from './FieldIdentityCard.types';
import { TextEllipsisTooltip } from '@soil-capital/ui-kit/TextEllipsisTooltip';

export const FieldIdentityCard = ({ fieldCropId, setIsLoading }: FieldIdentityCardPropsT) => {
    const { classes } = useFieldIdentityCardStyle();
    const {
        cropCroupImg,
        fieldSurfaceText,
        fieldName,
        cropName,
        companionCropName,
        tillageName,
        TillageIconComponent,
        organicText,
        translatedPreviousCropName,
    } = useFieldIdentityCardLogic({ fieldCropId, setIsLoading });

    return (
        <div className={classes.fieldIdentityCard}>
            <BoxedImg size="large" src={cropCroupImg} className={classes.cropImg} />
            <div className={classes.detailSection}>
                <div className={classes.header}>
                    <TextEllipsisTooltip
                        typographyProps={{ variant: 'caps' }}
                        text={fieldName}
                        className={classes.fieldNameEllipsed}
                    />
                    <Typography variant="caps" color="neutral" ml={1}>
                        {fieldSurfaceText}
                    </Typography>
                </div>
                <TextEllipsisTooltip
                    typographyProps={{ variant: 'h3' }}
                    text={cropName}
                    multiLineCount={companionCropName ? 1 : 2}
                />
                <div className={classes.details}>
                    {companionCropName && (
                        <div className={classes.iconInfo}>
                            <IconLink04 />
                            <Typography ml={1} variant="caption">
                                {companionCropName}
                            </Typography>
                        </div>
                    )}
                    <div className={classes.extraIconInfos}>
                        {organicText && (
                            <span className={`${classes.iconInfo} ${classes.organicIconInfo}`}>
                                <IconLeaf color="inherit" />
                                <Typography ml={1} variant="caption">
                                    {organicText}
                                </Typography>
                            </span>
                        )}
                        {tillageName && (
                            <span className={classes.iconInfo}>
                                <TillageIconComponent color="inherit" />
                                <TextEllipsisTooltip
                                    typographyProps={{ ml: 1, variant: 'caption' }}
                                    text={tillageName}
                                />
                            </span>
                        )}
                    </div>
                    {translatedPreviousCropName && (
                        <div className={classes.iconInfo}>
                            <IconHistory />
                            <Typography ml={1} variant="caption">
                                {translatedPreviousCropName}
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

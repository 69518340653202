import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        overflow: 'auto',
    },
    addressGroup: {
        display: 'flex',
        alignItems: 'end',
        gap: theme.spacing(2),
        '& > *': {
            flex: 1,
        },
    },
    flexbox: {
        display: 'flex',
        alignContent: 'center',
        gap: theme.spacing(1),
    },
    flexCol: {
        display: 'flex',
        alignContent: 'center',
        gap: theme.spacing(1),
        flexDirection: 'column',
        width: '100%',
    },
    bankGroup: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        width: '100%',
    },
    subcaption: {
        color: theme.palette.darkScale[700],
    },
    group: { width: '100%', display: 'flex', flexDirection: 'column', gap: theme.spacing(1) },
    disabled: {
        '& > *': { color: `${theme.palette.darkScale[700]} !important` },
    },
}));

import { CroppingViewData } from '@modules/croppingView/api/dndcCroppingFetcher';
import { CroppingField } from '@modules/croppingView/api/fieldsCroppingFetcher';
import { ReactComponent } from 'tss-react/tools/ReactComponent';
import { TableHeader } from './components/TableHeader';
import { DndcPhases } from './components/DndcPhases';
import { MscFieldsCropping } from './components/MscFieldsCropping';

type CroppingViewTablePropsT = {
    className?: string;
    fieldsCroppingData?: CroppingField[] | null;
    monthsBeforeStart: number;
    yearStart: number;
    years: number;
    monthsAfterEnd: number;
    dndcCroppingData?: CroppingViewData | null;
};

export const CroppingViewTable: ReactComponent<CroppingViewTablePropsT> = (props) => {
    const daysByColumns = 5;
    const daysByMonth = 30;
    const columnsByMonth = daysByMonth / daysByColumns;

    const startingGridColumns = props.monthsBeforeStart * columnsByMonth;
    const yearGridColumns = 12 * columnsByMonth;
    const endingGridColumns = props.monthsAfterEnd * columnsByMonth;
    const gridTemplateDays = startingGridColumns + yearGridColumns * props.years + endingGridColumns - 2; // fix the gap on the right
    const gridTemplateColumns = `repeat(${gridTemplateDays}, 1fr)`;

    return (
        <div className={`${props.className ?? ''} overflow-hidden rounded-xl border border-gray-300 `}>
            <TableHeader
                gridTemplateColumns={gridTemplateColumns}
                columnsByMonth={columnsByMonth}
                startingGridColumns={startingGridColumns}
                endingGridColumns={endingGridColumns}
                yearsCount={props.years}
                yearStart={props.yearStart}
            />
            {/* ------------------------------------------------- */}
            <DndcPhases
                gridTemplateColumns={gridTemplateColumns}
                dndcCroppingData={props.dndcCroppingData}
                monthsBeforeStart={props.monthsBeforeStart}
                yearStart={props.yearStart}
                hasFieldsData={!!props.fieldsCroppingData}
            />
            {/* ------------------------------------------------- */}
            <MscFieldsCropping
                gridTemplateColumns={gridTemplateColumns}
                monthsBeforeStart={props.monthsBeforeStart}
                monthsAfterEnd={props.monthsAfterEnd}
                yearStart={props.yearStart}
                fieldsCroppingData={props.fieldsCroppingData}
            />
        </div>
    );
};

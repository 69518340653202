/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';

export const createFetcher = <RespT, ParamsT extends Record<string, string | number | number[] | undefined>>(
    urlBuilder: (/* params: ParamsT */) => string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataTransform = (data: any) => data as RespT,
) => {
    return function (defaultSearchParams?: ParamsT, options?: { lazy?: boolean }) {
        const _options = { lazy: true, ...options };
        const [data, setData] = useState<RespT | null>(null);
        const [loading, setLoading] = useState(_options.lazy ? false : true);
        const [loaded, setLoaded] = useState(false);
        const [error, setError] = useState<string | null>(null);
        const [fetching, setFetching] = useState(false);

        const _fetch = useCallback(async (searchParams: ParamsT, abortSignal?: AbortSignal) => {
            const _searchParams = { ...defaultSearchParams, ...searchParams };
            setError(null);
            setFetching(true);
            try {
                const cleanParams = Object.fromEntries(
                    Object.entries(_searchParams)
                        .reduce((acc, [key, value]) => {
                            const arrayParams = Array.isArray(value) ? [key, value.join(',')] : [key, value];
                            return [...acc, arrayParams] as any;
                        }, [])
                        .filter(([, value]: any) => value !== '' && value !== undefined),
                );

                const queryParams = new URLSearchParams(cleanParams).toString();
                const queryParamSeparator = queryParams ? '?' : '';
                const url = urlBuilder(/* _searchParams */);

                const res = await fetch(`${url}${queryParamSeparator}${queryParams}`, {
                    credentials: 'include',
                    signal: abortSignal,
                });
                if (!res.ok) {
                    throw new Error(`Response status: ${res.status}`);
                }
                const response = dataTransform(await res.json());
                setData(() => response);
                setLoaded(true);

                setLoading(false);
                setFetching(false);
                return response;
            } catch (e: unknown) {
                console.error(e);
                setData(() => null);
                if (e instanceof Error) {
                    setError(e.message);
                }
                setLoading(false);
                setFetching(false);
                return null;
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            const controller = new AbortController();

            if (_options.lazy === false) {
                if (!defaultSearchParams) {
                    console.warn('fetcher is not lazy, it should fetch but no defaultSearchParams provided');
                    return;
                }
                _fetch(defaultSearchParams, controller.signal);
            }
            return () => {
                controller.abort('unmount component, fetch aborted');
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return useMemo(
            () => ({ data, loading, loaded, error, fetch: _fetch, fetching }),
            [_fetch, data, error, fetching, loaded, loading],
        );
    };
};

import { MapLayerListT } from '../MapLayers';
``;
export const invalidFieldsLayers: MapLayerListT = [
    {
        id: 'invalid-feature-fill',
        type: 'fill',
        paint: {
            'fill-color': '#F10D0D',
            'fill-opacity': ['case', ['boolean', ['feature-state', 'highlighted'], false], 0.9, 0.6],
        },
    },
    {
        id: 'invalid-feature-line',
        type: 'line',
        paint: {
            'line-color': '#F10D0D',
            'line-width': 1.5,
        },
    },
    {
        id: 'invalid-feature-text',
        type: 'symbol',
        paint: {
            'text-color': 'white',
            'text-halo-color': 'black',
            'text-halo-width': 1,
        },
        layout: {
            'text-field': ['get', 'label'],
            'text-size': 12,
            'text-font': ['literal', ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
            'text-anchor': 'center',
            'text-max-width': 10,
        },
    },
];

import { ConditionalTooltip, Input } from '@soil-capital/ui-kit/components';
import { Grid, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { BANK_ACCOUNT, useUpdateBankAccountFormLogic } from './useUpdateBankAccountFormLogic';
import { FormProvider } from 'react-hook-form';
import { CompanyT } from '@modules/billing/api/userCompany.types';
import { useEffect } from 'react';
import { formatSortCode } from '@modules/billing/utils/formatSortCode';

export type UpdateIbanFormProps = {
    refetch: () => void;
    setOnConfirm: (confirmFn: () => void) => void;
    userCompany?: CompanyT;
    setDisableContinue?: (disable: boolean) => void;
    setIsSubmitting?: (isSubmitting: boolean) => void;
    nextAction: () => void;
};

export const UpdateBankAccountForm = ({
    refetch,
    setOnConfirm,
    userCompany,
    setIsSubmitting,
    setDisableContinue,
    nextAction,
}: UpdateIbanFormProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { methods, canEditBankAccount, errors, submitForm, isFromGreatBritain, isFromIreland, sortCode } =
        useUpdateBankAccountFormLogic(refetch, t, nextAction, userCompany, setIsSubmitting, setDisableContinue);

    useEffect(() => {
        setOnConfirm(submitForm);
    }, [setOnConfirm, submitForm]);

    return (
        <FormProvider {...methods}>
            <form>
                <Grid
                    display="flex"
                    flexDirection="column"
                    paddingX={theme.spacing(3)}
                    gap={theme.spacing(1)}
                    paddingBottom="200px"
                >
                    {isFromIreland || isFromGreatBritain ? (
                        <>
                            <Grid display="flex" gap={theme.spacing(1)}>
                                <Grid width={'100%'}>
                                    <Grid display="flex" gap={theme.spacing(1)} alignContent={'center'}>
                                        <Typography variant="caption">
                                            {t('billing.subscription.billing-form.account-number').toUpperCase()}
                                        </Typography>
                                        <ConditionalTooltip
                                            text={t('billing.subscription.billing-form.account-number-tooltip')}
                                        />
                                    </Grid>
                                    <Input
                                        label=""
                                        placeholder={t('billing.subscription.billing-form.account-number.placeholder')}
                                        inputProps={{
                                            ...methods.register(BANK_ACCOUNT.ACCOUNT_NUMBER),
                                        }}
                                        type="text"
                                        disabled={!canEditBankAccount}
                                        fullWidth
                                    />
                                    {errors[BANK_ACCOUNT.ACCOUNT_NUMBER]?.message && (
                                        <Typography variant="caption" color="error">
                                            {t(`${errors[BANK_ACCOUNT.ACCOUNT_NUMBER]?.message}`)}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid width={'100%'}>
                                    <Grid display="flex" gap={theme.spacing(1)} alignContent={'center'}>
                                        <Typography variant="caption">
                                            {t('billing.subscription.billing-form.sort-code').toUpperCase()}
                                        </Typography>
                                        <ConditionalTooltip
                                            text={t('billing.subscription.billing-form.sort-code-tooltip')}
                                        />
                                    </Grid>
                                    <Input
                                        label=""
                                        placeholder={t('billing.subscription.billing-form.sort-code.placeholder')}
                                        value={formatSortCode(sortCode ?? '')}
                                        inputProps={{
                                            ...methods.register(BANK_ACCOUNT.SORT_CODE),
                                        }}
                                        type="text"
                                        disabled={!canEditBankAccount}
                                        fullWidth
                                    />
                                    {errors[BANK_ACCOUNT.SORT_CODE]?.message && (
                                        <Typography variant="caption" color="error">
                                            {t(`${errors[BANK_ACCOUNT.SORT_CODE]?.message}`)}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Typography variant="subcaption" color={theme.palette.darkScale[700]}>
                                {t('billing.subscription.billing-form.bank-account-description')}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Grid display="flex" gap={theme.spacing(1)}>
                                <Typography variant="caption">
                                    {t('billing.subscription.billing-form.IBAN').toUpperCase()}
                                </Typography>
                                <ConditionalTooltip text={t('billing.subscription.billing-form.IBAN-tooltip')} />
                            </Grid>
                            <Input
                                label=""
                                placeholder={t('billing.subscription.billing-form.IBAN.placeholder')}
                                inputProps={{
                                    ...methods.register(BANK_ACCOUNT.IBAN),
                                }}
                                type="text"
                                disabled={!canEditBankAccount}
                            />
                            {errors[BANK_ACCOUNT.IBAN]?.message && (
                                <Typography variant="caption" color="error">
                                    {t(`${errors[BANK_ACCOUNT.IBAN]?.message}`)}
                                </Typography>
                            )}
                            <Typography variant="subcaption" color={theme.palette.darkScale[700]}>
                                {t('billing.subscription.billing-form.IBAN-description')}
                            </Typography>
                        </>
                    )}
                </Grid>
            </form>
        </FormProvider>
    );
};

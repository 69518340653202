export const dndcOperationTypes = {
    fertilizer: {
        color: '#039BE5',
    },

    reduction: {
        color: '#ff5252',
    },
    till: {
        color: '#a1887f',
    },
};

/* eslint-disable consistent-return,func-names,no-param-reassign,no-multi-assign,padding-line-between-statements,one-var,spaced-comment,no-plusplus */
// modified version of mapbox-gl-draw-snap mode by Clément for V2 adapted for V3
// source: https://github.com/mhsattarian/mapbox-gl-draw-snap-mode

import { geojsonTypes, modes, cursors } from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon';
import { createSnapList, snap, snapOptions } from './snap.js';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';

const DrawPolygonWithSnap = { ...DrawPolygon };

DrawPolygonWithSnap.onSetup = function (opts) {
    const polygon = this.newFeature({
        type: geojsonTypes.FEATURE,
        properties: {},
        geometry: {
            type: geojsonTypes.POLYGON,
            coordinates: [[]],
        },
    });
    this.addFeature(polygon);

    // clear selected features
    const selectedFeatures = this.getSelected();
    this.clearSelectedFeatures();
    doubleClickZoom.disable(this);

    // init state
    const state = {
        map: this.map,
        options: this._ctx.options,
        polygon,
        fieldPolygonsLayer:
            opts?.fieldPolygonsLayer ?? this.map.getSource('fieldPolygonsLayer')?._options?.data?.features ?? [],
        farmBoundaries: opts?.farmBoundaries ?? [],
        selectedFeatures,
        currentVertexPosition: 0,
    };

    // snapping options
    state.options.snap = snapOptions.SNAP;
    state.options.snapOptions = {
        snapPx: snapOptions.SNAP_PX,
        snapToMidPoints: snapOptions.SNAP_TO_MID_POINTS,
        snapVertexPriorityDistance: snapOptions.SNAP_VERTEX_PRIORITY_DISTANCE,
    };

    // create a snap list
    state.snapList = createSnapList(this.map, state.fieldPolygonsLayer, polygon);
    const farmBoundaries =
        opts?.farmBoundaries && opts.farmBoundaries.features
            ? opts.farmBoundaries.features
            : opts?.farmBoundaries || [];
    if (farmBoundaries) {
        state.snapList = state.snapList.concat(farmBoundaries);
    }

    const moveEndCallback = () => {
        state.snapList = createSnapList(this.map, state.fieldPolygonsLayer, polygon);
    };
    const optionsChangedCallBack = (newOptions) => {
        state.options = newOptions;
    };
    state['moveEndCallback'] = moveEndCallback;
    state['optionsChangedCallBack'] = optionsChangedCallBack;
    this.map.on('moveend', moveEndCallback);
    this.map.on('draw.snap.options_changed', optionsChangedCallBack);

    return state;
};

DrawPolygonWithSnap.onClick = function (state, e) {
    // We save some processing by rounding on click, not mousemove
    const lng = state.snappedLng;
    const lat = state.snappedLat;

    // End the drawing if this click is on the previous position
    if (state.currentVertexPosition > 0) {
        const lastVertex = state.polygon.coordinates[0][state.currentVertexPosition - 1];
        state.lastVertex = lastVertex;
        if (lastVertex[0] === lng && lastVertex[1] === lat) {
            return this.changeMode(modes.DIRECT_SELECT, { featureId: state.polygon.id });
        }
    }

    state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, lng, lat);
    state.currentVertexPosition++;
    state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, lng, lat);
};

DrawPolygonWithSnap.onMouseMove = function (state, e) {
    // SNAP!
    const { lng, lat } = snap(state, e);
    state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, lng, lat);
    state.snappedLng = lng;
    state.snappedLat = lat;

    if (state.lastVertex && state.lastVertex[0] === lng && state.lastVertex[1] === lat) {
        this.updateUIClasses({ mouse: cursors.POINTER });
    } else {
        this.updateUIClasses({ mouse: cursors.ADD });
    }
};

DrawPolygonWithSnap.onStop = function (state) {
    // remove callbacks
    this.map.off('moveend', state.moveEndCallback);
    this.map.off('draw.snap.options_changed', state.optionsChangedCallBack);

    DrawPolygon.onStop.call(this, state);
};

// customized version of DrawPolygon one, showing the starting point (snapped)
DrawPolygonWithSnap.toDisplayFeatures = function (state, geojson, display) {
    // Active polygon?
    const isActivePolygon = geojson.properties.id === state.polygon.id;
    geojson.properties.active = isActivePolygon ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;

    // Don't render a polygon until it has two positions
    // (and a 3rd which is just the first repeated)
    if (geojson.geometry.coordinates.length === 0) return;

    // First coordinates array length
    const coordinateCount = geojson.geometry.coordinates[0].length;

    // Show the starting point
    if (coordinateCount && geojson.geometry.coordinates[0][0]) {
        geojson.properties.meta = Constants.meta.FEATURE;
        display(createVertex(state.polygon.id, geojson.geometry.coordinates[0][0], '0.0', false));
    } else {
        return;
    }

    // Update following point of polygon
    if (coordinateCount > 3) {
        // Add a start position marker to the map, clicking on this will finish the feature
        // This should only be shown when we're in a valid spot
        const endPos = geojson.geometry.coordinates[0].length - 3;
        display(createVertex(state.polygon.id, geojson.geometry.coordinates[0][endPos], `0.${endPos}`, false));
    }

    if (coordinateCount <= 4) {
        // If we've only drawn two positions (plus the closer),
        // make a LineString instead of a Polygon
        const lineCoordinates = [
            [geojson.geometry.coordinates[0][0][0], geojson.geometry.coordinates[0][0][1]],
            [geojson.geometry.coordinates[0][1][0], geojson.geometry.coordinates[0][1][1]],
        ];
        // create an initial vertex so that we can track the first point on mobile devices
        display({
            type: Constants.geojsonTypes.FEATURE,
            properties: geojson.properties,
            geometry: {
                coordinates: lineCoordinates,
                type: Constants.geojsonTypes.LINE_STRING,
            },
        });
        if (coordinateCount === 3) {
            return;
        }
    }

    // render the Polygon
    if (coordinateCount <= 1) return;
    return display(geojson);
};

export default DrawPolygonWithSnap;

import { auth } from '@modules/auth';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetFarmer } from '@shared/hooks/useGetFarmer';
import { useGetFarmPaymentStatusQuery } from './api/paymentApi';
import { useEffect, useState } from 'react';
import { useIsRenewalDisabled } from '@shared/hooks/useIsRenewalDisabled';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';

export const usePaymentLogic = () => {
    // To avoid loading the page when the user is in the confirmation page/processing page
    const hasToLoad =
        !location.pathname.includes('confirmation') &&
        !location.pathname.includes('net-off') &&
        !location.pathname.includes('processing');
    const [isLoading, setIsLoading] = useState(hasToLoad);
    const hasPermission = auth.usePermission('canMakePayment');
    const { currentSeason } = useCurrentSeason();
    const { currentSeasonId } = useCurrentSeasonId();
    const { farmer, isLoading: isFarmerLoading } = useGetFarmer();
    const { data: farmPaymentStatus, isLoading: isFarmPaymentStatusLoading } = useGetFarmPaymentStatusQuery({
        farmId: currentSeason?.farm_id,
    });
    const {
        data: partnerInfo,
        isLoading: isPartnerInfoLoading,
        isError: hasPartnerInfoQueryFailed,
    } = useGetPartnerInfoQuery({ userId: farmer?.id });
    const { isRenewalDisabled } = useIsRenewalDisabled(currentSeasonId, !!partnerInfo?.id, currentSeason?.farm_id);

    useEffect(() => {
        if (!isFarmPaymentStatusLoading && currentSeason && !isFarmerLoading && farmPaymentStatus && farmer) {
            setIsLoading(false);
        }
    }, [isFarmPaymentStatusLoading, currentSeason, isFarmerLoading, farmer, farmPaymentStatus]);

    return {
        isLoading: isLoading || isPartnerInfoLoading,
        canMakePayment:
            (farmPaymentStatus?.status === 'deferred' ||
                (farmPaymentStatus?.status === 'to_be_renewed' && !hasPartnerInfoQueryFailed) ||
                farmPaymentStatus?.status === 'net_off') &&
            !isRenewalDisabled,
        isPartnerPrepaying: !!partnerInfo?.is_partner_prepaying,
        hasPermission,
        currentSeasonId: currentSeason?.id,
    };
};

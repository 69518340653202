import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { FormRow } from '@shared/components';
import { Input } from '@soil-capital/ui-kit/components';
import { FARM_PROFILE_INPUT_NAME } from '../useFarmProfileForm';
import { useFarmProfileDocumentsQuestion } from './FarmProfileDocumentsQuestion.logic';
import { useTranslation } from 'react-i18next';
import FarmProfileDocumentsQuestionStyle from './FarmProfileDocumentsQuestion.style';
import { FarmProfileMultiSelect } from '../FarmProfileMultiSelect/FarmProfileMultiSelect';
import { FarmProfileStaticDataT } from '../../../api/farmProfileApi.types';
import { UseFormReturn } from 'react-hook-form';
import { FarmProfileFormData } from '../useFarmProfileForm';
export const FarmProfileDocumentsQuestion = ({
    methods,
    onSubmit,
    fmisOptions,
}: {
    methods: UseFormReturn<FarmProfileFormData>;
    onSubmit: () => void;
    fmisOptions: FarmProfileStaticDataT[];
}) => {
    const { isOtherVisible, isFmisvisible, isOtherFmisVisible } = useFarmProfileDocumentsQuestion();
    const { t } = useTranslation();
    const { classes } = FarmProfileDocumentsQuestionStyle();

    return (
        <>
            {isOtherVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.document-other')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.document-other"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.document-other"
                    />
                    <div className={classes.input}>
                        <Input
                            name={FARM_PROFILE_INPUT_NAME.DOCUMENT_OTHER}
                            placeholder={t('encoding-farm-profile.question.document-other.placeholder')}
                            onChange={() => onSubmit()}
                            inputProps={{
                                ...methods.register(FARM_PROFILE_INPUT_NAME.DOCUMENT_OTHER),
                            }}
                            fullWidth
                        />
                    </div>
                </FormRow>
            )}
            {isFmisvisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.documents.fmis')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.documents.fmis"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.documents.fmis"
                    />
                    <FarmProfileMultiSelect
                        name={FARM_PROFILE_INPUT_NAME.DOCUMENTS_FMIS}
                        onChange={() => onSubmit()}
                        options={fmisOptions}
                        className={classes.input}
                    />
                </FormRow>
            )}
            {isOtherFmisVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.document-other')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.document-other"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.document-other"
                    />
                    <div className={classes.input}>
                        <Input
                            name={FARM_PROFILE_INPUT_NAME.DOCUMENT_FMIS_OTHER}
                            placeholder={t('encoding-farm-profile.question.document-other.placeholder')}
                            onChange={() => onSubmit()}
                            inputProps={{
                                ...methods.register(FARM_PROFILE_INPUT_NAME.DOCUMENT_FMIS_OTHER),
                            }}
                            fullWidth
                        />
                    </div>
                </FormRow>
            )}
        </>
    );
};

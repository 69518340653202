import { fieldSelectionDuplicationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import { MapLayerCallbackFeatureDataT } from '@shared/map';
import { SharedStateT } from '@shared/store';
import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OPERATION_DUPLICATION_INPUT_NAMES } from '../../../shared/hooks/useOperationDuplicationForm';
import { useFormContext } from 'react-hook-form';
import { type DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';

export const useOperationDuplicationRightPanelLogic = (
    sourceField: DuplicationFieldT,
    targetFields: DuplicationFieldT[],
) => {
    const { setValue } = useFormContext();

    const sourceFieldId = useMemo(() => sourceField.id, [sourceField]);
    const allFields = useMemo(() => targetFields.concat(sourceField), [targetFields, sourceField]);
    const polygons = allFields.map((field) => field.polygon);

    const dispatch = useDispatch();
    const fieldIdHovered = useSelector((state: SharedStateT) => state.fieldSelectionDuplication.hoveredId);
    const fieldIdsFocused = useSelector((state: SharedStateT) => state.fieldSelectionDuplication.focusedIds);
    const selectedFieldIds = useSelector((state: SharedStateT) => state.fieldSelectionDuplication.selectedIds);

    const onFeatureClick = useCallback(
        (data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>) => {
            if (data?.featureId) {
                if (data.featureId === sourceFieldId) {
                    return false;
                }
                const isAlreadySelected = selectedFieldIds.includes(data.featureId);

                const updatedSelectedIds = isAlreadySelected
                    ? selectedFieldIds.filter((id) => id !== data.featureId)
                    : [...selectedFieldIds, data.featureId];

                dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds(updatedSelectedIds));
            }
        },
        [dispatch, selectedFieldIds, sourceFieldId],
    );
    const onFeatureHover = useCallback(
        (data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>) => {
            if (data?.featureId === sourceFieldId) {
                return false;
            }
            dispatch(fieldSelectionDuplicationSlice.actions.setHoveredFieldId(data?.featureId ?? null));
        },
        [dispatch, sourceFieldId],
    );

    const mapLayerSource = useMemo(() => {
        return {
            type: 'FeatureCollection',
            features: allFields.map((field) => ({
                ...field.polygon,
                properties: {
                    color: sourceField.id === field.id ? '#808080' : '#fff',
                    label: field.name,
                    'pattern-image': field.has_agroforestry ? 'trees' : null,
                },
            })),
        } as const;
    }, [allFields, sourceField.id]);

    useEffect(() => {
        const selectedFieldCropIds = selectedFieldIds.map((fieldId) => {
            const field = allFields.find((f) => f.id === fieldId);
            return field ? field.fieldCrop.id : undefined;
        });

        setValue(OPERATION_DUPLICATION_INPUT_NAMES.TARGET_FIELD_CROP_IDS, selectedFieldCropIds);
    }, [selectedFieldIds, setValue, allFields]);

    useEffect(() => {
        dispatch(fieldSelectionDuplicationSlice.actions.reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        allFields,
        dispatch,
        mapLayerSource,
        polygons,
        onFeatureClick,
        onFeatureHover,
        fieldIdHovered,
        fieldIdsFocused,
        selectedFieldIds,
    };
};

import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { encodingShared } from '@modules/encoding/shared';
import { useToggle } from '@shared/hooks/useToggle';
import { useIntercom } from 'react-use-intercom';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import { useGetGeometryDatas } from '@shared/hooks/useGetGeometryData';
import entities from '@shared/entities';

const INTERCOM_ARTICLES = {
    BE: 5671138,
    FR: 8907756,
    GB: 8866250,
} as const;

export const usePacImportHeaderLogic = () => {
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { harvestYear } = useGetCurrentHarvestYear();
    const { fieldState } = useGetFarmSeasonFields();
    const { geometryDataState } = useGetGeometryDatas();
    const [deleteAllInvalidField, { isLoading }] = entities.geometryData.useDeleteAll();
    const numberOfInvalidFields = geometryDataState.list.length;
    const numberOfFields = fieldState.list.length;
    const hasFields = numberOfFields > 0;
    const { currentFarm } = useCurrentFarm();
    const { showArticle } = useIntercom();

    const { open: pacFileModalOpen, handleOpen: onPacFileModalOpen, handleClose: onPacFileModalClose } = useToggle();

    const handleOpenIntercomArticle = () => {
        showArticle(
            currentFarm?.country?.iso_code && currentFarm.country.iso_code in INTERCOM_ARTICLES
                ? INTERCOM_ARTICLES[currentFarm.country.iso_code as keyof typeof INTERCOM_ARTICLES]
                : INTERCOM_ARTICLES['FR'],
        );
    };

    const handleDeleteInvalidFields = async () => {
        await deleteAllInvalidField({ farmSeasonId: currentSeasonId });
    };

    return {
        currentSeasonId,
        harvestYear,
        hasFields,
        isFetchingFields: fieldState.isFetching,
        numberOfFields,
        numberOfInvalidFields,
        pacFileModalOpen,
        onPacFileModalOpen,
        onPacFileModalClose,
        handleOpenIntercomArticle,
        handleDeleteInvalidFields,
        isDeleteInvalidFieldsLoading: isLoading,
        isLoadingInvalidFields: geometryDataState.isLoading,
    };
};

import { useWatch, useFormContext, useFormState } from 'react-hook-form';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { useEffect, useState } from 'react';
import { COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS } from '../coverCrop.types';
import { useGetCoverCropFormStaticDataByTypeQuery } from '../../../api/coverCropFormApi';
import { COVER_CROP_STATIC_DATA_TYPE } from '../../../api/coverCropFormApi.types';

export const useCoverCropDestructionMethodQuestion = () => {
    const destructionDate = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DATE });
    const { setValue } = useFormContext();
    const { dirtyFields } = useFormState();
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const destructionMethodId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_METHOD_ID }) as number;
    const { data: destructionMethods } = useGetCoverCropFormStaticDataByTypeQuery({
        type: COVER_CROP_STATIC_DATA_TYPE.COVER_CROP_DESTRUCTION,
    });

    const destructionMethodSlug = destructionMethods?.find((el) => el.id === destructionMethodId)
        ?.slug as (typeof COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS)[keyof typeof COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS];
    useEffect(() => {
        if (destructionDate === null && dirtyFields[COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DATE]) {
            setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_METHOD_ID, null);
        }
    }, [destructionDate, setValue, dirtyFields]);

    useEffect(() => {
        if (destructionMethodSlug) {
            const relevantDestructionMethods = [
                COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_CHEMICAL,
                COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_MECHANICAL,
                COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_PLOUGHING,
            ] as const;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newValue = relevantDestructionMethods.includes(destructionMethodSlug as any);
            setShowWarning(newValue);
        }
    }, [destructionMethodId, destructionMethodSlug, destructionMethods]);

    return {
        isVisible: destructionDate !== null,
        showWarning,
    };
};

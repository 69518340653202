import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';
import { Stack, Tooltip } from '@soil-capital/ui-kit';
import { useIntroductionContentData } from '@modules/encoding/modules/technicalItinerary/pages/IntroductionContentPage/useIntroductionContentData';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { useTranslation } from 'react-i18next';

export const TechnicalItineraryCropList = () => {
    const { cropDataList, handleCropClick, fieldCropsWithUntouchedDuplicatedITKIsFetching } =
        useIntroductionContentData();
    const { t } = useTranslation();

    return (
        <Stack spacing={1} mt={1}>
            {cropDataList.map((cropData) => (
                <IntroProgressItem
                    image={cropData.image}
                    key={cropData.cropId}
                    text={cropData.name}
                    progress={{
                        max: cropData.maxProgress,
                        current: cropData.currentProgress,
                        text: cropData.textProgress,
                    }}
                    endIcon={
                        <Tooltip
                            text={
                                cropData.hasOneDuplicatedFieldCropITKUntouched
                                    ? t('yoy.duplication.page.crop.field-have-duplication', {
                                          harvestYears: cropData.duplicationSourceHarvestYears,
                                      })
                                    : undefined
                            }
                        >
                            <IconLightning
                                sx={{
                                    visibility: cropData.hasOneDuplicatedFieldCropITKUntouched ? '' : 'hidden',
                                    opacity: fieldCropsWithUntouchedDuplicatedITKIsFetching ? 0.7 : 1,
                                }}
                            />
                        </Tooltip>
                    }
                >
                    {cropData.fieldDataList.length > 0 && (
                        <Stack spacing={1} mt={1}>
                            {cropData.fieldDataList.map((fieldData) => (
                                <IntroProgressItem
                                    key={fieldData.fieldId}
                                    onClick={() => handleCropClick(fieldData, cropData)}
                                    text={fieldData.name}
                                    infoText={fieldData.infoText}
                                    image={fieldData.image}
                                    progress={{
                                        max: fieldData.maxProgress,
                                        current: fieldData.currentProgress,
                                    }}
                                    endIcon={
                                        <Tooltip
                                            text={
                                                fieldData.hasITKFromDuplication
                                                    ? t('yoy.duplication.page.field.field-have-duplication', {
                                                          harvestYear: fieldData.duplicationSourceHarvestYear,
                                                      })
                                                    : undefined
                                            }
                                        >
                                            <IconLightning
                                                sx={{
                                                    visibility: fieldData.hasITKFromDuplication ? '' : 'hidden',
                                                    opacity: fieldCropsWithUntouchedDuplicatedITKIsFetching ? 0.7 : 1,
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            ))}
                        </Stack>
                    )}
                </IntroProgressItem>
            ))}
        </Stack>
    );
};

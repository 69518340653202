import { makeStyles } from '@soil-capital/ui-kit/style';

export const useOperationDuplicationListStyles = makeStyles()((theme) => ({
    periods: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    collapseToggle: {
        color: theme.palette.darkScale[700],
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    chevron: {
        transition: 'rotate 0.2s ease-in-out',
    },
    collapsedChevron: {
        rotate: '180deg',
    },
    collapse: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        color: theme.palette.darkScale[700],
    },
    selectAll: {
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));

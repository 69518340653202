export function sortFields<T>(fields: T[], sortOption?: string): T[] {
    if (!sortOption) return fields;

    const [sortKey, order] = sortOption.split(':') as [keyof T, 'asc' | 'dsc'];

    const sorted = [...fields].sort((a, b) => {
        if (sortKey === 'area') {
            const aStr = String(a[sortKey]).replace(',', '.');
            const bStr = String(b[sortKey]).replace(',', '.');

            const aNum = Number(aStr);
            const bNum = Number(bStr);
            return order === 'asc' ? aNum - bNum : bNum - aNum;
        } else {
            const aValue = String(a[sortKey]).toLowerCase();
            const bValue = String(b[sortKey]).toLowerCase();

            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        }
    });

    return sorted;
}

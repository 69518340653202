import { SidePanel } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useOperationDuplicationLeftPanelStyles } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/OperationDuplicationLeftPanel/OperationDuplicationLeftPanel.style';
import { useTranslation } from 'react-i18next';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { OperationDuplicationList } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/OperationDuplicationList/OperationDuplicationList';
import { formatNumber } from '@shared/utils';
import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { useDispatch } from 'react-redux';
import { fieldSelectionDuplicationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';

type OperationDuplicationLeftPanelProps = {
    sourceField: DuplicationFieldT;
};

export const OperationDuplicationLeftPanel = ({ sourceField }: OperationDuplicationLeftPanelProps) => {
    const { classes } = useOperationDuplicationLeftPanelStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    return (
        <SidePanel
            className={classes.panel}
            header={
                <SidePanel.Header>
                    <Typography variant="h3" mb={2}>
                        {t('encoding-technical-itinerary.duplication.modal.left-panel.title')}
                    </Typography>
                    <Typography color="neutral" variant="body2" mb={2}>
                        {t('encoding-technical-itinerary.duplication.modal.left-panel.description')}
                    </Typography>

                    <Typography color="neutral" className={classes.sourceFieldTitle} component="h4" mt={1}>
                        {t('encoding-technical-itinerary.duplication.modal.left-panel.source-field')}
                    </Typography>
                    <FieldCropInfoBox
                        className={classes.fieldCropInfos}
                        fieldName={sourceField.name}
                        fieldArea={`${formatNumber(sourceField.area, 2)} HA`}
                        cropNames={[
                            { id: sourceField.staticCrop.id, name: t(`db.crop.slug-${sourceField.staticCrop.slug}`) },
                        ]}
                        mapSnapshot={sourceField.snapshot}
                        handleFieldClick={() =>
                            dispatch(fieldSelectionDuplicationSlice.actions.setFocusedFieldIds([sourceField.id]))
                        }
                    />
                </SidePanel.Header>
            }
            body={
                <SidePanel.Body className={classes.panelBody}>
                    <OperationDuplicationList sourceField={sourceField} />
                </SidePanel.Body>
            }
        />
    );
};

import entities from '@shared/entities';
import getCropGroupImg from '../../utils/getCropGroupImg';
import { formatNumber } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import getTillageIconComponent from '../../utils/getTillageIconComponent';
import { FieldIdentityCardLogicPropsT } from './FieldIdentityCard.types';
import useCurrentSeasonId from '../../hooks/useCurrentSeasonId';
import { encodingShared } from '../..';
import { useEffect, useMemo } from 'react';
import { useGetPreviousFarmSeasonQuery } from '@modules/yoy/api/yoyApi';
import { useGetFieldOverviewQuery } from '@modules/encoding/api/FieldOverview/FieldOverviewApi';
import { skipToken } from '@reduxjs/toolkit/query';

export const useFieldIdentityCardLogic = ({ fieldCropId, setIsLoading }: FieldIdentityCardLogicPropsT) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { t } = useTranslation();

    /* ------------------------------- use states ------------------------------- */
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticCropGroupState } = entities.staticCropGroup.useState({ countryId: currentFarm?.country_id });
    const { staticTillagePracticeState } = entities.staticTillagePractice.useState();
    const { data: previousFarmSeason, isLoading: isLoadingPreviousFarmSeason } = useGetPreviousFarmSeasonQuery({
        farmSeasonId: currentSeasonId,
    });
    const { fieldState: previousFields } = entities.field.useState({ farmSeasonId: previousFarmSeason?.id });
    const { cropState: previousFarmSeasonCrops } = entities.crop.useState({ farmSeasonId: previousFarmSeason?.id });
    const { fieldCropState: previousFarmSeasonFieldCrops } = entities.fieldCrop.useState({
        farmSeasonId: previousFarmSeason?.id,
    });

    /* ---------------------------- get current data ---------------------------- */
    const fieldCrop = fieldCropState.getById(fieldCropId);
    const field = fieldState.getById(fieldCrop?.farm_season_field_id);
    const crop = cropState.getById(fieldCrop?.farm_season_crop_id);
    const staticCrop = staticCropState.getById(crop?.crop_id);
    const staticCropGroup = staticCropGroupState.getById(staticCrop?.crop_group_id);
    const companionCrop = staticCropState.getById(fieldCrop?.companion_crop_id);
    const tillage = staticTillagePracticeState.getById(fieldCrop?.tillage_id);

    /* ------------------------- compute view properties ------------------------ */
    const cropCroupImg = getCropGroupImg(staticCropGroup?.slug);
    const fieldSurfaceText = `${formatNumber(field?.area, 2)} HA`;
    const fieldName = field?.name ?? '';
    const cropName = t(staticCrop?.translation_slug ?? '');
    const companionCropName = t(companionCrop?.translation_slug ?? '');
    const tillageName = t(tillage?.translation_slug ?? '');
    const { TillageIconComponent } = getTillageIconComponent(tillage?.name);
    const organicText = fieldCrop?.is_organic ? t('constants.organic') : null;
    const { data: fieldOverview, isLoading: isFieldOverviewLoading } = useGetFieldOverviewQuery(
        field?.id != null ? { fieldId: field?.id, farmSeasonId: currentSeasonId } : skipToken,
    );
    const previousCropName = useMemo(() => {
        return fieldOverview?.find((fieldOverview) => fieldOverview.harvest_year === previousFarmSeason?.harvest_year)
            ?.crop_name;
    }, [fieldOverview, previousFarmSeason]);
    const translatedPreviousCropName = useMemo(() => {
        return previousCropName ? t(`db.crop.slug-${previousCropName}`) : null;
    }, [previousCropName, t]);

    const isLoading =
        cropState.isLoading ||
        currentFarmLoading ||
        fieldCropState.isLoading ||
        fieldState.isLoading ||
        staticCropGroupState.isLoading ||
        staticCropState.isLoading ||
        staticTillagePracticeState.isLoading ||
        isLoadingPreviousFarmSeason ||
        previousFields.isLoading ||
        previousFarmSeasonCrops.isLoading ||
        previousFarmSeasonFieldCrops.isLoading ||
        isFieldOverviewLoading;

    useEffect(() => {
        setIsLoading(isLoading);
    }, [setIsLoading, isLoading]);

    return {
        cropCroupImg,
        fieldSurfaceText,
        fieldName,
        cropName,
        companionCropName,
        tillageName,
        TillageIconComponent,
        organicText,
        translatedPreviousCropName,
    };
};

import useMapDrawStyle from './MapDrawingControl.style';
import { FeatureCollection, Geometry, GeometryCollection, Polygon, Properties } from '@turf/helpers';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { DrawField } from './components/DrawField/DrawField';
import { Dispatch, SetStateAction } from 'react';
import { FieldT } from '@shared/entities/field/field.types';
import { MergeField } from './components/MergeField/MergeField';
import { EditField } from './components/EditField/EditField';
import { SplitField } from './components/SplitField/SplitField';
import { GeometryDataT } from '@shared/entities';
import { MAPMODE, MapModeT } from '../PacImportMap/PacImportMap.logic';

interface MapDrawControlProps {
    readOnly: boolean;
    fields: FieldT[];
    invalidFields: GeometryDataT[];
    farmBoundaries: FeatureCollection<Geometry | GeometryCollection, Properties> | undefined;
    existingPolygons: Polygon[];
    mapMode: MapModeT;
    setMapMode: Dispatch<SetStateAction<MapModeT>>;
    isYoyFarmSeason: boolean;
}

export const MapDrawingControl = ({
    readOnly,
    fields,
    invalidFields,
    farmBoundaries,
    existingPolygons,
    mapMode,
    setMapMode,
    isYoyFarmSeason,
}: MapDrawControlProps) => {
    const { classes } = useMapDrawStyle();
    const invalidFieldsNumber = invalidFields.length;

    return (
        <Grid className={classes.container}>
            {(mapMode === MAPMODE.DRAW || mapMode === MAPMODE.NONE) && invalidFieldsNumber === 0 && (
                <DrawField
                    readOnly={readOnly}
                    farmBoundaries={farmBoundaries}
                    existingPolygons={existingPolygons}
                    invalidFieldsNumber={invalidFieldsNumber}
                    mapMode={mapMode}
                    setMapMode={setMapMode}
                    isDisabled={isYoyFarmSeason && !farmBoundaries}
                />
            )}

            {(mapMode === MAPMODE.EDIT || mapMode === MAPMODE.NONE) && (
                <EditField
                    mapMode={mapMode}
                    setMapMode={setMapMode}
                    readOnly={readOnly}
                    currentFields={fields}
                    farmBoundaries={farmBoundaries}
                    currentInvalidFields={invalidFields}
                    isYoyFarmSeason={isYoyFarmSeason}
                    isDisabled={isYoyFarmSeason && !farmBoundaries}
                />
            )}
            {(mapMode === MAPMODE.SPLIT || mapMode === MAPMODE.NONE) && invalidFieldsNumber === 0 && (
                <SplitField
                    readOnly={readOnly}
                    fields={fields}
                    invalidFields={invalidFields}
                    mapMode={mapMode}
                    setMapMode={setMapMode}
                    isYoyFarmSeason={isYoyFarmSeason}
                    isDisabled={isYoyFarmSeason && !farmBoundaries}
                />
            )}
            {(mapMode === MAPMODE.MERGE || mapMode === MAPMODE.NONE) && invalidFieldsNumber === 0 && (
                <MergeField
                    readOnly={readOnly}
                    invalidFields={invalidFields}
                    fields={fields}
                    mapMode={mapMode}
                    setMapMode={setMapMode}
                    isYoyFarmSeason={isYoyFarmSeason}
                    isDisabled={isYoyFarmSeason && !farmBoundaries}
                />
            )}
        </Grid>
    );
};

import { useTranslation } from 'react-i18next';
import useFieldCropSummaryActionPageStyle from '../FieldCropSummaryActionPage.style';
import { ScrollSection } from '@soil-capital/ui-kit/components';
import { IconTractor } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { TillageListT } from '../FieldCropSummaryActionPage.types';
import { formatNumber } from '@shared/utils';

export const TillageSummary = ({ tillageList }: { tillageList: TillageListT | undefined }) => {
    const { t } = useTranslation();
    const { classes } = useFieldCropSummaryActionPageStyle();

    return (
        <ScrollSection spacingX={2} className={classes.container}>
            <div className={classes.containerHeader}>
                <IconTractor data-testid="icon-tractor" />
                <Typography variant="caps">{t('encoding-technical-itinerary.field-crop-summary.soil-work')}</Typography>
            </div>
            {tillageList?.length && tillageList.length > 0 ? (
                <table className={classes.summaryTable}>
                    {tillageList?.map((tillage, index) => (
                        <tr key={index} className={classes.summaryRow}>
                            <td width={220}>
                                <Typography variant="body2">{tillage.machinery}</Typography>
                            </td>
                            <td width="100%">
                                <Typography variant="body2" className={classes.flex}>
                                    <span className={classes.depth}>
                                        {tillage.details?.map((detail) => formatNumber(detail.depth)).join('; ')}
                                    </span>
                                    <span className={classes.cm}> {t('constants.unit.cm')}</span>
                                </Typography>
                            </td>
                            <td width={160}>
                                <Typography variant="body2">
                                    {formatNumber(tillage.passes)}{' '}
                                    {tillage.passes === 1 ? t('constants.pass') : t('constants.pass_other')}
                                </Typography>
                            </td>
                        </tr>
                    ))}
                </table>
            ) : (
                <></>
            )}
        </ScrollSection>
    );
};

import { Map } from 'mapbox-gl';
import { useEffect } from 'react';

type UseSyncFeatureStatePropsT = {
    map: Map | null;
    dataSource: GeoJSON.FeatureCollection;
    sourceId: string;
    /** callback that must return the featureState properties to overwrite. Make sure to memoize it as it's part of useEffect deps */
    getFeatureStateCb: (feature: GeoJSON.Feature) => { [key: string]: unknown };
    sourceLayer?: string;
};

export const safeGetSource = (map: mapboxgl.Map, sourceId: string): mapboxgl.AnySourceImpl | null => {
    if (!map.isStyleLoaded()) {
        return null;
    }
    try {
        return map.getSource(sourceId);
    } catch (error) {
        console.error('Error in getSource:', error);
        return null;
    }
};
/**
 * Keep sync the featureState of the dataSource with the map and the 'getFeatureState callback'.
 * handle the potential map style reloads and safely apply the featureState updates. */
export const useSyncFeatureState = ({
    map,
    dataSource,
    sourceId,
    getFeatureStateCb,
    sourceLayer,
}: UseSyncFeatureStatePropsT) => {
    useEffect(() => {
        if (!map) return;

        const effect = () => {
            // If we can’t get the source now, exit. Avoid runtime errors.
            if (!safeGetSource(map, sourceId)) return;

            dataSource.features.forEach((feature) => {
                const params: { source: string; id: number | string | undefined; sourceLayer?: string } = {
                    source: sourceId,
                    id: feature.id,
                };
                if (sourceLayer) {
                    params.sourceLayer = sourceLayer;
                }
                map.setFeatureState(params, getFeatureStateCb(feature));
            });
        };

        if (!map.isStyleLoaded()) {
            // update featureStates will impact the style. We have to wait the style to be loaded to avoid errors.
            // idle fire after the last frame rendered. So when the style is loaded. see https://docs.mapbox.com/mapbox-gl-js/api/map/#map.event:idle
            map.on('idle', effect);
            return () => {
                map.off('idle', effect);
            };
        }

        effect();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, getFeatureStateCb]);
};

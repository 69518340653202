import { createFetcher } from './createFetcher';

export const createSearchFieldsFetcher = createFetcher<SearchFieldData[], SearchFieldParams>(
    () => `${process.env.REACT_APP_API_BASE_URL}/v3/cropping-view-search-fields`,
    (data) => {
        // console.log('SEARCH DATA', data);
        return data;
    },
);

export interface SearchFieldParams {
    [key: string]: string | number | number[] | undefined;
    field_ids?: string;
    field_info?: string;
    farmer_info?: string;
    limit?: number;
    offset?: number;
}

export interface SearchFieldData {
    farm_name: string;
    email: string;
    first_name: string;
    last_name: string;
    field_id: number;
    field_name: string;
    field_area: number;
    farm_season_id: number;
}

import { useDuplicationCropSelection } from '@modules/yoy/hooks/useDuplicationCropSelection';
import { YOY_DUPLICATION_ERRORS } from '@modules/yoy/hooks/useYoyDuplicationForm';
import { Button } from '@soil-capital/ui-kit';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@shared/hooks/useToggle';
import { YoyDuplicationOverwriteModal } from '@modules/yoy/components/YoyDuplicationOverwriteModal/YoyDuplicationOverwriteModal';
import { useFormContext } from 'react-hook-form';
import { useDuplicationSeasonSelection } from '@modules/yoy/hooks/useDuplicationSeasonSelection';

export const YoyDuplicationButton = ({
    onConfirm,
    isLoading,
}: {
    onConfirm: () => Promise<void>;
    isLoading: boolean;
}) => {
    const { t } = useTranslation();
    const { selectedCropsHaveDuplication, allowDuplication } = useDuplicationCropSelection();
    const { open, handleOpen, handleClose } = useToggle();
    const { setError } = useFormContext();
    const { selectedSeason, targetSeasonIsYoySeason } = useDuplicationSeasonSelection();

    const handleClicked = () => {
        if (!selectedSeason)
            return setError('source_season_id', {
                type: 'required',
                message: t(YOY_DUPLICATION_ERRORS.SOURCE_SEASON_ID_REQUIRED),
            });
        handleOpen();
    };

    const handleConfirm = async () => {
        await onConfirm();
        handleClose();
    };

    return (
        <>
            <Button
                onClick={handleClicked}
                variant="contained"
                loading={isLoading}
                disabled={!allowDuplication || isLoading}
            >
                <IconLightning />
                <Typography>{t('yoy.duplication.page.footer.action')}</Typography>
            </Button>
            <YoyDuplicationOverwriteModal
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirm}
                harvestYear={selectedSeason?.harvest_year as number}
                selectedCropsHaveDuplication={selectedCropsHaveDuplication}
                isDuplicating={isLoading}
                isYoySeason={targetSeasonIsYoySeason}
            />
        </>
    );
};

import { Input, Modal, ConditionalTooltip, NumberInput } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { useDrawnFieldCreationModalStyles } from './useDrawnFieldCreationModal.style';
import { DRAWN_FIELD_CREATION, useDrawnFieldCreationModalFormLogic } from './useDrawnFieldCreationModalFormLogic';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Polygon } from '@turf/helpers';

export type FieldsCreationModalPropsT = {
    polygon: Polygon;
    area: number;
    open: boolean;
    onClose: () => void;
    type?: 'empty-spot' | 'drawn';
    invalidFieldId?: number | null;
    name?: string | null;
};

export const DrawnFieldsCreationModal = ({
    polygon,
    area,
    open,
    onClose,
    type = 'drawn',
    invalidFieldId,
    name,
}: FieldsCreationModalPropsT) => {
    const { classes } = useDrawnFieldCreationModalStyles();
    const { t } = useTranslation();
    const { methods, onConfirm, isCreatingField, errors, treshold, inputRef } = useDrawnFieldCreationModalFormLogic({
        polygon,
        area,
        onClose,
        open,
        type,
        invalidFieldId,
        name,
    });

    return (
        <FormProvider {...methods}>
            <form>
                <Modal
                    size={'small'}
                    open={open}
                    onClose={onClose}
                    onConfirm={onConfirm}
                    header={
                        <Modal.Header>
                            <Modal.HeaderTitle>{t('encoding-rotation.field-creation-modal.title')}</Modal.HeaderTitle>
                            <Modal.Close />
                        </Modal.Header>
                    }
                    body={
                        <Modal.Body className={classes.body}>
                            <Input
                                label={t('constants.name')}
                                data-testid="field_name"
                                type="text"
                                inputProps={{
                                    ...methods.register(DRAWN_FIELD_CREATION.NAME),
                                }}
                                value={methods.watch(DRAWN_FIELD_CREATION.NAME) || ''}
                                inputRef={inputRef}
                                placeholder={name ?? ''}
                            />
                            {errors.field_name && (
                                <Typography color="error" className={classes.error}>
                                    {errors.field_name.message}
                                </Typography>
                            )}
                            <div className={classes.container}>
                                <div className={classes.label}>
                                    <Typography variant="body" color="darkScale.900">
                                        {t('constants.area').toUpperCase()}
                                    </Typography>
                                    <ConditionalTooltip
                                        title={t('encoding.rotation.map.draw.area-tooltip', { treshold })}
                                        scheme="dark"
                                        display="flex"
                                    />
                                </div>
                                <NumberInput
                                    min={0}
                                    incrementationStep={1}
                                    allowedDecimals={2}
                                    value={methods.watch(DRAWN_FIELD_CREATION.AREA) || 0}
                                    onChange={(value) => {
                                        methods.setValue(DRAWN_FIELD_CREATION.AREA, value || 0);
                                    }}
                                    suffix={t('constants.hectares')}
                                    placeholder={area.toString()}
                                />

                                {errors.area ? (
                                    <Typography color="error" className={classes.description}>
                                        {errors.area.message}
                                    </Typography>
                                ) : (
                                    <Typography color="GrayText" className={classes.description}>
                                        {t('encoding-rotation.map.draw.area-description', { treshold })}
                                    </Typography>
                                )}
                            </div>
                        </Modal.Body>
                    }
                    footer={
                        <Modal.Footer>
                            <Modal.Confirm loading={isCreatingField}>{t('constants.save')}</Modal.Confirm>
                            <Modal.Cancel onClick={onClose}>{t('constants.cancel')}</Modal.Cancel>
                        </Modal.Footer>
                    }
                />
            </form>
        </FormProvider>
    );
};

import { InferType } from 'yup';
import { MakeUndefinedPartial } from '@shared/utils';
import seasonSchema from './season.schema';
import { BaseEntityT } from '../baseEntity.types';
import { ProductPlanStatusT, ProductPlanT } from '@modules/billing/api/billing.types';

export enum AUDIT_STATUS {
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
}

export type SeasonT = {
    farm_id: number;
    season_id: number;
    name: string;
    iban: string;
    baseline_farm_season_id: number | null;
    /** @deprecated */
    partner_code: string;
    /** @deprecated */
    partner_type: 0 | 1 | 2 | 3 | 4;

    // flags
    has_results_published: boolean | null;
    has_completed_self_encoding: boolean;
    has_results: boolean;
    has_results_access: boolean;
    has_certificates: boolean;
    audit_status: null | 'in_progress' | 'done';

    // flow related
    installation_validated_at: string | null;
    installation_validated_by: number | null;
    summary_validated_at: string | null;
    summary_validated_by: number | null;
    operations_validated_by: number | null;
    operations_validated_at: string | null;

    // eligibility
    eligibility_area: number;
    eligibility_has_livestock: boolean;

    // Payment related
    one_payment: boolean | null;
    first_invoice_status: 'paid' | 'unpaid' | null;
    second_invoice_status: 'paid' | 'unpaid' | null;

    // Relations
    farm_boundaries?: string;
    farm_boundaries_mask?: string;
    carbon_programme_year: number | null;
    // agronomist?: string;

    // audit
    created_at?: string;
    harvest_year?: number;
    latest_farm_season_product_plan: {
        status: ProductPlanStatusT | null;
        paid_at: string | null;
        product_plan: { name: ProductPlanT };
    };
    status: SeasonStatusT;
    has_seen_results_available_msg: boolean;
} & BaseEntityT;

export const FARM_SEASON_STATUS = {
    DATA_INPUT_NOT_STARTED: 'DATA_INPUT_NOT_STARTED',
    DATA_INPUT_IN_PROGRESS: 'DATA_INPUT_IN_PROGRESS',
    WAITING_FOR_RESULTS: 'WAITING_FOR_RESULTS',
    RESULTS_AVAILABLE: 'RESULTS_AVAILABLE',
    RESULTS_PUBLISHED: 'RESULTS_PUBLISHED',
    DATA_INPUT_VALIDATED: 'DATA_INPUT_VALIDATED',
};

export type SeasonStatusT = keyof typeof FARM_SEASON_STATUS;

export type SeasonBodyT = MakeUndefinedPartial<InferType<typeof seasonSchema>>;

export type SeasonEndpointParamsT = {
    farmId: number | undefined;
};

export type TotalAreaCheckT = {
    has_user_area_with_pasture_increased: boolean;
    total_user_area_with_pasture_diff_ha: number;
    total_user_area_with_pasture_diff_percent: number;
    previous_season_user_area_with_pasture: number;
    previous_season_original_area_with_pasture: number;
    current_season_user_area_with_pasture: number;
    current_season_original_area_with_pasture: number;
    baseline_user_area_with_pasture: number;
    check_area_grow_passed: boolean;
};

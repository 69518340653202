import api from '@shared/api';
import { CompanyT, PrepaidUserCompanyBodyT, UserCompanyBodyT, AccountDetailsBodyT } from './userCompany.types';

export const userCompanyApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserCompany: builder.query<CompanyT, { farmId: number }>({
            query: ({ farmId }) => ({
                url: `/v3/farms/${farmId}/user-company`,
                method: 'GET',
            }),
            providesTags: ['UserCompany'],
        }),
        postUserCompany: builder.mutation<CompanyT, { farmId: number; company: UserCompanyBodyT }>({
            query: ({ farmId, company }) => ({
                url: `/v3/farms/${farmId}/user-company`,
                method: 'POST',
                body: company,
            }),
            invalidatesTags: ['UserCompany'],
        }),
        putUserCompany: builder.mutation<CompanyT, { farmId: number; companyId: number; company: UserCompanyBodyT }>({
            query: ({ farmId, companyId, company }) => ({
                url: `/v3/farms/${farmId}/user-company/${companyId}`,
                method: 'PUT',
                body: company,
            }),
            invalidatesTags: ['UserCompany', 'OnboardingTAC'],
        }),
        putBankAccount: builder.mutation<
            CompanyT,
            { farmId: number; companyId: number; accountDetails: AccountDetailsBodyT }
        >({
            query: ({ farmId, companyId, accountDetails }) => ({
                url: `/v3/farms/${farmId}/user-company/${companyId}/bank-account`,
                method: 'PUT',
                body: accountDetails,
            }),
        }),
        postPartnerPrepaidUserCompany: builder.mutation<CompanyT, { farmId: number; company: PrepaidUserCompanyBodyT }>(
            {
                query: ({ farmId, company }) => ({
                    url: `/v3/farms/${farmId}/partner-prepaid-user-company`,
                    method: 'POST',
                    body: company,
                }),
                invalidatesTags: ['UserCompany'],
            },
        ),
    }),
});

export const {
    usePostUserCompanyMutation,
    usePutUserCompanyMutation,
    usePutBankAccountMutation,
    usePostPartnerPrepaidUserCompanyMutation,
} = userCompanyApi;

export const useGetUserCompanyQuery = (params: { farmId: number | undefined }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return userCompanyApi.useGetUserCompanyQuery({ farmId: params.farmId! }, { skip: !params.farmId });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { CroppingViewData } from '@modules/croppingView/api/dndcCroppingFetcher';
import { useMemo } from 'react';
import { computeGridFromDate, getGridDayRatio } from '../utils/computeGridFromDate';
import { makeStyles } from '@soil-capital/ui-kit/style';
import { dndcOperationTypes } from '@modules/croppingView/constants/dndc-operation-types';
import { Tooltip } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { sortByUniques } from '../utils/sortByUniques';
import { GridStatus } from '../../GridStatus/GridStatus';
import { IconWarning } from '@soil-capital/ui-kit/icons';
import { mscCropTypes } from '@modules/croppingView/constants/msc-crop-types';

const useStyle = makeStyles()(() => ({
    phase: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '-13px',
            left: '-3px',
            width: '2px',
            height: '1000px',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
    },
    lastPhase: {
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '-13px',
            right: '-3px',
            width: '2px',
            height: '1000px',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
    },
    event: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '12px', // height of the event dot, to avoid blocking the tooltip on hover
            left: '50%',
            width: '1px',
            pointerEvents: 'none',
            height: '1000px',
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        },
    },
    cropName: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingLeft: '5px',
        fontFamily: 'BrownStd-Regular',
        display: 'block',
        userSelect: 'none',
    },
}));

const TooltipContent = ({ date, type, depth, name }: { type: string; date: string; depth?: string; name?: string }) => {
    return (
        <div className="h-full w-full">
            <Typography variant="body">{type}</Typography>
            <Typography variant="body2">
                <span className="text-gray-500">date: </span>
                {date}
            </Typography>
            {depth && (
                <Typography variant="body2">
                    <span className="text-gray-500">depth: </span>
                    {depth}
                </Typography>
            )}
            {name && (
                <Typography variant="body2">
                    <span className="text-gray-500">name: </span>
                    {name}
                </Typography>
            )}
        </div>
    );
};

export const DndcPhases = ({
    gridTemplateColumns,
    dndcCroppingData,
    yearStart,
    monthsBeforeStart,
    hasFieldsData,
}: {
    gridTemplateColumns: string;
    dndcCroppingData?: CroppingViewData | null;
    yearStart: number;
    monthsBeforeStart: number;
    hasFieldsData: boolean;
}) => {
    const { classes } = useStyle();

    // keep dates of events added to only display one line per date. This to avoid overlapping lines (they have opacity)
    const eventLines: string[] = [];

    const dndcPhasesComputed = useMemo(() => {
        const computedPhases = dndcCroppingData?.phases?.map((phase, i) => ({
            start: computeGridFromDate(phase?.start_date, yearStart, monthsBeforeStart) ?? 1,
            end: computeGridFromDate(phase?.end_date, yearStart, monthsBeforeStart) ?? '-1',
            color: '#444444',
            label: `${phase?.start_date} → ${phase?.end_date}`,
            id: i,
            year: i - 2,
        }));

        return computedPhases;
    }, [dndcCroppingData, monthsBeforeStart, yearStart]);

    const dndcCroppingComputed = useMemo(() => {
        const computedCropping = dndcCroppingData?.phases
            ?.flatMap((phase) =>
                phase.management_events.cropping.map((cropping) => [phase.start_date, cropping] as const),
            )
            .map(([phaseStartDate, cropping]) => ({
                start: computeGridFromDate(cropping?.start_date, yearStart, monthsBeforeStart) ?? 1,
                end: computeGridFromDate(cropping?.end_date, yearStart, monthsBeforeStart) ?? -1,
                color: mscCropTypes['Other crops']?.color,
                label: cropping.name,
                title: `${cropping.start_date} → ${cropping.end_date}`,
                id: phaseStartDate + cropping.name,
            }));

        return computedCropping;
    }, [dndcCroppingData, yearStart, monthsBeforeStart]);

    const dndcEventsComputed = useMemo(() => {
        const computedEvents = dndcCroppingData?.phases
            ?.flatMap((phase) =>
                Object.entries(phase.management_events).map(([key, value]) => [phase.start_date, key, value] as const),
            )
            .flatMap(([phaseStartDate, key, value]) => {
                if (value.length === 0 || key === 'irrigation') {
                    return;
                }
                if (key === 'cropping') {
                    value = value
                        .map((v: any) => v.reductions)
                        .flat()
                        .filter((v: any) => v);
                    key = 'reduction';
                }

                return value.map((event: any) => {
                    const date = computeGridFromDate(event?.date, yearStart, monthsBeforeStart);
                    if (!date) {
                        console.error(`NO Date for event '${event}' (${key})`);
                        return;
                    }

                    return {
                        start: date - 1,
                        end: date + 1,
                        color: dndcOperationTypes?.[key as 'till' | 'fertilizer' | 'reduction']?.color ?? 'red',
                        label: event?.type ?? key,
                        title: `${event.date}`,
                        id: phaseStartDate + key + Math.random(),
                        labelDate: event?.date,
                        labelDepth: event?.depth && `${event.depth} cm`,
                        labelName: event?.name,
                    };
                });
            })
            .filter((event) => event);

        return computedEvents;
    }, [dndcCroppingData, monthsBeforeStart, yearStart]);

    const filteredDndcEventsComputed = useMemo(
        () => sortByUniques(dndcEventsComputed, (event: any) => event.start),
        [dndcEventsComputed],
    );

    const status = useMemo(() => {
        if (dndcCroppingData?.status === 'FAILED') return 'FAILED';
        if (dndcCroppingData?.status === 'SKIPPED') return 'SKIPPED';
        if (dndcCroppingData?.status === 'SUCCEEDED') return null;
        return 'UNPROCESSED';
    }, [dndcCroppingData]);
    const statusColorMap = {
        SKIPPED: '#1565C0',
        FAILED: '#C0392B',
        UNPROCESSED: '#616161',
    };

    const errorMessages = useMemo(() => {
        if (dndcCroppingData?.status === 'FAILED') {
            return dndcCroppingData?.errors ?? null;
        }
    }, [dndcCroppingData]);

    return (
        <div
            className={`
                 grid 
                 border-t-[1px]
                 border-gray-300
                 border-solid
                 min-h-16 
                 bg-gray-50
                 relative
                 py-2`}
            style={{
                gridTemplateColumns,
            }}
        >
            {!hasFieldsData ? (
                <div className="text-gray-500 text-nowrap p-4">No data</div>
            ) : (
                <>
                    <span className="absolute m-2">
                        <div>Regrow</div>
                        {dndcCroppingData?.updated_at && (
                            <Typography variant="caption" className="text-gray-400">
                                {new Date(dndcCroppingData?.updated_at).toLocaleDateString()}
                            </Typography>
                        )}
                        {dndcCroppingData?.outdated && (
                            <Tooltip
                                disableInteractive
                                className="w-fit"
                                display="block"
                                elements={
                                    <Typography variant="body2" className="text-red-400">
                                        The Operation data has changed since the last result calclation
                                    </Typography>
                                }
                            >
                                <IconWarning />
                            </Tooltip>
                        )}
                    </span>
                    {status && (
                        <GridStatus
                            startPosition={getGridDayRatio(30 * (monthsBeforeStart + 2))}
                            text={status}
                            bgColor={statusColorMap[status]}
                            hoverText={errorMessages}
                        />
                    )}
                </>
            )}
            {/* // PHASES */}
            {dndcPhasesComputed?.map((zone: any, i) => (
                <span
                    key={zone.start}
                    className={`${classes.phase} ${
                        dndcPhasesComputed.length - 1 === i ? classes.lastPhase : ''
                    } rounded-md h-4 mt-1 mb-2`}
                    style={{
                        gridColumn: `${zone.start}/${zone.end}`,
                        backgroundColor: i === 3 ? '#777777' : zone.color,
                        border: '2px solid white',
                    }}
                >
                    <Tooltip
                        className="w-full h-full"
                        scheme="light"
                        display="block"
                        elements={
                            <>
                                <Typography variant="body">
                                    Phase {i + 1} {i === 3 ? '(Intervention)' : ''}
                                </Typography>
                                <Typography variant="body2">
                                    <span className="text-gray-500">dates: </span>
                                    {zone.label}
                                </Typography>
                            </>
                        }
                        disableInteractive
                    >
                        <div className="w-full h-full" />
                    </Tooltip>
                </span>
            ))}
            {/* // CROPPING */}
            {dndcCroppingComputed?.map((cropping) => (
                <span
                    key={cropping.id}
                    className={`rounded-md`}
                    style={{
                        gridColumn: `${cropping.start}/${cropping.end}`,
                        backgroundColor: cropping.end !== -1 && cropping.start > cropping.end ? 'red' : cropping.color,
                        border: '1px solid rgb(55 96 78 / 30%)',
                    }}
                >
                    <Tooltip
                        scheme="light"
                        elements={
                            <>
                                <Typography variant="body">{cropping.label}</Typography>
                                <Typography variant="body2">
                                    <span className="text-gray-500">dates: </span>
                                    {cropping.title}
                                </Typography>
                            </>
                        }
                        disableInteractive
                    >
                        <div className={classes.cropName}>{cropping.label}</div>
                    </Tooltip>
                </span>
            ))}
            {/* // EVENTS */}
            {filteredDndcEventsComputed?.map((event) => (
                <span
                    key={event.id}
                    className={`${
                        eventLines.includes(event.labelDate) ? '' : eventLines.push(event.labelDate) && classes.event
                    } rounded-md h-3 mt-px relative`}
                    style={{
                        gridColumn: `${event.start}/${event.end}`,
                        backgroundColor: event.color,
                    }}
                >
                    <Tooltip
                        elements={
                            <TooltipContent
                                date={event.labelDate}
                                type={event.label}
                                depth={event.labelDepth}
                                name={event.labelName}
                            />
                        }
                        display="inline"
                        scheme="light"
                        disableInteractive
                    >
                        <div className="w-full h-4" />
                    </Tooltip>
                </span>
            ))}
        </div>
    );
};

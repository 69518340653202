import { BaseEntityT } from '../baseEntity.types';
import { ProgressT } from '@shared/entities';
import { ProgressDetailT } from '../progress/progress.types';
import { StaticCropSlugT } from '../staticCrop/staticCrop.types';
import { StaticMachinerySlugT } from '../staticMachinery/staticMachinery.types';
import { FertiliserUnitT } from '../staticFertiliser/staticFertiliser.types';
import { PesticideTypeT } from '../staticPesticide/staticPesticide.types';

export type FieldCropT = {
    farm_season_field_id: number;
    farm_season_crop_id: number;

    is_organic?: boolean | null;
    tillage_id?: number | null;
    has_companion_crop?: boolean | null;
    companion_crop_id?: number | null;
    creation_source?: FieldCropCreationSourceT | null;
    crop_destination_static_data_id?: number | null;
} & BaseEntityT;

export const FIELD_CROP_CREATION_SOURCE_SLUGS = {
    PAC: 'pac',
    MANUAL: 'manual',
    OPENDATA: 'opendata',
} as const;

export type FieldCropCreationSourceT =
    (typeof FIELD_CROP_CREATION_SOURCE_SLUGS)[keyof typeof FIELD_CROP_CREATION_SOURCE_SLUGS];

export type FieldCropFinalizeAssignationPayloadT = {
    farm_season_field_crop_ids: number[];
};

export type FieldCropUpdatePayloadT = {
    id?: number;
    is_organic?: boolean | null;
    tillage_id?: number | null;
    has_companion_crop?: boolean | null;
    companion_crop_id?: number | null;
    crop_destination_static_data_id?: number | null;
};

export type FieldCropEndpointParamsT = {
    farmSeasonId?: number;
};

export type lastPreviousHarvestOperationEndpointParamsT = {
    farmSeasonFieldCropId: number;
    farmSeasonId: number;
};
/* --------------------------------- ACTIONS -------------------------------- */
export type UpdateGlobalCharacteristicsResponseT = {
    field_crop_updated: FieldCropT;
    progress_updated: ProgressT[];
};
export type UpdateGlobalCharacteristicsRequestT = {
    farmSeasonId: number;
    id: number;
    body: {
        is_organic?: boolean | null;
        tillage_id?: number | null;
        has_companion_crop?: boolean | null;
        companion_crop_id?: number | null;
    };
};

export type FinalizeGlobalCharacteristicsResponseT = {
    progress_detail_updated: ProgressDetailT;
};
export type FinalizeGlobalCharacteristicsRequestT = {
    farmSeasonId: number;
    id: number;
};

/* ---------------------------- Field crop summary --------------------------- */
export type TillageMachinerySummaryT = {
    machinery_slug: StaticMachinerySlugT;
    passes: number;
    details: { depth: number }[];
};
export type TillageSummaryT = TillageMachinerySummaryT[];
export type MineralFertiliserSummaryT = {
    fertiliser_slug: string;
    quantity: number;
    unit: FertiliserUnitT;
    n: number;
    p: number;
    k: number;
    ca: number;
    mg: number;
    s: number;
};
export type OrganicFertiliserSummaryT = {
    fertiliser_slug: string;
    quantity: number;
    unit: FertiliserUnitT;
};
export type MineralFertilisingSummaryT = {
    total_npk: {
        n: number;
        p: number;
        k: number;
    };
    total_camgs: {
        ca: number;
        mg: number;
        s: number;
    };
    fertilisers: MineralFertiliserSummaryT[];
};
export type OrganicFertilisingSummaryT = OrganicFertiliserSummaryT[];
export type PesticideSummaryT = {
    pesticide_type_slug: PesticideTypeT;
    passes: number;
};
export type SprayingSummaryT = PesticideSummaryT[];
export type HarvestingSummaryT = {
    yield: number;
};
export type FieldCropSummaryT = {
    farm_sason_field_crop_id: number;
    previous_crop_slug: StaticCropSlugT | null;
    has_cover_crops: boolean;
    tillage_summary: TillageSummaryT;
    mineral_fertilising_summary: MineralFertilisingSummaryT;
    organic_fertilising_summary: OrganicFertilisingSummaryT;
    spraying_summary: SprayingSummaryT;
    harvesting_summary: HarvestingSummaryT;
};

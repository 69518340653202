export const computeGridFromDate = (
    date: string | undefined,
    startYear: number,
    monthsBefore: number,
): number | null => {
    if (!date) {
        return null;
    }

    const beginingYear = startYear; // make sure we start from 1
    const [year, month, day] = date.split('-');
    const relativeYears = parseInt(year) - beginingYear;
    const relativeMonths = parseInt(month) - 1 + monthsBefore;
    const days = relativeYears * 360 + relativeMonths * 30 + parseInt(day);
    const gridDays = getGridDayRatio(days);
    return gridDays > 1 ? gridDays : computeGridFromDate('0-01-01', 0, monthsBefore); // default to 01 january
};

export const getGridDayRatio = (days: number) => {
    return Math.floor(days / 5);
};

export const TableHeader = ({
    gridTemplateColumns,
    columnsByMonth,
    startingGridColumns,
    endingGridColumns,
    yearsCount,
    yearStart,
}: {
    gridTemplateColumns: string;
    yearsCount: number;
    yearStart: number;
    startingGridColumns: number;
    endingGridColumns: number;
    columnsByMonth: number;
}) => {
    return (
        <div
            className="grid bg-gray-700 text-white overflow-hidden"
            style={{
                gridTemplateColumns,
            }}
        >
            {/* before */}
            <span className="font-bold pt-1 bg-gray-500" style={{ gridColumn: `1/${startingGridColumns}` }} />
            {/* years */}
            {[...Array(yearsCount).keys()].map((yearIndex) => (
                <span
                    key={yearIndex}
                    className={`text-center self-center font-bold pt-1 
                  ${yearIndex % 2 === 0 ? 'bg-gray-600' : ''}`}
                    style={{
                        gridColumn: `${startingGridColumns + yearIndex * 12 * columnsByMonth}/${
                            startingGridColumns + (yearIndex + 1) * 12 * columnsByMonth
                        }`,
                    }}
                >
                    {yearStart + yearIndex}
                </span>
            ))}
            {/* after */}
            <span
                className="font-bold pt-1 bg-gray-500"
                style={{ gridColumn: `-${endingGridColumns}/-1` }} // +1 because we count in the othe direction
            />

            {/* ------------------------------------------------- */}

            {/* before */}
            <span className="text-center font-bold py-2 bg-gray-500" style={{ gridColumn: `1/${startingGridColumns}` }}>
                ←
            </span>
            {/* months */}
            {[...Array(yearsCount * 12).keys()].map((monthIndex) => (
                <span
                    key={monthIndex}
                    className={`text-center font-bold py-2 text-xs ${
                        Math.floor(monthIndex / 12) % 2 === 0 ? 'bg-gray-600' : ''
                    }`}
                    style={{
                        gridColumn: `${startingGridColumns + monthIndex * columnsByMonth}/${
                            startingGridColumns + (monthIndex + 1) * columnsByMonth
                        }`,
                    }}
                >
                    {(monthIndex % 12) + 1}
                </span>
            ))}
            {/* after */}
            <span className="text-center font-bold py-2 bg-gray-500" style={{ gridColumn: `-${endingGridColumns}/-1` }}>
                →
            </span>
        </div>
    );
};

import { useTranslation } from 'react-i18next';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { FullscreenModal } from '@shared/components/FullscreenModal/FullscreenModal';
import { FormProvider } from 'react-hook-form';
import { useOperationDuplicationForm } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationForm';
import { OperationDuplicationLeftPanel } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/OperationDuplicationLeftPanel/OperationDuplicationLeftPanel';
import { OperationDuplicationRightPanel } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/OperationDuplicationRightPanel/OperationDuplicationRightPanel';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { ConfirmDuplicationModal } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/ConfirmDuplicationModal/ConfirmDuplicationModal';
import { FinaliseDuplicationModal } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/FinaliseDuplicationModal/FinaliseDuplicationModal';
import { useDuplicationModals } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useDuplicationModals';
import { useFieldsReadyForFinalisation } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/FinaliseDuplicationModal/useFieldsReadyForFinalisation';
import { Button } from '@soil-capital/ui-kit/components';
import { enqueueSnackbar } from 'notistack';

export type OperationDuplicationModalPropsT = {
    open: boolean;
    onClose: () => void;
    sourceField: DuplicationFieldT;
    targetFields: DuplicationFieldT[];
};

export const OperationDuplicationModal = ({
    open,
    onClose,
    sourceField,
    targetFields,
}: OperationDuplicationModalPropsT) => {
    const { t } = useTranslation();
    const {
        openConfirmModal,
        handleOpenConfirmModal,
        handleCloseConfirmModal,
        openFinaliseModal,
        handleOpenFinaliseModal,
        handleCloseFinaliseModal,
    } = useDuplicationModals();

    const { fieldsReadyForFinalisation, isFetching } = useFieldsReadyForFinalisation();

    const { methods, onSubmit, isLoadingDuplication, onReset } = useOperationDuplicationForm({
        sourceField,
        onMutationSuccess: () => {
            enqueueSnackbar(t('encoding-technical-itinerary.duplication.modal.success-modal.text'), {
                variant: 'success',
            });
            handleCloseConfirmModal(onReset);
        },
    });

    const onClickOnFinish = () => {
        return fieldsReadyForFinalisation?.length > 0 ? handleOpenFinaliseModal() : onClose();
    };

    const onModalFinaliseConfirm = () => {
        onClose();
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <FullscreenModal
                        open={open}
                        onClose={onClose}
                        title={
                            <>
                                <IconLightning />
                                {t('encoding-technical-itinerary.duplication.modal.title')}
                            </>
                        }
                        headerButton={
                            <Button loading={isFetching} onClick={onClickOnFinish} variant="contained" color="primary">
                                {t('constants.finish')}
                            </Button>
                        }
                        leftPanel={<OperationDuplicationLeftPanel sourceField={sourceField} />}
                        rightPanel={
                            <OperationDuplicationRightPanel
                                sourceField={sourceField}
                                targetFields={targetFields}
                                onConfirmDuplicationClick={(selectedFields) =>
                                    handleOpenConfirmModal(sourceField, targetFields, selectedFields)
                                }
                            />
                        }
                    />
                    <ConfirmDuplicationModal
                        sourceFieldName={sourceField.name}
                        open={openConfirmModal}
                        onClose={handleCloseConfirmModal}
                        onConfirm={onSubmit}
                        isLoading={isLoadingDuplication}
                    />
                </form>
            </FormProvider>
            <FinaliseDuplicationModal
                open={openFinaliseModal}
                onClose={handleCloseFinaliseModal}
                onConfirm={onModalFinaliseConfirm}
                harvestYear={sourceField.harvestYear}
                fieldCrops={fieldsReadyForFinalisation}
            />
        </>
    );
};

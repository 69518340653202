import { auth } from '@modules/auth';
import { CompanyT } from '@modules/billing/api/userCompany.types';
import { usePostPartnerPrepaidUserCompanyMutation } from '@modules/billing/api/userCompanyApi';
import { countrySlugs } from '@shared/constants/countrySlugs';
import entities from '@shared/entities';
import { useForm } from '@shared/hooks';
import { useGetSoilCapitalCountryList } from '@shared/hooks/useGetSoilCapitalCountryList';
import { isValidIBAN, electronicFormatIBAN } from 'ibantools';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import UkModulusChecking from 'uk-modulus-checking';
import * as z from 'zod';

export const BANK_ACCOUNT = { IBAN: 'iban', ACCOUNT_NUMBER: 'account_number', SORT_CODE: 'sort_code' };

export const useUpdateBankAccountFormLogic = (
    refetch: () => void,
    t: (key: string) => string,
    nextAction: () => void,
    userCompany?: CompanyT,
    setIsSubmitting?: (isSubmitting: boolean) => void,
    setDisableContinue?: (disable: boolean) => void,
) => {
    const { countries } = useGetSoilCapitalCountryList();
    const { farmState } = entities.farm.useState({ farmSeasonId: auth.useLatestFarmSeasonId() ?? 0 });
    const farm = farmState.list[0];
    const selectedCountry: string = farm.country.id.toString();

    const canEditBankAccount = auth.usePermission('canEditIban');

    const isIrelandSelected = useCallback(
        (selectedCountry: string): boolean => {
            const selectedCountrySlug = countries.find((country) => country.id.toString() === selectedCountry)?.name;

            return selectedCountrySlug === countrySlugs.IE;
        },
        [countries],
    );
    const isGreatBritainSelected = useCallback(
        (selectedCountry: string): boolean => {
            const selectedCountrySlug = countries.find((country) => country.id.toString() === selectedCountry)?.name;

            return selectedCountrySlug === countrySlugs.GB;
        },
        [countries],
    );

    const defaultValues = {
        [BANK_ACCOUNT.IBAN]: userCompany?.iban ?? null,
        [BANK_ACCOUNT.ACCOUNT_NUMBER]: userCompany?.account_number?.toString() ?? null,
        [BANK_ACCOUNT.SORT_CODE]: userCompany?.sort_code?.toString() ?? null,
    };

    const schema = z
        .object({
            [BANK_ACCOUNT.IBAN]: z.string().nullable(),
            [BANK_ACCOUNT.ACCOUNT_NUMBER]: z.string().nullable(),
            [BANK_ACCOUNT.SORT_CODE]: z.string().nullable(),
        })
        .refine(
            (data) => {
                return canEditBankAccount && !isFromGreatBritain && !isFromIreland
                    ? data[BANK_ACCOUNT.IBAN] === null ||
                          data[BANK_ACCOUNT.IBAN] === '' ||
                          isValidIBAN(electronicFormatIBAN(data[BANK_ACCOUNT.IBAN] ?? '') as string)
                    : true;
            },
            {
                message: t('billing.subscription.billing-form.IBAN.error'),
                path: [BANK_ACCOUNT.IBAN],
            },
        )
        .superRefine((data, ctx) => {
            if ((isFromGreatBritain || isFromIreland) && canEditBankAccount) {
                const sortCode = data[BANK_ACCOUNT.SORT_CODE] ?? '';
                const accountNumber = data[BANK_ACCOUNT.ACCOUNT_NUMBER] ?? '';
                // We allow prepaid user to not give bank account details
                if (!sortCode && !accountNumber) return true;
                if (sortCode.replace(/-/g, '').length !== 6) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: t('billing.subscription.billing-form.sort-code.length.error'),
                        path: [BANK_ACCOUNT.SORT_CODE],
                    });
                }
                if (accountNumber.length !== 8) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: t('billing.subscription.billing-form.account-number.length.error'),
                        path: [BANK_ACCOUNT.ACCOUNT_NUMBER],
                    });
                }
                if (sortCode && accountNumber && !new UkModulusChecking({ accountNumber, sortCode }).isValid()) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: t('billing.subscription.billing-form.account-number.error'),
                        path: [BANK_ACCOUNT.ACCOUNT_NUMBER],
                    });
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: t('billing.subscription.billing-form.sort-code.error'),
                        path: [BANK_ACCOUNT.SORT_CODE],
                    });
                }
            }
        });

    const methods = useForm({ schema, defaultValues, displaySnackbarOnSchemaError: false, mode: 'onBlur' });

    const {
        handleSubmit,
        formState: { errors, isValid },
        watch,
    } = methods;
    const [postPartnerPrepaidUserCompany, { isLoading: isFormPostFormLoading }] =
        usePostPartnerPrepaidUserCompanyMutation();

    const submitForm = handleSubmit(async (data) => {
        const response = await postPartnerPrepaidUserCompany({
            farmId: farm.id,
            company: {
                iban: data?.iban?.toString() ?? null,
                account_number: Number(data?.account_number),
                sort_code: Number(data?.sort_code),
            },
        });
        if ('error' in response && response.error) {
            enqueueSnackbar(t('pages.payment.billing-info.iban.error'), { variant: 'error' });
        } else {
            if (userCompany) {
                refetch();
            }
            nextAction();
        }
    });

    const [isFromIreland, setIsFromIreland] = useState(isIrelandSelected(selectedCountry));
    const [isFromGreatBritain, setIsFromGreatBritain] = useState(isGreatBritainSelected(selectedCountry));

    const sortCode = watch(BANK_ACCOUNT.SORT_CODE) as string | null;

    useEffect(() => {
        setIsFromIreland(isIrelandSelected(selectedCountry));
        setIsFromGreatBritain(isGreatBritainSelected(selectedCountry));
    }, [selectedCountry, isIrelandSelected, isGreatBritainSelected]);

    useEffect(() => {
        setIsSubmitting?.(isFormPostFormLoading);
    }, [setIsSubmitting, isFormPostFormLoading]);

    useEffect(() => {
        setDisableContinue?.(isValid);
    }, [setDisableContinue, isValid]);

    nextAction;

    return {
        defaultValues,
        methods,
        canEditBankAccount,
        errors,
        submitForm,
        isFromGreatBritain,
        isFromIreland,
        sortCode,
    };
};

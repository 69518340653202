/* eslint-disable @typescript-eslint/no-explicit-any */

export const sortByUniques = (values: undefined | any[], getSortComparer: (v: any) => any | number) => {
    if (!values) {
        return [];
    }

    const filtered: any[] = [];
    let toBeFiltered = [...values];

    do {
        const duplicates = [];
        const uniques = [];
        const seen = new Set();

        for (const item of toBeFiltered) {
            const valueToCompare = getSortComparer(item);
            if (seen.has(valueToCompare)) {
                duplicates.push(item);
            } else {
                seen.add(valueToCompare);
                uniques.push(item);
            }
        }
        filtered.push(...uniques.sort((a, b) => getSortComparer(a) - getSortComparer(b)));

        toBeFiltered = duplicates;
    } while (toBeFiltered.length > 0);

    return filtered;
};

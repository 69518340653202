import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => {
    return {
        flat: {
            boxShadow: 'none',
            filter: 'none',
            outline: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
            '& .MuiOutlinedInput-input': {
                padding: theme.spacing(1, 2),
            },
        },
        flexbox: {
            paddingRight: theme.spacing(1),
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            width: '100%',
            paddingBottom: theme.spacing(2),
        },
        label: { color: theme.palette.darkScale[700], whiteSpace: 'nowrap' },
    };
});

import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useMap } from '@shared/map/useMap';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, FeatureCollection, Geometry, GeometryCollection, Polygon, Properties } from '@turf/helpers';
import {
    calculateArea,
    deleteLayer,
    isDrawingInsideBoundaries,
    isDrawingOnOtherPolygons,
} from '@modules/encoding/modules/rotation/helpers/polygonHelper';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@shared/hooks/useToggle';
import { MAPMODE, MapModeT } from '@modules/encoding/modules/rotation/components/PacImportMap/PacImportMap.logic';
import DrawPolygonWithSnap from '@modules/encoding/modules/rotation/helpers/draw_polygon_with_snap_mode.js';

export const useDrawFieldLogic = ({
    farmBoundaries,
    existingPolygons,
    mapMode,
    setMapMode,
}: {
    farmBoundaries: FeatureCollection<Geometry | GeometryCollection, Properties> | undefined;
    existingPolygons: Polygon[];
    mapMode: MapModeT;
    setMapMode: Dispatch<SetStateAction<MapModeT>>;
}) => {
    const map = useMap();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [drawControl, setDrawControl] = useState<MapboxDraw | null>(null);
    const [drawnPolygon, setDrawnPolygon] = useState<Feature<Polygon> | null>(null);
    const [polygonArea, setPolygonArea] = useState<number | null>(null);
    const [isSaveDisabled, setIsSavedDisabled] = useState(false);
    const mapModeRef = useRef(mapMode);

    const addDrawnLayer = useCallback(
        (polygon: Feature<Geometry>) => {
            if (map && polygon) {
                if (map.getLayer(`drawn-${polygon.id}`)) {
                    deleteLayer(map, `drawn-${polygon.id}`);
                }
                map.addLayer({
                    id: `drawn-${polygon.id}`,
                    type: 'fill',
                    source: {
                        type: 'geojson',
                        data: polygon.geometry as Polygon,
                    },
                    paint: {
                        'fill-color': '#FB9537',
                        'fill-opacity': 1,
                    },
                });
            }
        },
        [map],
    );
    const startDrawing = useCallback(() => {
        if (!map) return;
        setIsSavedDisabled(false);
        closeSnackbar();
        setMapMode(MAPMODE.DRAW);
        map.getCanvasContainer().style.cursor = `url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23f57c00\' width=\'24px\' height=\'24px\' viewBox=\'0 0 24 24\'%3E%3Cpath d=\'M0 0h24v24H0V0z\' fill=\'none\'/%3E%3Cpath d=\'M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z'/%3E%3C/svg%3E") 0 22, crosshair`;

        const modes = {
            ...MapboxDraw.modes,
            draw_polygon_with_snap: DrawPolygonWithSnap,
        };

        const drawOptions = new MapboxDraw({
            displayControlsDefault: false,
            modes,
        });

        if (drawControl) {
            map.removeControl(drawControl);
        }

        map.addControl(drawOptions);
        setDrawControl(drawOptions);
        drawOptions.changeMode('draw_polygon_with_snap', {
            fieldPolygonsLayer: existingPolygons,
            farmBoundaries: farmBoundaries?.features
                ? farmBoundaries?.features.map((feature) => feature.geometry as Polygon)
                : [],
        });
        map.on('draw.create', (event) => {
            if (mapModeRef.current !== MAPMODE.DRAW) return;
            const features: Feature<Polygon>[] = event.features;
            const drawingInsideBoundaries = isDrawingInsideBoundaries(features[0].geometry, farmBoundaries);
            const drawingOnOtherPolygons = isDrawingOnOtherPolygons(features[0].geometry, existingPolygons);
            if (drawingInsideBoundaries && !drawingOnOtherPolygons) {
                setDrawnPolygon(features[0]);
                addDrawnLayer(features[0]);
                setPolygonArea(calculateArea(features[0].geometry));
            } else if (drawingOnOtherPolygons) {
                setIsSavedDisabled(true);
                enqueueSnackbar(t('encoding-rotation.map.draw.overlap-error'), {
                    variant: 'error',
                    preventDuplicate: true,
                });
            } else if (!drawingInsideBoundaries) {
                setIsSavedDisabled(true);
                enqueueSnackbar(t('encoding-rotation.map.draw.boundaries-error'), {
                    variant: 'error',
                    preventDuplicate: true,
                });
            }
        });
    }, [
        map,
        drawControl,
        farmBoundaries,
        existingPolygons,
        t,
        enqueueSnackbar,
        closeSnackbar,
        addDrawnLayer,
        setMapMode,
    ]);

    const stopDrawing = useCallback(
        (removePolygon = false) => {
            if (!map || !drawControl) return;
            setMapMode(MAPMODE.NONE);
            map.getCanvasContainer().style.cursor = 'default';
            map.removeControl(drawControl);
            setDrawControl(null);

            if (removePolygon && drawnPolygon) {
                deleteLayer(map, `drawn-${drawnPolygon.id}`);
                setDrawnPolygon(null);
            }
        },
        [map, drawControl, drawnPolygon, setMapMode],
    );

    const {
        open: drawnFieldCreationModalOpen,
        handleOpen: onDrawnFieldCreationModalOpen,
        handleClose: onDrawnFieldCreationModalClose,
    } = useToggle();

    useEffect(() => {
        mapModeRef.current = mapMode;
    }, [mapMode]);

    return {
        t,
        startDrawing,
        stopDrawing,
        polygonArea,
        drawnPolygon,
        drawnFieldCreationModalOpen,
        onDrawnFieldCreationModalOpen,
        onDrawnFieldCreationModalClose,
        isSaveDisabled,
    };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useIsRenewalDisabled = (currentSeasonId: number, isPartnerFarmer: boolean, farmId: number | undefined) => {
    // const { data: carbonProgramme, isLoading } = entities.farm.useGetCarbonProgramme({
    //     farmSeasonId: currentSeasonId,
    // });
    // const isFarmer = auth.useAuthUser()?.role?.name === STATIC_ROLE_SLUGS.FARMER;
    // const isC4farmer = carbonProgramme?.slug === 'carbon-programme-4' && isFarmer;
    // const { seasonState } = entities.season.useState({ farmId }, { skip: !farmId });
    // const currentSeason = seasonState?.list?.find((fs) => fs.id === currentSeasonId);
    // const carbonProgrammeYear = currentSeason?.carbon_programme_year ?? 0;

    return {
        // isRenewalDisabled: isPartnerFarmer ? false : isLoading ? true : isC4farmer && carbonProgrammeYear <= 1,
        isRenewalDisabled: false,
    };
};

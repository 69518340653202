import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => {
    return {
        container: {
            backgroundColor: theme.palette.common.white,
            position: 'absolute',
            left: '50%',
            marginBottom: '10px',
            borderRadius: theme.shape.borderRadius,
            gap: theme.spacing(1),
            transform: 'translate(-50%, 0%)',
            padding: theme.spacing(1),
            zIndex: 100,
            display: 'flex',
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 'calc(100% - 60px)',
            overflow: 'hidden',
        },
    };
});

import { Button } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { usePacImportHeaderStyles } from '@modules/encoding/modules/rotation/components/PacImportHeader/PacImportHeader.style';
import { IconInfo, IconTrash, IconUpload, IconWarning } from '@soil-capital/ui-kit/icons';
import { usePacImportHeaderLogic } from '@modules/encoding/modules/rotation/components/PacImportHeader/usePacImportHeaderLogic';
import { PacFileImportModal } from '../PacFileImportModal/PacFileImportModal';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { FieldAreaInfo } from '@modules/encoding/modules/rotation/components/FieldAreaInfo/FieldAreaInfo';

export const PacImportHeader = () => {
    const { t } = useTranslation();
    const { classes } = usePacImportHeaderStyles();
    const {
        currentSeasonId,
        hasFields,
        isFetchingFields,
        harvestYear,
        numberOfInvalidFields,
        pacFileModalOpen,
        onPacFileModalOpen,
        onPacFileModalClose,
        handleOpenIntercomArticle,
        handleDeleteInvalidFields,
        isDeleteInvalidFieldsLoading,
        isLoadingInvalidFields,
    } = usePacImportHeaderLogic();
    const { readOnly } = useFarmSeasonReadOnly();

    return (
        <>
            <Typography variant="h3">
                {t('encoding-rotation.pac-file.contextual-panel.title')}
                <span className={classes.harvest_year}>{harvestYear}</span>
            </Typography>
            {hasFields && !isLoadingInvalidFields ? (
                <FieldAreaInfo hasInvalidFields={numberOfInvalidFields > 0} />
            ) : (
                <Typography mt={0.5} color="neutral" variant="body2">
                    {t('encoding-rotation.pac-file.contextual-panel.description', {
                        harvestYear,
                    })}
                </Typography>
            )}
            <div className={classes.pacImportContainer}>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    startIcon={<IconUpload style={{ color: 'white' }} className={classes.uploadIcon} />}
                    onClick={onPacFileModalOpen}
                    disabled={readOnly}
                >
                    {t('encoding-rotation.pac-file.contextual-panel.import-button')}
                </Button>
                <IconInfo onClick={handleOpenIntercomArticle} className={classes.iconInfo} />
            </div>
            {hasFields && (
                <Typography variant="subcaption" color="GrayText" className={classes.caption}>
                    {t('encoding-rotation.map.localise')}
                </Typography>
            )}
            {!hasFields && !isFetchingFields && (
                <Button onClick={handleOpenIntercomArticle} className={classes.helpButton} variant="text" fullWidth>
                    {t('encoding-rotation.pac-file.contextual-panel.help-button')}
                </Button>
            )}
            {numberOfInvalidFields > 0 && (
                <>
                    <div className={classes.invalidFieldsTextContainer}>
                        <IconWarning className={classes.alertIcon} />
                        <Typography variant="body">
                            {numberOfInvalidFields > 1
                                ? t('encoding-rotation.pac-file.contextual-panel.invalid-fields-more', {
                                      numberOfInvalidFields,
                                  })
                                : t('encoding-rotation.pac-file.contextual-panel.invalid-fields-one', {
                                      numberOfInvalidFields,
                                  })}
                        </Typography>
                    </div>
                    <Button
                        className={classes.deleteInvalidFieldsButton}
                        variant="text"
                        fullWidth
                        startIcon={<IconTrash className={classes.trashIcon} />}
                        onClick={handleDeleteInvalidFields}
                        disabled={readOnly}
                        loading={isDeleteInvalidFieldsLoading}
                    >
                        {t('encoding-rotation.pac-file.contextual-panel.delete-invalid-fields-button')}
                    </Button>
                </>
            )}
            <PacFileImportModal
                open={pacFileModalOpen}
                currentSeasonId={currentSeasonId}
                onClose={onPacFileModalClose}
            />
        </>
    );
};

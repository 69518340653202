/* eslint-disable @typescript-eslint/no-explicit-any */
import { CroppingFieldData } from '@modules/croppingView/api/fieldsCroppingFetcher';
import { MapLayers, MapRoot, useMap } from '@shared/map';
import { MapLayerListT } from '@shared/map/MapLayers';
import bbox from '@turf/bbox';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@soil-capital/ui-kit/style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Link } from 'react-router-dom';
import { Button } from '@soil-capital/ui-kit/components';

export const featureSelectionLayers: MapLayerListT = [
    {
        id: 'fill',
        type: 'fill',
        paint: {
            'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hovered'], false],
                ['get', 'fill_hover_color'],
                ['get', 'fill_color'],
            ],
            'fill-opacity': 0.8,
        },
    },
    {
        id: 'line',
        type: 'line',
        paint: {
            'line-color': ['get', 'line_color'],
            'line-width': 3,
        },
    },
];

export const FieldView = ({
    fieldFragmentData,
    onSelectFragment,
    selectedFragmentData,
    className,
}: {
    fieldFragmentData: CroppingFieldData[];
    onSelectFragment: (id: number) => void;
    selectedFragmentData: CroppingFieldData | undefined;
    className?: string;
}) => {
    const { classes } = makeStyles()(() => ({
        mapSizes: {
            height: '300px',
            width: '350px',
        },
        normalText: {
            fontFamily: 'BrownStd-Regular',
        },
        outlinedButton: {
            display: 'block',
            marginLeft: '6px',
            padding: '4px 8px',
            marginTop: '4px',
            border: '1px solid #cacaca',
            ':hover': {
                backgroundColor: '#cacaca',
            },
        },
        outlinedButtonActive: {
            borderColor: '#333 !important',
        },
    }))();

    const [hoveredId, setHoveredId] = useState<null | number>(null);

    // the source build for the map layer should be reusable for other field selections
    const mapLayerSource = useMemo(() => {
        if (!selectedFragmentData) {
            return {
                type: 'FeatureCollection',
                features: [],
            } as GeoJSON.FeatureCollection;
        }

        const features = [] as any[];

        features.push({
            type: 'Feature',
            id: selectedFragmentData?.field_id,
            geometry: JSON.parse(selectedFragmentData.field_geom),
            properties: {
                id: selectedFragmentData?.fragment_id,
                fill_color: '#b5ced8',
                fill_hover_color: 'transparent',
                line_color: 'transparent',
            },
        });
        fieldFragmentData.forEach((fieldFragment) =>
            features.push({
                type: 'Feature',
                id: fieldFragment.fragment_id,
                geometry: JSON.parse(fieldFragment.fragment_geom),
                properties: {
                    id: fieldFragment.fragment_id,
                    fill_color:
                        selectedFragmentData?.fragment_id === fieldFragment.fragment_id ? '#9255a8' : 'transparent',
                    fill_hover_color: '#8f0dbd',
                    line_color: '#8f0dbd',
                },
            }),
        );

        return {
            type: 'FeatureCollection',
            features,
        } as GeoJSON.FeatureCollection<GeoJSON.Geometry, any>;
    }, [fieldFragmentData, selectedFragmentData]);

    const map = useMap();

    useEffect(() => {
        if (!map) {
            return;
        }

        if (!selectedFragmentData) {
            return;
        }

        const fieldGeom = JSON.parse(selectedFragmentData?.field_geom ?? '{}');

        const focusFeatures = () => {
            const polygonToCenter = {
                type: 'Feature',
                geometry: fieldGeom,
                properties: {},
            };
            // Calculate the smallest box that can fit the given polygon(s)

            if (polygonToCenter) {
                const computedBbox = bbox({ type: 'FeatureCollection', features: [polygonToCenter] }) as BBox2d;
                map.fitBounds(computedBbox, { padding: 20, animate: false });
            }
        };

        focusFeatures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, selectedFragmentData?.field_id]);

    const interventionField = selectedFragmentData?.cropping_fields?.find((field) => field.year === 1);

    return (
        <div className={`flex flex-wrap ${className}`}>
            <MapLayers
                onFeatureHover={(feature, layerId) => {
                    if (layerId !== 'fill') {
                        return false;
                    }
                    setHoveredId(Number((feature as any)?.featureId ?? 0));
                }}
                onFeatureClick={(feature) => {
                    feature?.featureId && onSelectFragment(feature?.featureId);
                }}
                hoveredId={hoveredId}
                dataSource={mapLayerSource}
                layers={featureSelectionLayers}
            />
            <div className={`${classes.mapSizes} mx-5`}>
                <MapRoot className={classes.mapSizes} />
            </div>
            <div className={`${classes.normalText}`}>
                <div>
                    <Typography variant="body">Year {interventionField?.year}</Typography>
                    <Typography variant="body2">
                        <span className="text-gray-500">name: </span>
                        {interventionField?.name}
                    </Typography>
                    <Typography variant="body2">
                        <span className="text-gray-500">area: </span>
                        {interventionField?.field_area} ha
                    </Typography>
                    <Typography variant="body2">
                        <span className="text-gray-500">field ID: </span>
                        <span className="select-all">{interventionField?.id}</span>
                    </Typography>
                    <Link
                        to={`/${interventionField?.farm_season_id}/technical-itinerary/${interventionField?.crops[0]?.field_crop_id}/global-characteristics`}
                        target="_blank"
                        className="text-blue-500 underline"
                    >
                        Go to ITK ({interventionField?.crops[0]?.crop_name})
                    </Link>
                </div>
                {fieldFragmentData.map((fieldFragment) => (
                    <Button
                        key={fieldFragment.fragment_id}
                        size="small"
                        onClick={() => onSelectFragment(fieldFragment.fragment_id)}
                        variant="text"
                        color="inherit"
                        className={`${classes.outlinedButton} ${
                            fieldFragment.fragment_id === selectedFragmentData?.fragment_id
                                ? classes.outlinedButtonActive
                                : ''
                        }`}
                    >
                        <span>
                            fragment <span className="select-all">{fieldFragment.fragment_id}</span> (
                            {fieldFragment.fragment_area} ha)
                        </span>
                    </Button>
                ))}
            </div>
        </div>
    );
};

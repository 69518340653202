import { FieldsCreationModal } from '@modules/encoding/modules/rotation/components/FieldCreationModal/FieldsCreationModal';
import { MapLayers } from '@shared/map/MapLayers';
import { useMapOpendataControlLogic } from './MapOpendataControl.logic';

export const MapOpendataControl = () => {
    const {
        openDataGeoJSON,
        layers,
        onFeatureClick,
        onFeatureHover,
        fieldCreationModalOpen,
        onFieldCreationModalClose,
        vectorSource,
        fieldCreationModalPayload,
        fieldIdHovered,
        sourceLayerFill,
        vectorSourceId,
    } = useMapOpendataControlLogic();

    return (
        <>
            <MapLayers
                dataSource={openDataGeoJSON}
                layers={layers}
                onFeatureClick={onFeatureClick}
                onFeatureHover={onFeatureHover}
                vectorSources={vectorSource}
                hoveredId={fieldIdHovered}
                sourceLayer={sourceLayerFill}
                vectorSourceId={vectorSourceId}
            />
            {fieldCreationModalPayload && (
                <FieldsCreationModal
                    feature={fieldCreationModalPayload}
                    open={fieldCreationModalOpen}
                    onClose={onFieldCreationModalClose}
                />
            )}
        </>
    );
};

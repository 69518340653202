import { usePacFieldListStyles } from '@modules/encoding/modules/rotation/components/PacImportedFields/PacFieldList/PacFieldList.style';
import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { IconCross, IconEdit } from '@soil-capital/ui-kit/icons';
import { usePacFieldListLogic } from './usePacFieldListLogic';
import { List } from '@soil-capital/ui-kit/List';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { Button, Tooltip } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { fieldDefinitionSlice } from '@modules/encoding/shared/store/fieldDefinitionSlice';
import { useDispatch } from 'react-redux';
import { FieldSorter } from '../../FieldSorter/FieldSorter';
import { useSortedFields } from '../../../hooks/useSortedFields';

type PacFieldListProps = {
    onEdit: (fieldId: number) => void;
    onDelete: (id: number, isGeometryData: boolean) => void;
};

export const PacFieldList = ({ onEdit, onDelete }: PacFieldListProps) => {
    const { classes } = usePacFieldListStyles();
    const { fields, invalidFields, isFetching } = usePacFieldListLogic();
    const { t } = useTranslation();
    const { readOnly } = useFarmSeasonReadOnly();
    const dispatch = useDispatch();
    const { sortedFields, sortedInvalidFields, sortOption, handleSortChange } = useSortedFields(fields, invalidFields);
    const handleFieldClick = (fieldId: number) => {
        dispatch(fieldDefinitionSlice.actions.setFocusedFieldIds([fieldId]));
        dispatch(fieldDefinitionSlice.actions.setHighlightedFieldIds([fieldId]));

        setTimeout(() => {
            dispatch(fieldDefinitionSlice.actions.removeHighlightedFieldIds(fieldId));
        }, 1000);
    };

    if (isFetching)
        return (
            <div className={classes.skeletonList}>
                <Skeleton variant="rounded" width="100%" height={48} />
                <Skeleton variant="rounded" width="100%" height={48} />
                <Skeleton variant="rounded" width="100%" height={48} />
                <Skeleton variant="rounded" width="100%" height={48} />
                <Skeleton variant="rounded" width="100%" height={48} />
            </div>
        );

    return (
        <>
            {fields.length > 0 && <FieldSorter value={sortOption} onChange={handleSortChange} />}
            <List className={classes.list}>
                {sortedInvalidFields?.map((field) => (
                    <FieldCropInfoBox
                        className={`${classes.fieldCropInfoBox} ${classes.invalidField}`}
                        key={field.id}
                        fieldName={field.name}
                        fieldArea={`${field.area} HA`}
                        mapSnapshot={''}
                        isInvalid={true}
                        handleFieldClick={() => handleFieldClick(field.id)}
                        icon={
                            !readOnly && (
                                <div className={classes.iconContainer}>
                                    <Tooltip text={t('encoding-rotation.modal.field-list.delete')}>
                                        <Button variant="text" className={classes.button}>
                                            <IconCross
                                                className={classes.iconCross}
                                                onClick={() => onDelete(field.id, true)}
                                            />
                                        </Button>
                                    </Tooltip>
                                </div>
                            )
                        }
                    />
                ))}
                {sortedFields.map((field) => (
                    <FieldCropInfoBox
                        className={classes.fieldCropInfoBox}
                        key={field.id}
                        fieldName={field.name}
                        fieldArea={`${field.area} HA`}
                        mapSnapshot={field.snapshot}
                        disabled={field.is_permanent || field.hasAgroforestryDataOnPreviousSeason}
                        handleFieldClick={() => handleFieldClick(field.id)}
                        isInvalid={false}
                        icon={
                            !readOnly && (
                                <div className={classes.iconContainer}>
                                    <Tooltip
                                        text={t(
                                            field.is_permanent
                                                ? 'encoding-rotation.modal.field-list.is-permanent'
                                                : field.hasAgroforestryDataOnPreviousSeason
                                                ? 'encoding-rotation.modal.field-list.hasAgroforestryInPreviousSeason'
                                                : 'encoding-rotation.modal.field-list.edit',
                                        )}
                                    >
                                        <Button
                                            variant="text"
                                            className={classes.button}
                                            disabled={field.is_permanent || field.hasAgroforestryDataOnPreviousSeason}
                                        >
                                            <IconEdit
                                                data-testid={`field-${field.id}-edit`}
                                                onClick={() => {
                                                    if (
                                                        field.is_permanent ||
                                                        field.hasAgroforestryDataOnPreviousSeason
                                                    ) {
                                                        return;
                                                    }
                                                    onEdit(field.id);
                                                }}
                                                className={classes.iconEdit}
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        text={t(
                                            field.is_permanent
                                                ? 'encoding-rotation.modal.field-list.is-permanent'
                                                : field.hasAgroforestryDataOnPreviousSeason
                                                ? 'encoding-rotation.modal.field-list.hasAgroforestryInPreviousSeason'
                                                : 'encoding-rotation.modal.field-list.delete',
                                        )}
                                    >
                                        <Button
                                            variant="text"
                                            className={classes.button}
                                            disabled={field.is_permanent || field.hasAgroforestryDataOnPreviousSeason}
                                        >
                                            <IconCross
                                                data-testid={`field-${field.id}-delete`}
                                                onClick={() => {
                                                    if (
                                                        field.is_permanent ||
                                                        field.hasAgroforestryDataOnPreviousSeason
                                                    ) {
                                                        return;
                                                    }
                                                    onDelete(field.id, false);
                                                }}
                                                className={classes.iconCross}
                                            />
                                        </Button>
                                    </Tooltip>
                                </div>
                            )
                        }
                    />
                ))}
            </List>
        </>
    );
};

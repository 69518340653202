import { Tooltip } from '@soil-capital/ui-kit/components';

export const GridStatus = ({
    bgColor,
    startPosition,
    text,
    hoverText,
}: {
    startPosition: number;
    bgColor: string;
    text: string;
    hoverText?: string | null;
}) => {
    return (
        <span
            className=" w-0 h-8"
            style={{
                gridColumn: `${startPosition}`,
            }}
        >
            <Tooltip text={hoverText} className="w-fit" display="block">
                <span className=" rounded-xl py-2 px-4 text-gray-100 opacity-80" style={{ backgroundColor: bgColor }}>
                    {text}
                </span>
            </Tooltip>
        </span>
    );
};

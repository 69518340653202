import { useState } from 'react';
import { DuplicationFieldT } from './useGetDuplicationFieldCropData';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';

export const useDuplicationModals = () => {
    const [openDuplicationModal, setOpenDuplicationModal] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openFinaliseModal, setOpenFinaliseModal] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [openWarningModalForSelectAll, setOpenWarningModalForSelectAll] = useState(false);
    const [track] = useTrackMutation();

    const handleOpenDuplicationModal = (sourceField: DuplicationFieldT, targetFields: DuplicationFieldT[]) => {
        setOpenDuplicationModal(true);
        track({
            event: EVENTS.TECHNICAL_ITINERARY_DUPLICATION_STARTED,
            properties: {
                Crop: sourceField.staticCrop.name,
                Field: sourceField.name,
                Operations_subcategories_nb: sourceField.operationsByOperationType.length,
                Fields_nb: targetFields.length,
            },
        });
    };
    const handleCloseDuplicationModal = (sourceField: DuplicationFieldT) => {
        setOpenDuplicationModal(false);
        track({
            event: EVENTS.TECHNICAL_ITINERARY_DUPLICATION_CLOSED,
            properties: { crop: sourceField.staticCrop.name, Field: sourceField.name },
        });
    };
    const handleOpenConfirmModal = (
        sourceField: DuplicationFieldT,
        targetFields: DuplicationFieldT[],
        selectedFieldNbr: number,
    ) => {
        setOpenConfirmModal(true);
        track({
            event: EVENTS.TECHNICAL_ITINERARY_DUPLICATION_REQUESTED,
            properties: {
                Crop: sourceField.staticCrop.name,
                Field: sourceField.name,
                Operations_subcategories_nb: sourceField.operationsByOperationType.length,
                Fields_nb: targetFields.length,
                Target_fields_nb: selectedFieldNbr,
            },
        });
    };
    const handleCloseConfirmModal = (onReset?: () => void) => {
        if (onReset) {
            onReset();
        }
        setOpenConfirmModal(false);
    };
    const handleOpenFinaliseModal = () => setOpenFinaliseModal(true);
    const handleCloseFinaliseModal = () => setOpenFinaliseModal(false);
    const handleOpenWarningModal = () => setOpenWarningModal(true);
    const handleOpenWarningModalForSelectAll = () => setOpenWarningModalForSelectAll(true);
    const handleCloseWarningModalForSelectAll = () => setOpenWarningModalForSelectAll(false);
    const handleCloseWarningModal = () => setOpenWarningModal(false);

    return {
        openDuplicationModal,
        handleOpenDuplicationModal,
        handleCloseDuplicationModal,
        openConfirmModal,
        handleOpenConfirmModal,
        handleCloseConfirmModal,
        openFinaliseModal,
        handleOpenFinaliseModal,
        handleCloseFinaliseModal,
        openWarningModal,
        handleOpenWarningModal,
        handleCloseWarningModal,
        openWarningModalForSelectAll,
        handleOpenWarningModalForSelectAll,
        handleCloseWarningModalForSelectAll,
    };
};

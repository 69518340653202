import { MapLayerListT } from '../MapLayers';
``;
export const featureSelectionLayers: MapLayerListT = [
    {
        id: 'feature-fill',
        type: 'fill',
        paint: {
            'fill-color': [
                'case',
                ['boolean', ['feature-state', 'highlighted'], false],
                'orange',
                ['boolean', ['feature-state', 'selected'], false],
                'green',
                ['get', 'color'],
            ],
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'highlighted'], false],
                0.7,
                ['boolean', ['feature-state', 'hovered'], false],
                0.9,
                0.6,
            ],
        },
    },
    {
        id: 'feature-line',
        type: 'line',
        paint: {
            'line-color': [
                'case',
                ['boolean', ['feature-state', 'highlighted'], false],
                'orange',
                ['boolean', ['feature-state', 'selected'], false],
                'green',
                ['get', 'color'],
            ],
            'line-width': 1.5,
        },
    },
    {
        id: 'feature-text',
        type: 'symbol',
        paint: {
            'text-color': 'white',
            'text-halo-color': 'black',
            'text-halo-width': 1,
        },
        layout: {
            'text-field': ['get', 'label'],
            'text-size': 12,
            'text-font': ['literal', ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
            'text-anchor': 'center',
            'text-max-width': 10,
        },
    },
    {
        id: 'feature-pattern',
        type: 'fill',
        paint: {
            'fill-pattern': ['get', 'pattern-image'],
        },
    },
];

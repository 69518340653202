import { CarbonProgrammeT, FarmBodyT, FarmEndpointParamsT, FarmT } from './farm.types';
import { EntityState } from '@reduxjs/toolkit';
import farmCache from './farm.cache';
import api from '@shared/api';
import { UserT } from '@modules/auth/api/authApi.types';

const url = (farmSeasonId: number) => `/v3/farm-seasons/${farmSeasonId}/farms`;

export const farmApiInstance = api.injectEndpoints({
    endpoints: (build) => ({
        getFarm: build.query<EntityState<FarmT>, FarmEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: url(farmSeasonId),
                method: 'GET',
            }),
            transformResponse: farmCache.transformResponse,
        }),
        getFarmFarmer: build.query<UserT, FarmEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/farmer`,
                method: 'GET',
            }),
        }),
        getCarbonProgramme: build.query<CarbonProgrammeT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/carbon-programme`,
                method: 'GET',
            }),
        }),
        updateFarm: build.mutation<FarmT, FarmEndpointParamsT & { body: FarmBodyT; id: number }>({
            query: ({ body, id, farmSeasonId }) => ({
                url: `${url(farmSeasonId)}/${id}`,
                method: 'PUT',
                body,
            }),
            onQueryStarted: farmCache.update('fetch-only'),
        }),
    }),
});

export default {
    useUpdate: farmApiInstance.useUpdateFarmMutation,
    useGet: farmApiInstance.useGetFarmQuery,
    useGetFarmer: farmApiInstance.useGetFarmFarmerQuery,
    useGetCarbonProgramme: farmApiInstance.useGetCarbonProgrammeQuery,
};

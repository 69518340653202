import { useMemo } from 'react';
import entities from '@shared/entities';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';

export const useFieldCropHasCoverCropDestruction = (seasonId: number, fieldCropId: number) => {
    const { operationState } = entities.operation.useState({
        farmSeasonId: seasonId,
        farmSeasonFieldCropId: fieldCropId,
    });
    const { staticOperationTypeState } = entities.staticOperationType.useState();

    const fieldCropHasCoverCropDestruction = useMemo(() => {
        return operationState?.list?.some(
            (el) =>
                staticOperationTypeState?.getById(el.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION,
        );
    }, [operationState, staticOperationTypeState]);

    return {
        fieldCropHasCoverCropDestruction,
        isLoading: staticOperationTypeState.isLoading || operationState.isLoading,
    };
};

import { Loader, SearchInput, SelectableList, SidePanel, Tooltip } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { formatNumber } from '@shared/utils';
import { IconCheckCircle, IconMap2, IconPlusCircle, IconSeed } from '@soil-capital/ui-kit/icons';
import { useFieldCropAssignationModalRightPanelStyles } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldCropAssignationModalRightPanel/FieldCropAssignationModalRightPanel.style';
import { FieldListFilters } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldListFilters/FieldListFilters';
import { SelectedFieldFilter } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/SelectedFieldFilter/SelectedFieldFilter';
import { useFieldCropAssignationModalRightPanelLogic } from './useFieldCropAssignationModalRightPanelLogic';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';
import { MapRoot } from '@shared/map';
import { useFieldCropAssignationMap } from './useFieldCropAssignationMap';
import { ReplaceCropModal } from '../ReplaceCropModal/ReplaceCropModal';
import { FieldSelectionMap } from '@modules/encoding/shared/components/FieldSelectionMap/FieldSelectionMap';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { ROTATION_FIELDS_FILTERS } from '@modules/encoding/modules/rotation/store/filter.slice';
import { FieldSorter } from '../../../FieldSorter/FieldSorter';
import { useSortedFields } from '@modules/encoding/modules/rotation/hooks/useSortedFields';

export const FieldCropAssignationModalRightPanel = () => {
    const { t } = useTranslation();
    const { classes } = useFieldCropAssignationModalRightPanelStyles();
    const { readOnly } = useFarmSeasonReadOnly();
    const {
        harvestYear,
        fieldList,
        search,
        selectedFilter,
        fieldMatchingFilter,
        handleSearchChange,
        handleResetSearch,
        doesFieldMatchFilters,
        handleFilterChanged,
        handleFilterReset,
        handleFieldIdFilterReset,
        handleFieldSelection,
        selectedCropId,
        disabledFieldIds,
        selectedFieldIds,
        loadingFieldIds,
        confirmDeleteFieldCropModal,
        replaceCropModal,
        fieldIds,
    } = useFieldCropAssignationModalRightPanelLogic({ readOnly });

    const { mapBannerHelpText, setFocusedFieldId, setHoveredFieldId } = useFieldCropAssignationMap({
        readOnly,
        selectedCropId,
    });

    const { sortedFields, sortOption, handleSortChange } = useSortedFields(fieldList);

    return (
        <SidePanel
            style={{ flex: '1' }}
            header={
                <SidePanel.Header>
                    <Typography variant="h3" mb={2}>
                        {t('encoding-rotation.modal.right-panel.title')}
                    </Typography>
                    <Typography color="neutral" variant="body2" mb={2}>
                        {t('encoding-rotation.modal.right-panel.description', { harvestYear })}
                    </Typography>
                    <div className={classes.filterContainer}>
                        <SearchInput
                            placeholder={t('constants.search-field')}
                            value={search}
                            onClearClick={handleResetSearch}
                            onChange={handleSearchChange}
                        />
                        {fieldMatchingFilter ? (
                            <SelectedFieldFilter value={fieldMatchingFilter.name} onDelete={handleFieldIdFilterReset} />
                        ) : (
                            <FieldListFilters
                                filters={[
                                    {
                                        value: ROTATION_FIELDS_FILTERS.FIELDS_WITHOUT_CROP,
                                        icon: IconMap2,
                                        translationKey: 'constants.fields-no-crop',
                                    },
                                    {
                                        value: ROTATION_FIELDS_FILTERS.FIELDS_WITH_CROP,
                                        icon: IconSeed,
                                        translationKey: 'constants.fields-with-crop',
                                    },
                                    {
                                        value: ROTATION_FIELDS_FILTERS.FIELDS_SELECTED,
                                        icon: IconCheckCircle,
                                        translationKey: 'constants.fields-selected',
                                    },
                                ]}
                                value={selectedFilter}
                                onChange={handleFilterChanged}
                                onReset={handleFilterReset}
                            />
                        )}
                    </div>
                </SidePanel.Header>
            }
            body={
                <SidePanel.Body>
                    <FieldSorter value={sortOption} onChange={handleSortChange} classname={classes.fieldSorter} />
                    <SelectableList>
                        {sortedFields.map((field) => {
                            const disabled = disabledFieldIds?.includes(field.id);
                            const isSelected = selectedFieldIds?.includes(field.id);
                            const isLoading = loadingFieldIds?.includes(field.id);
                            return (
                                <SelectableList.Item
                                    key={field.id}
                                    onChange={() => handleFieldSelection(field.id)}
                                    onMouseEnter={disabled ? undefined : () => setHoveredFieldId(field.id)}
                                    onMouseLeave={disabled ? undefined : () => setHoveredFieldId(null)}
                                    className={!doesFieldMatchFilters(field) ? classes.hide : ''}
                                    isSelected={isSelected}
                                    isSelectable={!disabled}
                                >
                                    <Tooltip
                                        text={
                                            !readOnly && !selectedCropId
                                                ? t(
                                                      'encoding-rotation.modal.right-panel.field-selection-disabled-as-no-crop-selected',
                                                  )
                                                : null
                                        }
                                        disableInteractive
                                        placement="top"
                                    >
                                        <FieldCropInfoBox
                                            disabled={!selectedCropId}
                                            className={classes.fieldInfoBoxOverride}
                                            icon={
                                                isLoading ? (
                                                    <Loader size={24} />
                                                ) : isSelected ? (
                                                    <IconCheckCircle color="success" />
                                                ) : (
                                                    <IconPlusCircle />
                                                )
                                            }
                                            handleFieldClick={(event) => {
                                                event.preventDefault();
                                                setFocusedFieldId(field.id);
                                            }}
                                            fieldName={field.name}
                                            fieldArea={`${formatNumber(field.area, 2)} HA`}
                                            mapSnapshot={field.snapshot}
                                            cropNames={field.crops}
                                        />
                                    </Tooltip>
                                </SelectableList.Item>
                            );
                        })}
                    </SelectableList>
                </SidePanel.Body>
            }
        >
            <MapRoot />
            <FieldSelectionMap
                initialFieldIdsfocused={fieldIds}
                onDeselect={handleFieldSelection}
                onSelect={handleFieldSelection}
                mapBannerHelpText={mapBannerHelpText}
            />

            <ConfirmModal
                isOpen={confirmDeleteFieldCropModal.isOpen}
                confirmText={t('encoding-rotation.delete-field-crop-modal.confirm')}
                cancelText={t('constants.cancel')}
                title={t('encoding-rotation.delete-field-crop-modal.title')}
                onClose={({ confirmed }) => confirmDeleteFieldCropModal.onClose?.(confirmed)}
                type="warning"
                primaryText={{
                    translationKey: t('encoding-rotation.delete-field-crop-modal.warning-text'),
                    translationValues: {
                        fieldName: confirmDeleteFieldCropModal.data?.fieldName,
                        cropName: confirmDeleteFieldCropModal.data?.staticCropName,
                    },
                }}
                secondaryText={{
                    translationKey: t('encoding-rotation.delete-field-crop-modal.text'),
                    translationValues: {
                        fieldName: confirmDeleteFieldCropModal.data?.fieldName,
                        cropName: confirmDeleteFieldCropModal.data?.staticCropName,
                    },
                }}
            />
            <ReplaceCropModal replaceCropModalController={replaceCropModal} />
        </SidePanel>
    );
};

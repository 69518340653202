import { useState } from 'react';
import { OperationTimelineInputGroupCardLogicPropsT } from './OperationTimelineInputGroupCard.types';
import { ComputedOperationTypeItemT } from '../../hooks/useOperationTypeItemsForPicker';
import entities, { OperationT } from '@shared/entities';
import { useModalController } from '@shared/hooks/useModalController';
import {
    ConfirmDeleteOperationModalCloseDataT,
    ConfirmDeleteOperationModalOpenDataT,
} from '../ConfirmDeleteOperationModal/ConfirmDeleteOperationModal.types';
import { useDispatch } from 'react-redux';
import { getOperationFormApi, mapOperationTypeSlugToOperationFormType } from '../../api/getOperationFormApi';
import { getOperationFormSchema } from '../../schema/getOperationFormSchema';
import {
    OPERATION_TYPE_SLUGS,
    StaticOperationTypeT,
} from '@shared/entities/staticOperationType/staticOperationType.types';
import { useTranslation } from 'react-i18next';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetStaticCrops } from '@shared/hooks/useGetStaticCrops';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';

export default ({ fieldCropId, seasonId, period }: OperationTimelineInputGroupCardLogicPropsT) => {
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch = useDispatch<any>();
    const [track] = useTrackMutation();

    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: seasonId,
    });
    const { cropState } = useGetFarmSeasonCrops();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { staticCropState } = useGetStaticCrops();
    const crop = staticCropState.getById(
        cropState.getById(fieldCropState.getById(fieldCropId)?.farm_season_crop_id)?.crop_id,
    );
    const companionCrop = staticCropState.getById(fieldCropState.getById(fieldCropId)?.companion_crop_id);

    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [deleteOperation] = entities.operation.useDelete();
    const [createOperation] = entities.operation.useCreate();
    const [updateOperation] = entities.operation.useUpdate();

    const [operationIdToDeleteLoading, setOperationIdToDeleteLoading] = useState<number | null>(null);

    const { open: openModalConfirmDeleteOperation, ...confirmDeleteOperationModalProps } = useModalController<
        ConfirmDeleteOperationModalOpenDataT,
        ConfirmDeleteOperationModalCloseDataT
    >();
    const { open: openModalWarningSameSeedingDate, ...warningSameSeedingDateModalProps } = useModalController<
        void,
        ConfirmModalCloseDataT
    >();

    // uncomment this code to check if an operation of the same type hasn't been recorded for the same date
    // checks if the operation date is already used by another operation from the same type
    // const checkOperationDate = useMemo(
    //     () =>
    //         (
    //             operationTypeId: number | undefined,
    //             operationDate: string | null | undefined,
    //             operationId: number | undefined,
    //         ): boolean => {
    //             const operationWithSameDate = operationTypeId
    //                 ? operationState
    //                       .getByOperationTypeId(operationTypeId)
    //                       .filter((operation) => operation.operation_date === operationDate)
    //                 : [];
    //             if (
    //                 operationWithSameDate.length > 0 &&
    //                 operationWithSameDate.some((operation) => operation.id !== operationId)
    //             ) {
    //                 enqueueSnackbar(t('errors.same-date-operation'), { variant: 'error' });
    //                 return false;
    //             }

    //             return true;
    //         },
    //     [operationState, t],
    // );
    const getDatesToDisable = (operations: OperationT[]): (string | null)[] | undefined => {
        const existingDates = operations.map((operation) => operation.operation_date);
        return existingDates ?? undefined;
    };
    const handleCreateOperationFromPicker = (value: ComputedOperationTypeItemT['value']) => {
        setIsPickerOpen(false);

        // uncomment this code to check if an operation of the same type hasn't been recorded for the same date
        // const isOperationDateValid = checkOperationDate(value.operationTypeId, value.operationDate, undefined);

        // if (!isOperationDateValid) {
        //     return;
        // }

        createOperation({
            farmSeasonFieldCropId: fieldCropId,
            farmSeasonId: seasonId,
            body: {
                operation_type_config_id: value.operationTypeConfigId,
                operation_type_id: value.operationTypeId,
                operation_date: value.operationDate ? value.operationDate : value.operationDate,
            },
        });
        track({
            event: EVENTS.OPERATION_SUBCATEGORY_ADDED,
            properties: {
                name: staticOperationTypeState.getById(value.operationTypeId)?.name ?? '',
                period: period ?? '',
            },
        });
    };

    const canSelectDate = (operation: OperationT) => {
        const staticOperationType = staticOperationTypeState.getById(operation?.operation_type_id);
        return staticOperationType?.slug !== OPERATION_TYPE_SLUGS.SPRAYING_ALL;
    };

    const canDeleteOperation = async (operationId: number): Promise<boolean> => {
        const operationToDelete = operationState.getById(operationId);
        const staticOperationType = staticOperationTypeState.getById(operationToDelete?.operation_type_id);
        if (!staticOperationType?.id) {
            console.error('Operation type not found');
            return false;
        }

        const endpointName = mapOperationTypeSlugToOperationFormType[staticOperationType?.slug];
        if (!endpointName) {
            return true; // no operation form api related, so no data linked to this operation
        }

        const operationFormSchema = getOperationFormSchema(endpointName);
        const getOperationFormAction = getOperationFormApi(endpointName).initiateGet;

        setOperationIdToDeleteLoading(operationId);
        const { data, isSuccess, error } = await dispatch(
            getOperationFormAction({ farmSeasonId: seasonId, fieldCropId, operationId }),
        );
        setOperationIdToDeleteLoading(null);

        if (!isSuccess) {
            console.error(error);
            return false;
        }

        if (operationFormSchema.hasExistingData(data)) {
            const { confirmed } = await openModalConfirmDeleteOperation({ operationIdToDelete: operationId });
            return confirmed; // user confirmed to delete the operation
        } else {
            return true; // no data encoded in the form for this operation
        }
    };

    const handleDeleteOperation = async (operationId: number) => {
        if (await canDeleteOperation(operationId)) {
            deleteOperation({ farmSeasonFieldCropId: fieldCropId, farmSeasonId: seasonId, id: operationId });
        }
    };

    const isSeedingOperation = (slug: string | undefined) =>
        slug === OPERATION_TYPE_SLUGS.SEEDING_SEEDING || slug === OPERATION_TYPE_SLUGS.SEEDING_COMPANION_SEEDING;

    const confirmSameDateForSeedingOperations = async (operationId: number, operationDate: string | null) => {
        // We want to display a warning modal if the user is trying to set the same date for main crop seeding operation
        // and companion crop seeding operation
        const operationToUpdate = operationState.getById(operationId);
        const staticOperationType = staticOperationTypeState.getById(operationToUpdate?.operation_type_id);

        if (isSeedingOperation(staticOperationType?.slug)) {
            if (
                operationState.list.some((operation) => {
                    const existingStaticOperationType = staticOperationTypeState.getById(operation?.operation_type_id);
                    return (
                        isSeedingOperation(existingStaticOperationType?.slug) &&
                        operation.operation_date === operationDate
                    );
                })
            ) {
                const { confirmed } = await openModalWarningSameSeedingDate();
                return confirmed; // user confirmed to use the same date for all seeding operations
            }
        }
        return true;
    };

    const handleUpdateOperation = async ({ id, operationDate }: { id: number; operationDate: string | null }) => {
        // const isOperationDateValid = checkOperationDate(
        //     operationState.getById(id)?.operation_type_id,
        //     operationDate,
        //     id,
        // );

        // if (!isOperationDateValid) {
        //     return;
        // }

        if (await confirmSameDateForSeedingOperations(id, operationDate)) {
            updateOperation({
                id,
                farmSeasonFieldCropId: fieldCropId,
                farmSeasonId: seasonId,
                body: {
                    operation_date: operationDate,
                },
            });
        }
    };

    const getOperationTypeName = (operationType: StaticOperationTypeT) => {
        switch (operationType.slug) {
            case OPERATION_TYPE_SLUGS.SEEDING_SEEDING:
                return t('db.operation_type.seeding') + ' ' + t(crop?.translation_slug ?? '');
            case OPERATION_TYPE_SLUGS.SEEDING_COMPANION_SEEDING:
                return t('db.operation_type.seeding') + ' ' + t(companionCrop?.translation_slug ?? '');
            default:
                return t(operationType.translation_slug);
        }
    };

    const canDeleteSeedingOperation = operationState.list.length === 1;

    return {
        isPickerOpen,
        canDeleteSeedingOperation,
        canSelectDate,
        setIsPickerOpen,
        handleCreateOperationFromPicker,
        handleDeleteOperation,
        handleUpdateOperation,
        getOperationTypeName,
        confirmDeleteOperationModalProps,
        operationIdToDeleteLoading,
        warningSameSeedingDateModalProps,
        getDatesToDisable,
    };
};

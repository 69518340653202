import { ReactNode, useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { MapContext } from './MapProvider';
import { useMap } from './useMap';

/**
 * render children in the <MapRoot/> component. If coordinates provided, center the maps on those \
 */
export const MapPortal = ({ children, coordinates }: { children: ReactNode; coordinates?: [number, number] }) => {
    const map = useMap();

    useEffect(() => {
        if (coordinates) {
            map?.jumpTo({ center: coordinates });
        }
    }, [coordinates, map]);

    const mapContext = useContext(MapContext);
    if (!mapContext) {
        throw new Error('MapContext is null');
    }

    if (!mapContext.mapContainerRef.current) {
        return null;
    }

    return createPortal(children, mapContext.mapContainerRef.current);
};

import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { encodingRoutesConfig } from './encoding.routes';
import layout from './modules/layout';
import technicalItinerary from './modules/technicalItinerary';
import FadeTransition from './shared/components/FadeTransition/FadeTransition';
import { farmProfile } from './modules/farmProfile';
import { preparation } from './modules/preparation';
import { rotation } from './modules/rotation';
import { irrigation } from './modules/irrigation';
import { drainage } from './modules/drainage';
import { agroforestry } from './modules/agroforestry';
import { livestock } from './modules/livestock';
import { soilAnalysis } from './modules/soilAnalysis';
import { history } from './modules/history';
import './shared/components/FadeTransition/fade-transition.css';
import EncodingGuard from './shared/components/EncodingGuard/Encoding.guard';
import { EncodingDoneContentPage } from './pages/EncodingDoneContentPage';
import { MapProvider } from '@shared/map';
import { NotFoundPanel } from '@shared/errors/pages/NotFoundPanel';
import { Guard } from '@shared/guards/Guard';
import { IsEncodingDoneGuard } from '@shared/guards/IsEncodingDoneGuard';
import { IsNotHistoricalGuard } from '@shared/guards/IsNotHistoricalGuard';

export default () => {
    const navigate = useNavigate();

    const routes = useRoutes([
        {
            path: `${encodingRoutesConfig.PREPARATION}/*`,
            element: <preparation.Component />,
        },
        {
            path: `${encodingRoutesConfig.FARM_PROFILE}/*`,
            element: <farmProfile.Component />,
        },
        {
            path: `${encodingRoutesConfig.ROTATION}/*`,
            element: <rotation.Component />,
        },
        {
            path: `${encodingRoutesConfig.TECHNICAL_ITINERARY}/*`,
            element: <technicalItinerary.Component />,
        },
        {
            path: `${encodingRoutesConfig.IRRIGATION}/*`,
            element: <irrigation.Component />,
        },
        {
            path: `${encodingRoutesConfig.DRAINAGE}/*`,
            element: <drainage.Component />,
        },
        {
            path: `${encodingRoutesConfig.AGROFORESTRY}/*`,
            element: <agroforestry.Component />,
        },
        {
            path: `${encodingRoutesConfig.LIVESTOCK}/*`,
            element: <livestock.Component />,
        },
        {
            path: `${encodingRoutesConfig.SOIL_ANALYSIS}/*`,
            element: <soilAnalysis.Component />,
        },
        {
            path: `${encodingRoutesConfig.HISTORY}/*`,
            element: <history.Component />,
        },
        {
            path: encodingRoutesConfig.ENCODING_DONE,
            element: (
                <Guard
                    element={<EncodingDoneContentPage navigate={navigate} />}
                    guards={[IsEncodingDoneGuard, IsNotHistoricalGuard]}
                />
            ),
        },

        { path: '/', element: <Navigate to={encodingRoutesConfig.PREPARATION} replace /> },
        {
            path: '*',
            element: <NotFoundPanel />,
        },
    ]);

    return (
        <EncodingGuard>
            <MapProvider>
                <layout.Component>
                    <FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
                        {routes}
                    </FadeTransition>
                </layout.Component>
            </MapProvider>
        </EncodingGuard>
    );
};

import { useForm } from '@shared/hooks';
import { useEffect } from 'react';
import { useWeedingMachineFormOptions } from '../../hooks/useWeedingMachineFormOptions';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { getOperationFormApi } from '@modules/encoding/modules/technicalItinerary/api/getOperationFormApi';
import { useOperationFormDefaultValues } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationFormDefaultValues';
import {
    WEEDING_MACHINE_FORM_INPUT_NAMES,
    WeedingMachineFormSchemaT,
    weedingMachineFormSchema,
} from '@modules/encoding/modules/technicalItinerary/schema/weedingMachineFormSchema';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import { useFieldCropHasCoverCropDestruction } from '@modules/encoding/modules/technicalItinerary/hooks/useFieldCropHasCoverCropDestruction';

export const useWeedingMachineForm = () => {
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();
    const {
        formApiData,
        computeApiDataToMatchSchema,
        refetchFormData,
        isFormLoading: isLoadingDefaultValues,
    } = useOperationFormDefaultValues('weeding-machine');

    const { fieldCropHasCoverCropDestruction, isLoading } = useFieldCropHasCoverCropDestruction(
        Number(seasonId),
        Number(fieldCropId),
    );

    // Form options
    const { isLoading: isLoadingOptions, ...options } = useWeedingMachineFormOptions();

    const isFormLoading = isLoadingDefaultValues || isLoadingOptions;

    // Mutations
    const [updateForm] = getOperationFormApi('weeding-machine').useUpdate();

    /* -------------------------------- Form -------------------------------- */
    const methods = useForm({
        schema: weedingMachineFormSchema.schema,
        defaultValues: computeApiDataToMatchSchema(formApiData),
        displaySnackbarOnSchemaError: true,
    });

    const { handleSubmit, reset, getValues, setValue } = methods;

    // Reset form default values when API data was fetched
    useEffect(() => {
        if (formApiData) reset(computeApiDataToMatchSchema(formApiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiData]);

    // Form callbacks
    const onSubmit = useStableDebounce(
        handleSubmit(async (data: WeedingMachineFormSchemaT) => {
            try {
                await updateForm({
                    farmSeasonId: seasonId,
                    fieldCropId: fieldCropId,
                    operationId: operationId,
                    body: {
                        ...data,
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    /**
     * set the default values for the new machinery
     */
    const changeMachineryRelatedInputs = () => {
        setValue(WEEDING_MACHINE_FORM_INPUT_NAMES.DEPTH, null);
    };

    return {
        methods,
        options: {
            ...options,
        },
        isFormLoading: isFormLoading || isLoading,
        onSubmit,
        fieldCropHasCoverCropDestruction,
        refetchFormData,
        getFormValues: getValues,
        changeMachineryRelatedInputs,
    };
};

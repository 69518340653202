import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useMemo } from 'react';
import { useGetNeedFinalizationStep } from '../../hooks/useNeedFinalizationStep';
import { FIELD_CROP_CREATION_SOURCE_SLUGS } from '@shared/entities/fieldCrop/fieldCrop.types';
import { useCropDestinationStep } from '../../hooks/useCropDestinationStep';
import { useCheckTotalAreaWithPreviousSeason } from '@modules/encoding/shared/hooks/useCheckTotalAreaWithPreviousSeason';
import { useGetGeometryDatas } from '@shared/hooks/useGetGeometryData';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

export const ROTATION_PROGRESS_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    PENDING: 'PENDING',
    FINISHED: 'FINISHED',
} as const;

export type RotationProgressStatusT = (typeof ROTATION_PROGRESS_STATUSES)[keyof typeof ROTATION_PROGRESS_STATUSES];

export const useIntroductionContentProgress = () => {
    const { currentSeason } = encodingShared.useCurrentSeason();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeason?.id });
    const { geometryDataState } = useGetGeometryDatas();
    const areInvalidFields = !!geometryDataState?.list.length;
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeason?.id });
    const { needFinalization, isLoading: isFinalizationStepLoading } = useGetNeedFinalizationStep();
    const { currentCropDestinationProgress, maxCropDestinationProgress, isCropDestinationStepLoading } =
        useCropDestinationStep();
    const currentCarbonProgrammeYear = currentSeason?.carbon_programme_year ?? 0;
    const { data, isLoading: isLoadingTotalAreaCheck } = useCheckTotalAreaWithPreviousSeason();
    const { isYoyFarmSeason } = useBaselineFarmSeason();

    const isLoading =
        fieldState.isLoading ||
        fieldCropState.isLoading ||
        isCropDestinationStepLoading ||
        isFinalizationStepLoading ||
        isLoadingTotalAreaCheck;

    const maxFieldCropProgress = fieldState.list.length || 1;
    const currentFieldCropProgress = useMemo(
        () =>
            Object.values(Object.fromEntries(fieldCropState.list.map((fieldCrop) => [fieldCrop.farm_season_field_id])))
                .length,
        [fieldCropState.list],
    );

    /** current field progress can be 0 (undone) or 1 (done = any field created) */
    const hasValidFields = fieldState.list.length > 0 && !areInvalidFields;
    const hasPassedCheck = !!data?.check_area_grow_passed;

    const currentFieldProgress = isYoyFarmSeason ? (hasPassedCheck && hasValidFields ? 1 : 0) : hasValidFields ? 1 : 0;

    const isCropDestinationAssignationDone =
        currentCarbonProgrammeYear > 0 ? currentCropDestinationProgress === maxCropDestinationProgress : true;

    let progressStatus: RotationProgressStatusT = ROTATION_PROGRESS_STATUSES.NOT_STARTED;

    const currentFinalizationStepProgress = fieldCropState.list.some(
        (fieldCrop) => fieldCrop.creation_source === FIELD_CROP_CREATION_SOURCE_SLUGS.PAC,
    )
        ? 0
        : 1;

    if (
        currentFieldProgress > 0 &&
        currentFieldCropProgress === maxFieldCropProgress &&
        isCropDestinationAssignationDone &&
        (!needFinalization || currentFinalizationStepProgress === 1)
    )
        progressStatus = ROTATION_PROGRESS_STATUSES.FINISHED;
    else if (currentFieldProgress > 0) progressStatus = ROTATION_PROGRESS_STATUSES.PENDING;

    const isFieldCropAssignationDone = currentFieldCropProgress === maxFieldCropProgress;

    return {
        currentFieldCropProgress,
        maxFieldCropProgress,
        currentCropDestinationProgress,
        maxCropDestinationProgress,
        maxFieldProgress: 1,
        currentFieldProgress,
        currentFinalizationStepProgress,
        maxFinalizationStepProgress: 1,
        progressStatus,
        needFinalization,
        isFieldCropAssignationDone,
        isLoading,
    };
};

import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react';
import { MapLayerCallbackFeatureDataT } from '@shared/map/MapLayers';

//
export const MapDrawingEventContext = createContext<{
    onClick:
        | (
              | ((data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => void)
              | undefined
          )
        | null;
    setOnClick: Dispatch<
        SetStateAction<
            | (
                  | ((data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => void)
                  | undefined
              )
            | null
        >
    >;
    onHover:
        | (
              | ((data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => void)
              | undefined
          )
        | null;
    setOnHover: Dispatch<
        SetStateAction<
            | (
                  | ((data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => void)
                  | undefined
              )
            | null
        >
    >;
} | null>(null);

export const MapDrawingEventContextProvider = ({ children }: { children: ReactNode }) => {
    const [onClick, setOnClick] = useState<
        | (
              | ((data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => void)
              | undefined
          )
        | null
    >(null);
    const [onHover, setOnHover] = useState<
        | (
              | ((data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => void)
              | undefined
          )
        | null
    >(null);

    return (
        <MapDrawingEventContext.Provider
            value={{
                onClick,
                setOnClick,
                onHover,
                setOnHover,
            }}
        >
            {children}
        </MapDrawingEventContext.Provider>
    );
};

export const mscCropTypes = {
    Cereals: { color: '#efe98c' },
    Potatoes: { color: '#c9bfbb' },
    Vegetables: { color: '#79c57c' },
    'Root crops': { color: '#FFCCBC' },
    Peas: { color: '#c5ee98' },
    Pastures: { color: '#46bb2f' },
    Aromatics: { color: '#E1BEE7' },
    Oilseed: { color: '#ffc768' },
    'Other crops': { color: '#b5ced8' },
    'Cover crops': { color: '#54dcbf' },
};

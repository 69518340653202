import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import fieldApi from '@shared/entities/field/field.api';
import { emptySpotFieldT } from '@shared/entities/field/field.types';

export const useCreateEmptySpotField = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const [createMutation, { isLoading }] = fieldApi.useCreateEmptySpot();

    const createEmptySpotField = async (field: emptySpotFieldT) => {
        return await createMutation({ farmSeasonId: currentSeasonId, body: field });
    };

    return {
        createEmptySpotField,
        isLoading,
    };
};

import { useForm } from '@shared/hooks';
import { useEffect } from 'react';
import { getOperationFormApi } from '../../api/getOperationFormApi';
import { useOperationRouteParams } from '../../hooks/useOperationRouteParams';
import { useOperationFormDefaultValues } from '../../hooks/useOperationFormDefaultValues';
import { PloughingFormSchemaT, ploughingFormSchema } from '../../schema/ploughingFormSchema';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import { useFieldCropHasCoverCropDestruction } from '../../hooks/useFieldCropHasCoverCropDestruction';

export const useOperationPloughingForm = () => {
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();
    const { fieldCropHasCoverCropDestruction, isLoading } = useFieldCropHasCoverCropDestruction(
        Number(seasonId),
        Number(fieldCropId),
    );

    const { formApiData, isFormLoading, refetchFormData, computeApiDataToMatchSchema } =
        useOperationFormDefaultValues('ploughing');

    const methods = useForm({
        schema: ploughingFormSchema.schema,
        defaultValues: computeApiDataToMatchSchema(),
        displaySnackbarOnSchemaError: true,
    });

    const [updatePloughing] = getOperationFormApi('ploughing').useUpdate();

    const { handleSubmit, reset, getValues: getFormValues, setValue, watch } = methods;

    useEffect(() => {
        if (formApiData) {
            reset(computeApiDataToMatchSchema(formApiData));
        }
    }, [formApiData, reset, computeApiDataToMatchSchema]);

    const onSubmit = useStableDebounce(
        handleSubmit(async (data: PloughingFormSchemaT) => {
            await updatePloughing({
                farmSeasonId: seasonId,
                fieldCropId: fieldCropId,
                operationId: operationId,
                body: {
                    passage: data.passage,
                    depth: data.depth,
                },
            });
        }, console.error),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    return {
        isFormLoading: isFormLoading || isLoading,
        setValue,
        watch,
        onSubmit,
        refetchFormData,
        getFormValues,
        fieldCropHasCoverCropDestruction,
    };
};

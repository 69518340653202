import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { FieldT, GeometryDataT } from '@shared/entities';
import buffer from '@turf/buffer';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import intersect from '@turf/intersect';
import { toleranceOptions } from '@modules/encoding/modules/rotation/helpers/polygonHelper';
import { FieldPolygonT } from '@shared/entities/field/field.types';
import simplify from '@turf/simplify';
import union from '@turf/union';
import { useDispatch, useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { fieldDefinitionSlice } from '@modules/encoding/shared/store/fieldDefinitionSlice';
import { MapLayerCallbackFeatureDataT } from '@shared/map/MapLayers';
import { MapDrawingEventContext } from '@modules/encoding/modules/rotation/context/mapDrawingEventContextProvider';
import { MAPMODE, MapModeT } from '@modules/encoding/modules/rotation/components/PacImportMap/PacImportMap.logic';

export const useMergeFieldLogic = ({
    invalidFields,
    fields,
    mapMode,
    setMapMode,
    isYoyFarmSeason,
}: {
    invalidFields: GeometryDataT[];
    fields: FieldT[];
    mapMode: MapModeT;
    setMapMode: Dispatch<SetStateAction<MapModeT>>;
    isYoyFarmSeason: boolean;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const mapEventContextProvider = useContext(MapDrawingEventContext);

    if (!mapEventContextProvider) {
        throw new Error('MapEventContextProvider is not available');
    }

    const { setOnClick, setOnHover } = mapEventContextProvider;
    const invalidFieldsNumber = invalidFields?.length;
    const [selectableFields, setSelectableFields] = useState<FieldT[] | undefined>([]);
    const [mergedFieldModalOpen, setMergedFieldModalOpen] = useState<boolean>(false);
    const [newMergedPolygon, setNewMergedPolygon] = useState<FieldPolygonT | null>(null);
    const selectedFieldIds = useSelector((state: SharedStateT) => state.fieldDefinition.selectedIds);

    const handleMergeUpdate = useCallback(
        (data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>) => {
            if (mapMode === MAPMODE.MERGE) {
                const fieldId = data?.featureId;
                const field = fields?.find((f) => f.id === fieldId);

                // cannot merge permanent fields & agroforestry fields yoy
                if (field?.is_permanent) {
                    return false;
                } else if (field?.has_agroforestry && isYoyFarmSeason) {
                    return false;
                }
                const isSelectableField = selectableFields?.some((selectableField) => selectableField.id === fieldId);
                if (field && isSelectableField) {
                    const isFieldAlreadySelected = selectedFieldIds?.some(
                        (selectedFieldId) => selectedFieldId === field.id,
                    );
                    if (isFieldAlreadySelected) {
                        // If field already selected, remove it from selectedFields
                        dispatch(
                            fieldDefinitionSlice.actions.setSelectedFieldIds(
                                selectedFieldIds?.filter((selectedFieldId) => selectedFieldId !== field.id),
                            ),
                        );

                        // We should also check if the new polygon is still valid.
                    } else
                        dispatch(fieldDefinitionSlice.actions.setSelectedFieldIds?.([...selectedFieldIds, field.id]));
                    // Otherwise add it from selectedFields
                }
            }
        },
        [mapMode, fields, selectedFieldIds, dispatch, selectableFields, isYoyFarmSeason],
    );

    const onFeatureHover = useCallback(
        (data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>) => {
            const targetFieldOverviewFeature = fields.find((field) => field.id === data?.featureId);
            // cannot merge permanent fields & agroforestry fields yoy
            if (targetFieldOverviewFeature?.is_permanent) {
                return false;
            } else if (targetFieldOverviewFeature?.has_agroforestry && isYoyFarmSeason) {
                return false;
            }
            const fieldId = targetFieldOverviewFeature?.id;
            if (fieldId) dispatch(fieldDefinitionSlice.actions.setHoveredFieldId(fieldId));
        },
        [fields, dispatch, isYoyFarmSeason],
    );

    const computeSelectableFields = useCallback(() => {
        const alreadySelectedFields = fields?.filter((field) => selectedFieldIds.includes(field.id));
        const adjacentFields: FieldT[] = [];

        // Get only the fields that are not already selected
        const availableFields = fields?.filter((obj1) => !alreadySelectedFields?.some((obj2) => obj2.id === obj1.id));

        // Add tolerance buffer to each available field
        const bufferedAvailableFields = availableFields?.map((field) => {
            const bufferedField = { ...field };
            bufferedField.polygon = getBufferedPolygon(field.polygon);
            return bufferedField;
        });

        // Find adjacent fields to already selected fields
        alreadySelectedFields?.forEach((selectedField) => {
            bufferedAvailableFields?.forEach((availableField) => {
                if (arePolygonsContiguous(selectedField.polygon, availableField.polygon)) {
                    adjacentFields.push(availableField);
                }
            });
        });

        return alreadySelectedFields.concat(adjacentFields);
    }, [selectedFieldIds, fields]);

    const getBufferedPolygon = (polygon: FieldPolygonT) => {
        return buffer(polygon, toleranceOptions.TOLERANCE_MERGE_BUFFER) as FieldPolygonT;
    };

    const arePolygonsContiguous = (poly1: FieldPolygonT, poly2: FieldPolygonT) => {
        return intersect(poly1, poly2);
    };

    const checkPolygonsContinguous = (polyList: FieldPolygonT[]) => {
        // Start with the first polygon as the base
        let newPolygon = polyList[0];
        const polygonsLeftToBeChecked = [...polyList.slice(1)];

        let polygonsAddedInLastIteration = true;

        // Keep iterating until no new polygons are added
        while (polygonsAddedInLastIteration && polygonsLeftToBeChecked.length > 0) {
            polygonsAddedInLastIteration = false;

            for (let i = polygonsLeftToBeChecked.length - 1; i >= 0; i--) {
                const polygon = polygonsLeftToBeChecked[i];

                // Check if the current polygon is contiguous with the newPolygon
                if (arePolygonsContiguous(getBufferedPolygon(polygon), getBufferedPolygon(newPolygon))) {
                    let newUnion = union(polygon, newPolygon) as FieldPolygonT;
                    if (newUnion?.geometry?.type === ('MultiPolygon' as string)) {
                        const bufferedUnion = union(
                            buffer(polygon, toleranceOptions.TOLERANCE_MERGE_BUFFER),
                            buffer(newPolygon, toleranceOptions.TOLERANCE_MERGE_BUFFER),
                        );
                        if (bufferedUnion?.geometry?.type === 'Polygon') {
                            newUnion = simplify(buffer(bufferedUnion, -toleranceOptions.TOLERANCE_MERGE_BUFFER), {
                                tolerance: 0.0001,
                                highQuality: true,
                                mutate: true,
                            }) as FieldPolygonT;
                        } else {
                            return;
                        }
                    }

                    // Check if the resulting geometry is valid (a single polygon)
                    if (newUnion?.geometry?.type === 'Polygon') {
                        newPolygon = newUnion;
                        polygonsLeftToBeChecked.splice(i, 1);
                        polygonsAddedInLastIteration = true;
                    }
                }
            }
        }
        // After the loop, check if there are any polygons left that couldn't be merged
        if (polygonsLeftToBeChecked.length > 0) return false;
        else return newPolygon;
    };

    const onFinishMerge = () => {
        if (selectedFieldIds?.length && selectedFieldIds.length >= 2) {
            const selectedFields = fields?.filter((field) => selectedFieldIds.includes(field.id));
            const newPoly = checkPolygonsContinguous(selectedFields.map((field) => field.polygon));
            if (newPoly) {
                setNewMergedPolygon(newPoly);
                setMergedFieldModalOpen(true);
            } else
                enqueueSnackbar(t('encoding-rotation.pac-file.merge-field-error.field-not-contiguous'), {
                    variant: 'error',
                });
        }
    };

    const onMergedFieldModalClose = () => {
        setMergedFieldModalOpen(false);
        stopMerging();
    };

    const merge = () => {
        setMapMode(MAPMODE.MERGE);
    };

    const stopMerging = () => {
        setMapMode(MAPMODE.NONE);
        setOnClick(null);
        setOnHover(null);
        dispatch(fieldDefinitionSlice.actions.setSelectedFieldIds([]));
    };
    useEffect(() => {
        if (mapMode === MAPMODE.MERGE) {
            if (!selectedFieldIds?.length) {
                // If no fields are selected, set all fields as selectable. (Starting point)
                setSelectableFields(fields);
            } else {
                // if 1 or more fields are selected, compute which fields are selectable.
                const selectableFields = computeSelectableFields(); // Should we send this to BE instead ?
                setSelectableFields(selectableFields);
            }
        } else {
            setSelectableFields([]);
        }
    }, [mapMode, fields, selectedFieldIds, computeSelectableFields]);

    useEffect(() => {
        if (mapMode === MAPMODE.MERGE) {
            setOnHover(() => onFeatureHover);
            setOnClick(() => handleMergeUpdate);
        } else {
            setOnClick(null);
            setOnHover(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapMode, handleMergeUpdate, onFeatureHover]);

    return {
        merge,
        stopMerging,
        fields,
        mapMode,
        setMapMode,
        onFinishMerge,
        newMergedPolygon,
        selectedFieldIds,
        mergedFieldModalOpen,
        onMergedFieldModalClose,
        invalidFieldsNumber,
        handleMergeUpdate,
        onFeatureHover,
    };
};

import { OPERATION_DUPLICATION_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationForm';
import { Checkbox, SelectableList, Tooltip } from '@soil-capital/ui-kit/components';
import { Collapse } from '@soil-capital/ui-kit/material-core';
import { IconChevronDown } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import {
    type DuplicationFieldT,
    type OperationGroupT,
} from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { useState, MouseEvent, PropsWithChildren } from 'react';
import { useOperationDuplicationListStyles } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/OperationDuplicationList/OperationDuplicationList.style';
import { useGetOperationDateString } from '@modules/encoding/modules/technicalItinerary/hooks/useGetOperationDateString';
import { ControlledSelectableCheckboxRadio } from '@shared/components/form/ControlledSelectableCheckboxRadio/ControlledSelectableCheckboxRadio';
import { ControlledSelectableList } from '@shared/components/form/ControlledSelectableList/ControlledSelectableCheckboxList';
import { useOperationDuplicationFormValues } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationFormValues';
import { useOperationDuplicationList } from './useOperationDuplicationList';

const TooltipWrapper = ({ isSelected, children }: PropsWithChildren<{ isSelected: boolean }>) => {
    const { t } = useTranslation();

    return (
        <Tooltip
            title={isSelected ? t('constants.unselect') : ''}
            text={isSelected ? t('encoding-technical-itinerary.duplication.modal.left-panel.tooltip.unselect') : ''}
            placement="right"
        >
            {children}
        </Tooltip>
    );
};

const OperationDuplicationListItem = ({ option }: { option: OperationGroupT }) => {
    const { classes, cx } = useOperationDuplicationListStyles();
    const { t } = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { getOperationDateString } = useGetOperationDateString();

    const handleToggleCollapse = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={classes.periods}>
            <Typography>{t(option.translation_slug)}</Typography>
            <Typography className={classes.collapseToggle} onClick={handleToggleCollapse}>
                {t('constants.see-periods')}
                <IconChevronDown className={cx(classes.chevron, isCollapsed && classes.collapsedChevron)} />
            </Typography>
            <Collapse in={isCollapsed}>
                <div className={classes.collapse}>
                    {option.operations.map((operation) => (
                        <Typography key={operation.id}>{getOperationDateString(operation?.operation_date)}</Typography>
                    ))}
                </div>
            </Collapse>
        </div>
    );
};

export type OperationDuplicationListProps = {
    sourceField: DuplicationFieldT;
};

export const OperationDuplicationList = ({ sourceField }: OperationDuplicationListProps) => {
    const { t } = useTranslation();
    const { hasSameOrganic, hasSameTillageId, hasSameCompanionCrop } = useOperationDuplicationFormValues();
    const { classes } = useOperationDuplicationListStyles();

    const orderedOperationsASC = sourceField.operationsByOperationType.sort((a, b) => {
        const aName = t(a.translation_slug);
        const bName = t(b.translation_slug);
        return aName.localeCompare(bName);
    });

    const { selectAll, handleSelectAllOperationsClick } = useOperationDuplicationList({ sourceField });

    return (
        <div>
            <div className={classes.selectAll}>
                <Checkbox checked={selectAll} onClick={handleSelectAllOperationsClick} />
                <Typography color="neutral" variant="body2">
                    {selectAll ? t('constants.unselect-all') : t('constants.select-all')}
                </Typography>
            </div>
            <SelectableList gap={1}>
                <TooltipWrapper isSelected={hasSameOrganic}>
                    <ControlledSelectableCheckboxRadio
                        name={OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_ORGANIC}
                        isSelected={hasSameOrganic}
                        label={t(
                            `encoding-technical-itinerary.duplication.modal.left-panel.global-characteristics.${
                                sourceField.fieldCrop.is_organic ? '' : 'not-'
                            }organic`,
                        )}
                    />
                </TooltipWrapper>
                <TooltipWrapper isSelected={hasSameTillageId}>
                    <ControlledSelectableCheckboxRadio
                        name={OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_TILLAGE_ID}
                        isSelected={hasSameTillageId}
                        label={t(sourceField?.tillagePractice?.translation_slug ?? '')}
                    />
                </TooltipWrapper>
                <TooltipWrapper isSelected={hasSameCompanionCrop}>
                    <ControlledSelectableCheckboxRadio
                        name={OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_COMPANION_CROP_ID}
                        isSelected={hasSameCompanionCrop}
                        label={
                            sourceField.companionCrop
                                ? t(
                                      'encoding-technical-itinerary.duplication.modal.left-panel.global-characteristics.companion-crop',
                                      { companionCrop: t(sourceField.companionCrop.translation_slug) },
                                  )
                                : t(
                                      'encoding-technical-itinerary.duplication.modal.left-panel.global-characteristics.no-companion-crop',
                                  )
                        }
                    />
                </TooltipWrapper>
                <ControlledSelectableList
                    name={OPERATION_DUPLICATION_INPUT_NAMES.OPERATION_IDS}
                    options={orderedOperationsASC}
                    getOptionKey={(option) => option.id}
                    getOptionValue={(option) => option.operations.map((operation) => operation.id)}
                    renderOption={(option, props) => (
                        <TooltipWrapper key={option.id} isSelected={props.isSelected}>
                            <SelectableList.CheckboxRadio {...props}>
                                <OperationDuplicationListItem option={option} />
                            </SelectableList.CheckboxRadio>
                        </TooltipWrapper>
                    )}
                />
            </SelectableList>
        </div>
    );
};

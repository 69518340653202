import { FARM_PROFILE_STATIC_DATA_SLUGS } from '@modules/encoding/modules/farmProfile/farmProfile.types';

export const FARM_PROFILE_STATIC_DATA_SLUGS_TO_TRANSLATION_KEY = {
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_OBJECTIVES_PROFITABILITY]:
        'encoding-farm-profile.farm-objectives.profitability',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_OBJECTIVES_SOIL_HEALTH]: 'encoding-farm-profile.farm-objectives.soil-health',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_OBJECTIVES_FEWER_WORKING_HOURS]:
        'encoding-farm-profile.farm-objectives.fewer-working-hours',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_OBJECTIVES_BIODIVERSITY]: 'encoding-farm-profile.farm-objectives.biodiversity',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_OBJECTIVES_RESILIENCE]:
        'encoding-farm-profile.farm-objectives.farm-resilience',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_OBJECTIVES_OTHER]: 'constants.other',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DIFFICULTIES_SOIL_EROSION]:
        'encoding-farm-profile.farm-difficulties.soil-erosion',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DIFFICULTIES_CIDE_RESISTANCE]:
        'encoding-farm-profile.farm-difficulties.cide-resistance',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DIFFICULTIES_DIMINISHING_YIELDS]:
        'encoding-farm-profile.farm-difficulties.diminishing-yields-improvements',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DIFFICULTIES_SOIL_FERTILITY]:
        'encoding-farm-profile.farm-difficulties.soil-fertility',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DIFFICULTIES_SOIL_COMPACTION]:
        'encoding-farm-profile.farm-difficulties.soil-compaction',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DIFFICULTIES_DROUGHTS]: 'encoding-farm-profile.farm-difficulties.droughts',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DIFFICULTIES_NO_PROBLEM]: 'encoding-farm-profile.farm-difficulties.no-problem',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DIFFICULTIES_OTHER]: 'constants.other',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_TYPE_CONVENTIONAL]: 'encoding-farm-profile.farm-type.conventional',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_TYPE_PARTIALLY_ORGANIC]: 'encoding-farm-profile.farm-type.partially-organic',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_TYPE_TOTALLY_ORGANIC]: 'encoding-farm-profile.farm-type.totally-organic',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_TILLAGE_PRACTICE_CONVENTIONAL_TILL]:
        'encoding-farm-profile.tillage-practice.conventional-till',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_TILLAGE_PRACTICE_REDUCED_TILL]:
        'encoding-farm-profile.tillage-practice.reduced-till',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_TILLAGE_PRACTICE_NO_TILL]: 'encoding-farm-profile.tillage-practice.no-till',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_TILLAGE_PRACTICE_NO_TILL_WORK]:
        'encoding-farm-profile.tillage-practice.no-till-work',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_EXPECTATIONS_REMUNERATION]:
        'encoding-farm-profile.farm-expectations.remuneration',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_EXPECTATIONS_ENVIRONMENTAL_FOOTPRINT_INDICATOR]:
        'encoding-farm-profile.farm-expectations.environmental-footprint',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_EXPECTATIONS_AGRONOMIC_ADVICE]:
        'encoding-farm-profile.farm-expectations.agronomic-advice',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_EXPECTATIONS_REGENERATIVE_AGRICULTURE_TRANSITION]:
        'encoding-farm-profile.farm-expectations.regenerative-agriculture-transition',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_EXPECTATIONS_NETWORK_WITH_FARMERS]:
        'encoding-farm-profile.farm-expectations.network-with-farmers',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_EXPECTATIONS_OTHER]: 'constants.other',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS]: 'encoding-farm-profile.farm-documents.fmis',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_NOTEBOOK]: 'encoding-farm-profile.farm-documents.notebook',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_EXCEL]: 'encoding-farm-profile.farm-documents.excel',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_OTHER]: 'encoding-farm-profile.farm-documents.other',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_BFC]: 'encoding-farm-profile.farm-documents-fmis.bfc',

    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_DACOM]: 'encoding-farm-profile.farm-documents-fmis.dacom',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_GATEKEEPER]:
        'encoding-farm-profile.farm-documents-fmis.gatekeeper',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_GEOFOLIA]: 'encoding-farm-profile.farm-documents-fmis.geofolia',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_GREENFARM]:
        'encoding-farm-profile.farm-documents-fmis.greenfarm',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_OMNIA]: 'encoding-farm-profile.farm-documents-fmis.omnia',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_OTHER]: 'encoding-farm-profile.farm-documents-fmis.other',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_PARCELLES]:
        'encoding-farm-profile.farm-documents-fmis.parcelles',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_SMAG]: 'encoding-farm-profile.farm-documents-fmis.smag',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_WIUZ]: 'encoding-farm-profile.farm-documents-fmis.wiuz',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_LANDAPP]: 'encoding-farm-profile.farm-documents-fmis.landapp',
    [FARM_PROFILE_STATIC_DATA_SLUGS.FARM_DOCUMENTS_FMIS_TELUS]: 'encoding-farm-profile.farm-documents-fmis.telus',
};

export type FarmProfileStaticDataSlugsKeysT = keyof typeof FARM_PROFILE_STATIC_DATA_SLUGS_TO_TRANSLATION_KEY;

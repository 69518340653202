import { useGetFarmSeasonBoundariesQuery } from '@modules/encoding/api/farmSeasonBoundaries';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { fieldDefinitionSlice } from '@modules/encoding/shared/store/fieldDefinitionSlice';
import { GeometryDataT } from '@shared/entities';
import { FieldPolygonT, FieldT } from '@shared/entities/field/field.types';
import { MapLayerCallbackFeatureDataT, useMap } from '@shared/map';
import { MapDrawingEventContext } from '../../context/mapDrawingEventContextProvider';
import { getTextLayoutLabel } from '@modules/encoding/shared/utils/layerHelper';
import { SharedStateT } from '@shared/store';
import { useState, useContext, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export const MAPMODE = {
    NONE: 'none',
    MERGE: 'merge',
    SPLIT: 'split',
    DRAW: 'draw',
    EDIT: 'edit',
};
export type MapModeT = (typeof MAPMODE)[keyof typeof MAPMODE];

export const usePacImportActionPageMap = (fields: FieldT[], invalidFields: GeometryDataT[]) => {
    const { currentSeasonId } = useCurrentSeasonId();

    const dispatch = useDispatch();

    const [mapMode, setMapMode] = useState<MapModeT>(MAPMODE.NONE);
    const [hoveredFeatureFieldOverview, setHoveredFeatureFieldOverview] = useState<FieldPolygonT | undefined>();

    const fieldIdHovered = useSelector((state: SharedStateT) => state.fieldDefinition.hoveredId);
    const fieldIdsFocused = useSelector((state: SharedStateT) => state.fieldDefinition.focusedIds);
    const fieldIdsSelected = useSelector((state: SharedStateT) => state.fieldDefinition.selectedIds);
    const fieldIdsHighligted = useSelector((state: SharedStateT) => state.fieldDefinition.highlightedIds);

    const helpText =
        mapMode === MAPMODE.DRAW
            ? 'encoding-rotation.map.draw-help-drawMode'
            : mapMode === MAPMODE.EDIT
            ? 'encoding-rotation.map.draw-help-editMode'
            : mapMode === MAPMODE.SPLIT
            ? 'encoding-rotation.map.draw-help-splitMode'
            : invalidFields.length
            ? 'encoding-rotation.map.draw-help-invalid-fields'
            : 'encoding-rotation.map.draw-help';

    const mapDrawingEventContextProvider = useContext(MapDrawingEventContext);

    if (!mapDrawingEventContextProvider) {
        throw new Error('MapDrawingEventContextProvider is not available');
    }
    const { onClick, onHover } = mapDrawingEventContextProvider;

    const map = useMap();

    const treePattern = new Image(72, 72);
    treePattern.src = '/assets/patterns/tree.svg';

    treePattern.onload = () => {
        if (map && !map.hasImage('trees')) map.addImage('trees', treePattern, { pixelRatio: 2 });
    };

    const { data: farmSeasonBoundaries, isLoading: isFarmBoundariesLoading } = useGetFarmSeasonBoundariesQuery({
        farmSeasonId: currentSeasonId,
    });

    const allFieldIds = useMemo(() => {
        return fields.map((field) => field.id);
    }, [fields]);

    const allInvalidFieldIds = useMemo(() => {
        return invalidFields.map((field) => field.id);
    }, [invalidFields]);

    const mapLayerSource = useMemo(() => {
        return {
            type: 'FeatureCollection',
            features: fields.map((field) => ({
                ...field.polygon,
                properties: {
                    color: field.is_permanent ? '#808080' : '#fff',
                    label: getTextLayoutLabel(field),
                    'pattern-image': field.has_agroforestry ? 'trees' : null,
                },
            })),
        } as const;
    }, [fields]);

    const invalidFieldsMapLayerSource = useMemo(() => {
        return {
            type: 'FeatureCollection',
            features: invalidFields.map((field) => ({
                ...field.polygon,
                id: field.id,
                properties: {
                    color: '#F10D0D',
                    label: getTextLayoutLabel(field),
                },
            })),
        } as const;
    }, [invalidFields]);

    const farmBoundariesLayerSource = useMemo(() => {
        if (!farmSeasonBoundaries?.features) {
            return { type: 'FeatureCollection', features: [] } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
        }

        return farmSeasonBoundaries as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
    }, [farmSeasonBoundaries]);

    const onFeatureHover = useCallback(
        (data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => {
            const targetFieldOverviewFeature = fields.find((field) => field.id === data?.featureId);
            const fieldOverviewFeature = targetFieldOverviewFeature?.polygon;
            setHoveredFeatureFieldOverview(fieldOverviewFeature);

            if (targetFieldOverviewFeature?.is_permanent) {
                return false;
            }

            // don't trigger hover effect on text layer.
            // It causes the fieldOverview Popup to flicer when hovering the text layer
            if (layerId === 'feature-text') return;
            dispatch(fieldDefinitionSlice.actions.setHoveredFieldId(data ? data.featureId : null));
        },
        [fields, dispatch],
    );

    useEffect(() => {
        dispatch(fieldDefinitionSlice.actions.reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        mapLayerSource,
        invalidFieldsMapLayerSource,
        farmBoundariesLayerSource,
        onFeatureHover,
        onClick,
        onHover,
        fieldIdHovered,
        fieldIdsFocused,
        fieldIdsSelected,
        helpText,
        mapMode,
        setMapMode,
        hoveredFeatureFieldOverview,
        farmSeasonBoundaries,
        isFarmBoundariesLoading,
        allFieldIds,
        allInvalidFieldIds,
        fieldIdsHighligted,
    };
};

import { MouseEvent, useEffect, useState } from 'react';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { useTranslation } from 'react-i18next';
import { useOperationDuplicationFormValues } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationFormValues';
import { useDispatch, useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { fieldSelectionDuplicationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';

export const useFieldSelectionLogic = ({
    sourceField,
    targetFields,
    onConfirmSelection,
    onFieldSelected,
    onSelectAllFields,
    onConfirmSelectAllFields,
}: {
    sourceField: DuplicationFieldT;
    targetFields: DuplicationFieldT[];
    onConfirmSelection?: () => void;
    onFieldSelected?: () => void;
    onSelectAllFields?: () => void;
    onConfirmSelectAllFields?: () => void;
}) => {
    const { t } = useTranslation();
    const { fieldCropIds, operationIds } = useOperationDuplicationFormValues();
    const dispatch = useDispatch();
    const [selectedField, setSelectedField] = useState<DuplicationFieldT | undefined>(undefined);
    const selectedFieldIds = useSelector((state: SharedStateT) => state.fieldSelectionDuplication.selectedIds);

    const [selectAll, setSelectAll] = useState<boolean>(false);

    const selectedSourceOperationTypes = sourceField.operationsByOperationType
        .filter((ot) => ot.operations.every((op) => operationIds.includes(op.id)))
        .map((ot) => ot.id);

    const selectedFieldOperationTypes =
        selectedField?.operationsByOperationType
            .filter((ot) => selectedSourceOperationTypes.includes(ot.id))
            .map((ot) => t(ot.translation_slug)) || [];

    const handleFieldSelection = (e: MouseEvent<HTMLElement>, option: DuplicationFieldT) => {
        const fieldCrop = targetFields.find((fc) => fc.id === option.id);
        if (!fieldCrop) return;

        const isSelected = selectedFieldIds.includes(fieldCrop.id);
        if (!isSelected) {
            setSelectedField(fieldCrop);

            if (selectedFieldOperationTypes.length > 0) {
                e.preventDefault();
                onFieldSelected?.();
            } else {
                dispatch(
                    fieldSelectionDuplicationSlice.actions.setSelectedFieldIds([...selectedFieldIds, fieldCrop.id]),
                );
            }
        } else {
            setSelectedField(undefined);
            dispatch(
                fieldSelectionDuplicationSlice.actions.setSelectedFieldIds(
                    selectedFieldIds.filter((id) => id !== fieldCrop.id),
                ),
            );
        }
    };

    const handleSelectAllFieldsClick = () => {
        const selectAllPreviousValue = selectAll;
        if (selectAllPreviousValue) {
            dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds([]));
        } else {
            const isOneOperationAlreadyOnTargetFields = targetFields.some(
                (el) =>
                    el.operationsByOperationType.filter((ot) => selectedSourceOperationTypes.includes(ot.id)).length >
                    0,
            );
            if (isOneOperationAlreadyOnTargetFields) {
                onSelectAllFields?.();
            } else {
                dispatch(
                    fieldSelectionDuplicationSlice.actions.setSelectedFieldIds([...targetFields.map((el) => el.id)]),
                );
            }
        }
    };

    const handleConfirmSelectAllFields = () => {
        dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds([...targetFields.map((el) => el.id)]));
        onConfirmSelectAllFields?.();
    };

    const handleConfirmSelection = () => {
        if (selectedField) {
            const newValues = [...selectedFieldIds, selectedField.id];
            dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds([...newValues]));
            onConfirmSelection?.();
        }
    };

    useEffect(() => {
        setSelectAll(fieldCropIds.length === targetFields.length);
    }, [fieldCropIds, targetFields.length]);

    return {
        selectedField,
        selectedFieldOperationTypes,
        handleFieldSelection,
        handleConfirmSelection,
        selectAll,
        handleSelectAllFieldsClick,
        handleConfirmSelectAllFields,
    };
};
